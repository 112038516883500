import React, { useState } from 'react';
import { useClassnames } from 'hook/use-classnames';
import Modal from 'component/modal';
import Button from 'component/button';
import { useAlert } from 'component/alert/provider';
import EyeIcon from 'component/icon/eye';
import { IModalMergeProps } from './types';
import style from './index.pcss';
import { SubscriptionsItemUser } from 'src/api/subscriptions/types';
import api from 'src/api';

export default (props: IModalMergeProps) => {
    const cn = useClassnames(style);
    const { show } = useAlert();

    const [pendingMerge, setPendingMerge] = useState<boolean>(false);
    const [selectedPerson, setSelectedPerson] = useState<SubscriptionsItemUser>(
        {
            id: props.selectedPerson.person_id,
            first_name: props.selectedPerson.first_name,
            last_name: props.selectedPerson.last_name,
            photo_url: props.selectedPerson.photo_url
        }
    );

    const _requestMerge = (): void => {
        setPendingMerge(true);

        api.persons.createMergePerson(props.person.id, props.selectedPerson.person_id)
            .then(() => {
                props.onPersonMerged && props.onPersonMerged();
                props.onClickClose();
                show('Ваши изменения отправлены на модерацию, после принятия администратором данные будут изменены.');
                setPendingMerge(false);
            })
            .catch((error) => {
                if (error.response.data[0].length) {
                    show(error.response.data[0], 'warning');
                } else {
                    show('Не удалось отправить заявку на модерацию. Возможно, заявка на объединение с выбранной персоной уже зарегистрирована.', 'warning');
                }
                setPendingMerge(false);
            });
    };

    const onClickMerge = () => {
        _requestMerge();
    };

    const elPersonInfo = (dataPerson: SubscriptionsItemUser) => {
        return (
            <div>
                <div className={cn('similar-person__image-box')}>
                    <img alt={'Фото персоны'} className={cn('similar-person__photo-image')} src={dataPerson.photo_url} />
                </div>
                <div className={cn('similar-person__person-info')}>
                    <a
                        target="_blank"
                        href={`/persons/${dataPerson.id}`}
                        title="Перейти на страницу персоны"
                    >
                        Информация о персоне
                        <EyeIcon width={24} height={24} />
                    </a>
                    <ul>
                        <li>Имя: {dataPerson.first_name ? dataPerson.first_name : 'Не указано'}</li>
                        <li>Фамилия: {dataPerson.last_name ? dataPerson.last_name : 'Не указано'}</li>
                    </ul>
                </div>
            </div>
        );
    };

    const elButtons = () => {
        return (
            <div className={cn('similar-person__buttons')}>
                <Button
                    onClick={props.onClickClose}
                    className={cn('similar-person__button')}
                    isSecondary={true}
                    isSmall={true}
                >
                    Отмена
                </Button>
                <Button
                    onClick={onClickMerge}
                    isLoading={pendingMerge}
                    className={cn('similar-person__button')}
                    isSmall={true}
                >
                    Объединить
                </Button>
            </div>
        );
    };

    return (
        <Modal onClickClose={props.onClickClose}>
            <div className={cn('similar-person__merge-person')}>
                {elPersonInfo(props.person)}
                {elPersonInfo(selectedPerson)}
            </div>
            {elButtons()}
        </Modal>
    );
};
