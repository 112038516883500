import React, { FC, Fragment, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import moment from 'moment';

import LazyLoad from 'component/lazy-load';
import { useAlert } from 'component/alert/provider';

import placeholder from './img/placeholder.png';
import { IProps } from './types';
import style from './styles.pcss';
import NewAttachments from 'component/new-attachments';
import { parse, stringify } from 'query-string';

const EventAlbum: FC<IProps> = ({album, ...props}) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const { show } = useAlert();

    const elAttachments = useMemo(() => {
        if(Array.isArray(props.photos) && props.photos?.length && album) {
            const attachments = props.photos.map((item) => ({
                url: item
            }));

            const params = {['albumId']: album.pk};

            let query;

            if (props.query) {
                query = parse(props.query);
                query = stringify({...query, album_id: album.pk}, { arrayFormat: 'none' });
            }

            const link = `/events/${props.event_id}${query ? `?${query}` : ''}`;

            return (
                <Fragment>
                    <ReactTooltip />
                    <LazyLoad className={cn('album__attachments')}>
                        <NewAttachments
                            data-tip={album.description}
                            className={cn('album__attachments')}
                            attachmentsCount={props.photos_count || attachments.length}
                            limit={3}
                            link={link}
                            children={props.photos}
                            isLink={true}
                            tooltip={album.description}
                            isParent={true}
                            {...params}
                        />
                    </LazyLoad>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <ReactTooltip />
                    <LazyLoad className={cn('album__attachments')}>
                        <img
                            data-tip={album?.description}
                            src={placeholder}
                            alt={'placeholder'}
                        />
                    </LazyLoad>
                </ Fragment>
            );
        }
    }, [JSON.stringify(props.photos), album]);

    const elDate = useMemo(() => {
        if(album?.created_at) {
            return (
                <div className={cn('album__content-text')}>
                    <span>{t('route.dashboard.album.date')}</span>
                    <span>{moment(album?.created_at).format('ll')}</span>
                </div>

            );
        }
    }, [album?.created_at]);

    const elLink = useMemo(() => {
        const link = `/events/${props.event_id}${props.query}&album_id=${album.pk}`;

        return (
            <div className={cn('album__header')}>
                <ReactTooltip
                    id={`album-photo-name-${album.pk}`}
                    effect="solid"
                    className={cn('album__recommendations-tooltip')}
                >
                    {album.description}
                </ReactTooltip>
                <div
                    {...(album.description && { 'data-tip': album.description })}
                    data-for={`album-photo-name-${album.pk}`}
                >
                    <Link to={link} target={`${props.isBlankLink ? '_blank' : ''}`}>{album.name}</Link>
                </div>
            </div>
        );
    }, [props, album]);

    return (
        <div className={cn('album')}>
            {elAttachments}
            <div className={cn('album__content')}>
                {elLink}
                {elDate}
            </div>
        </div>
    );
};

export default EventAlbum;
