import { AxiosResponse } from 'axios';
import { AuthAPIType, AuthRegistrationConfirmData, AuthYandexCallback, AuthYandexCreate, RegistrationCreate, TokenCreate } from './types';
import httpClient from 'src/utils/http-сlient';

const authRegistrationCreate = (
    email: string,
    phone?: string
): Promise<AxiosResponse<RegistrationCreate>> => {
    return httpClient.post<RegistrationCreate>('/api/v2/auth/registration/', {
        email,
        ...(phone && { phone })
    });
};

const authRegistrationCheckToken = (
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/auth/registration/check-token/', {
        token
    });
};

const authRegistrationConfirm = (
    data: AuthRegistrationConfirmData
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/auth/registration/confirm/', {
        ...data
    });
};

export const authTokenCreate = (
    email: string,
    password: string
): Promise<AxiosResponse<TokenCreate>> => {
    return httpClient.post<TokenCreate>('/api/v2/auth/token/', {
        email,
        password
    });
};

export const authTokenRefreshCreate = (
    refresh: string
): Promise<AxiosResponse<TokenCreate>> => {
    return httpClient.post<TokenCreate>('/api/v2/auth/token/refresh/', {
        refresh
    });
};

export const authYandexCreate = (
    access_token: string
): Promise<AxiosResponse<AuthYandexCreate>> => {
    return httpClient.post<AuthYandexCreate>('/api/v2/auth/yandex/', {
        access_token
    });
};

const getAuthYandexCallback = (payload: AuthYandexCallback): Promise<AxiosResponse> => {
    return httpClient.get('/api/v2/auth/yandex/callback/', {
        params: {
            code: payload.code,
            state: payload.state,
            ...(payload.error && { error: payload.error }),
            ...(payload.error_description && { error_description: payload.error_description})
        }
    });
};

const delAuthYandexRevoke = (): Promise<AxiosResponse> => {
    return httpClient.delete('/api/v2/auth/yandex/revoke/');
};

const getAuthYandexUrl = (): Promise<AxiosResponse> => {
    return httpClient.get('/api/v2/auth/yandex/url/');
};

export default {
    authRegistrationCreate,
    authRegistrationCheckToken,
    authRegistrationConfirm,
    authTokenCreate,
    authTokenRefreshCreate,
    authYandexCreate,
    getAuthYandexCallback,
    delAuthYandexRevoke,
    getAuthYandexUrl
} as AuthAPIType;
