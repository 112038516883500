import React, { FC, useState, ReactNode, useEffect, useCallback } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import useClassnames from 'hook/use-classnames';

import UI from 'component/ui';
import Loader from 'component/loader';
import PasswordSet from 'component/password-set';
import IconLoader from 'component/icon/loader';
import IconSuccess from 'component/icon/apply';
import { IError } from 'component/form/types';
import { set, reset } from 'store/reducers/user/actions';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

import Meeting from '../meeting';
import style from './index.pcss';
import { AccountRetrieve } from 'src/api/accounts/types';
import api from 'src/api';
import { AuthRegistrationConfirmData } from 'src/api/auth/types';

const ConfirmRegistration: FC = () => {
    const cn = useClassnames(style);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { token }: { token: string } = useParams();

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const rolesStore = useSelector<IStore, AccountRetrieve['role'] | undefined>((store) => store[keyUser].role);
    const [tokenValidity, setTokenValidity] = useState<boolean>(false);
    const [isLoadingCheckToken, setIsLoadingCheckToken] = useState<boolean>(true);
    const [submitPending, setSubmitPending] = useState<boolean>(false);
    const [meetingSuccess, setMeetingSuccess] = useState<boolean>(false);
    const [redirected, setRedirected] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError> | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>();

    const login = useCallback(() => setTimeout(() => {
        if(!userId) {
            api.accounts.getAccountRetrieve()
                .then((payload) => {
                    dispatch(set(payload.data));

                    return <Redirect to="/subscriptions" />;
                })
                .catch((errorData) => {
                    if(!axios.isCancel(errorData)) {
                        setError('An unexpected error has occurred. :(');

                        dispatch(reset());
                    }
                });
        } else {
            setRedirected(true);
        }
    }, 3000), [userId]);

    const onSubmitMeeting = useCallback((last_name: string, first_name: string) => {
        if (password) {
            setSubmitPending(true);

            const data: AuthRegistrationConfirmData = {
                token,
                first_name,
                last_name,
                password
            };

            api.auth.authRegistrationConfirm(data)
            .then(() => {
                setMeetingSuccess(true);
                setSubmitPending(false);

                login();
            })
            .catch((err) => {
                console.error(err);

                setSubmitPending(false);
                setErrors(err.errors);
                setError(err.message);
            });
        }
    }, [password]);

    useEffect(() => {
        api.auth.authRegistrationCheckToken(token)
            .then((payload) => {
                setTokenValidity(true);
                setIsLoadingCheckToken(false);
            })
            .catch((errorData) => {
                    console.error(errorData);
                    setTokenValidity(false);
                    setIsLoadingCheckToken(false);
            });
    }, []);

    const onSubmitPassword = (input_password: string) => {
        setPassword(input_password);
    };

    const elContent = (): ReactNode => {
        if(isLoadingCheckToken) {
            return <Loader />;
        }

        if(password) {
            return <Meeting onSubmit={onSubmitMeeting} pending={submitPending} />;
        }

        if(password && meetingSuccess) {
            return (
                <div className={cn('confirm-registration__text-block')}>
                    <IconSuccess className={cn('confirm-registration__text-block-icon', 'confirm-registration__text-block-icon_green')} />
                    <h2 className={cn('confirm-registration__text-block-title')}>{t('route.registration.confirm.success.title')}</h2>
                    <span className={cn('confirm-registration__text-block-text')}>
                        <IconLoader />
                        {t('route.registration.confirm.success.text')}
                    </span>
                </div>
            );
        }

        return <PasswordSet pending={submitPending} error={error} errors={errors} onSubmit={onSubmitPassword} />;
    };

    if(!tokenValidity && !isLoadingCheckToken) {
        return <Redirect to="/404" />;
    }

    if(redirected && meetingSuccess && password && userId && rolesStore) {
        if(rolesStore.includes('PHOTOGRAPHER')) {
            return <Redirect to="/dashboard" />;
        }

        return <Redirect to="/" />;
    }

    return (
        <UI.Main className={cn('confirm-registration__ui-main')}>
            <Helmet
                title={t('helmet.title.confirm-registration')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />
            <UI.Box padding={true} className={cn('confirm-registration__ui-box')}>
                {elContent()}
            </UI.Box>
        </UI.Main>
    );
};

export default ConfirmRegistration;
