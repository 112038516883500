import React, {
    Fragment,
    MouseEvent,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import ErrorBlock from 'component/error';
import Form, { useRegistry } from 'component/form';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import history from 'component/core/history';
import InputPhoto from 'component/form/input-photo';
import IconCross from 'component/icon/cross';
import useDidUpdateEffect from 'hook/use-did-update-effect';
import { DataPersonsItem } from 'component/api/types/api/main/get-person-list/get/code-200';
import { parse, stringify } from 'query-string';
import useIntersect from 'hook/use-intersect';
import FilterForm from 'component/form/filter-form';
import PersonCarousel from 'component/person-carousel';
import CarouselItem from 'component/person-carousel/item';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import style from './index.pcss';
import { EventStatistic } from 'src/api/statistics/types';
import api from 'src/api';
import { PersonItem, PersonsSearchFilter } from 'src/api/persons/types';
import { Photo } from 'src/api/photos/types';
import EventPhotoList from 'component/new-photo-list';
import { Page } from 'src/api/base';
import {
    EventInfo,
    EventPhotoSearchFilter,
    EventAlbumItem
} from 'src/api/events/types';
import { CreateTempFile } from 'src/api/files/types';
import Breadcrumbs from 'component/breadcrumbs';
import PhotographerFilter, { SelectOption } from './components/photographer-filter';
import EventSubEvents from './components/sub-events';
import EventSubAlbums from './components/sub-albums';
import { getNormalizedQuery } from 'src/utils/get-normalized-query';
import { DataFilesTmpFacesItem, DataFilesTmpFacesPersonsItem } from 'component/api/types/api/tmp-file/get-files-list/get/code-200';

const PHOTOS_LIMIT = 50;

const LazyImageComponent = React.lazy(() => import('./image'));

const Event = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, photosToken, tokenFile, tokenPersons] = useCancelTokens(4);

    const isMobile = useSelector<IStore, boolean>(
        (store) => store[keyDeviceInfo].mobile
    );
    const isTablet = useSelector<IStore, boolean>(
        (store) => store[keyDeviceInfo].tablet
    );
    const isAdmin = useSelector<IStore, boolean | undefined>(
        (store) => store[keyUser].is_admin
    );
    const { id }: { id?: string } = useParams();
    const registry = useRegistry();

    const [currentId, setCurrentId] = useState<string | null>(id || null);

    const [isQueryChecked, setIsQueryChecked] = useState<boolean>(false);
    const [validity, setValidity] = useState<boolean>(false);
    const [persons, setPersons] = useState<Array<DataPersonsItem>>([]);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(
        getNormalizedQuery()
    );
    const [eventInfo, setEventInfo] = useState<EventInfo>();
    const [parentInfo, setParentInfo] = useState<EventInfo | null>();
    const [pendingFile, setPendingFile] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [image, setImage] = useState<CreateTempFile | null>(null);
    const [chosenPersonIds, setChosenPersonIds] = useState<Array<number>>([]);

    const [eventStat, setEventStat] = useState<EventStatistic | null>(null);
    const [isEventInfoPending, setIsEventInfoPending] = useState<boolean>(false);
    const [isEventStatsPending, setIsEventStatsPending] = useState<boolean>(false);
    const [isLoadingParentInfo, setIsLoadingParentInfo] = useState<boolean>(false);

    const [photosList, setPhotosList] = useState<Array<Photo>>([]);
    const [photosTotal, setPhotosTotal] = useState<number>(0);
    const [photosPage, setPhotosPage] = useState<number>(1);
    const [isPhotosPending, setIsPhotosPending] = useState<boolean>(false);
    const [isPhotosMerge, setIsPhotosMerge] = useState<boolean>(false);
    const [isPhotosNext, setIsPhotosNext] = useState<boolean>(false);
    const [personsList, setPersonsList] = useState<Array<PersonItem>>([]);
    const [personsTotal, setPersonsTotal] = useState<number>(0);

    const [isPersonsLoadMore, setIsPersonsLoadMore] = useState<boolean>(false);
    const [isPersonsPending, setIsPersonsPending] = useState<boolean>(false);
    const [isPersonsMerge, setIsPersonsMerge] = useState<boolean>(false);
    const [isPersonsNext, setIsPersonsNext] = useState<boolean>(false);
    const [personsPage, setPersonsPage] = useState<number>(1);
    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

    const [albumFilterValue, setAlbumFilterValue] = useState<string | null>(
        queryParams.photographer_id
    );
    const [albumInfo, setAlbumInfo] = useState<EventAlbumItem | null>();

    useEffect(() => {
        if (queryParams.album_id) {
            api.events.getEventAlbumsInfo(Number(id), queryParams.album_id)
                .then((resp) => {
                    setAlbumInfo(resp.data);
                })
                .catch(() => {
                    setAlbumInfo(null);
                });
        } else {
            setAlbumInfo(null);
        }
    }, [queryParams.album_id]);

    useEffect(() => {
        if (!queryParams.parent) {
            setParentInfo(null);
        } else {
            setIsLoadingParentInfo(true);
        }
    }, [queryParams]);

    useEffect(() => {
        if (queryParams.file_id) {
            setIsLoadingImage(true);
            api.files
                .getTempFile(queryParams.file_id)
                .then((resp) => {
                    const href = URL.createObjectURL(resp.data);

                    const newImage = {
                        id: queryParams.file_id,
                        url: href
                    };

                    setImage(newImage);
                    setIsLoadingImage(false);
                })
                .catch(() => {
                    setIsLoadingImage(false);
                });
        }
    }, []);

    const getPersonsLimit = (): number => {
        if (isMobile) {
            return 4;
        }

        if (isTablet) {
            return 6;
        }

        return 10;
    };

    useEffect(() => {
        if (isPersonsPending || isPersonsLoadMore) {
            const page: Page = {
                pageNumber: personsPage,
                pageSize: getPersonsLimit()
            };

            const filter: PersonsSearchFilter = {
                event_id: Number(id),
                ...(queryParams.search && { search: queryParams.search }),
                ...(queryParams.is_person_find_partner && {
                    is_person_find_partner: queryParams.is_person_find_partner
                }),
                ...(queryParams.number && { number_id: queryParams.number }),
                ...(queryParams.file_id && { file_id: queryParams.file_id })
            };

            api.persons
                .getPersonsList(page, filter)
                .then((resp) => {
                    setPersonsList(
                        isPersonsMerge
                            ? [...personsList, ...resp.data.results]
                            : resp.data.results
                    );
                    setPersonsTotal(resp.data.count);
                    setIsPersonsNext(!Boolean(resp.data.next === null));
                })
                .finally(() => {
                    setIsPersonsPending(false);
                    setIsPersonsLoadMore(false);
                })
                .catch(() => {
                    setIsPersonsNext(false);
                    setIsPersonsPending(false);
                    setIsPersonsLoadMore(false);
                    setPersonsList([]);
                    setPersonsTotal(0);
                });
        }
    }, [isPersonsPending, isPersonsLoadMore]);

    useEffect(() => {
        if (isEventStatsPending) {
            api.statistic.getEventStatistics(Number(id)).then((resp) => {
                setEventStat(resp.data);
                setIsEventStatsPending(false);
            })
            .catch(() => {
                setEventStat(null);
                setIsEventStatsPending(false);
            });

        }
    }, [isEventStatsPending]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        if (id && currentId !== id) {
            setIsEventInfoPending(true);

            setIsEventStatsPending(true);

            setPhotosPage(1);
            setIsPhotosNext(true);
            setIsPhotosPending(true);

            setPersonsPage(1);
            setIsPersonsNext(true);
            setIsPersonsPending(true);

            setCurrentId(id);
        }
    }, [id]);

    useEffect(() => {
        setIsEventInfoPending(true);
        setIsEventStatsPending(true);

        return () => {
            photosToken.remove();
            token.remove();
            tokenFile.remove();
        };
    }, []);

    useDidUpdateEffect(() => {
        onSubmitForm();
    }, [JSON.stringify(chosenPersonIds)]);

    useEffect(() => {
        if (isPhotosPending) {
            const page: Page = {
                pageNumber: photosPage,
                pageSize: PHOTOS_LIMIT
            };

            const filter: EventPhotoSearchFilter = {
                ...(queryParams.color_id && { color_id: queryParams.color_id }),
                ...(queryParams.search && { search: queryParams.search }),
                ...(queryParams.number && { number: queryParams.number }),
                ...(queryParams.is_person_find_partner && {
                    is_person_find_partner: queryParams.is_person_find_partner
                }),
                ...(queryParams.time_from && {
                    time_after: queryParams.time_from
                }),
                ...(queryParams.time_to && {
                    time_before: queryParams.time_to
                }),
                ...(queryParams.event_date_from && {
                    date_after: queryParams.event_date_from
                }),
                ...(queryParams.event_date_to && {
                    date_before: queryParams.event_date_to
                }),
                ...(queryParams.person_id && {
                    person_id: queryParams.person_id
                }),
                ...(queryParams.is_stock && { is_stock: queryParams.is_stock }),
                ...(queryParams.photographer_id && {
                    photographer_id: queryParams.photographer_id
                }),
                ...(queryParams.is_sell_suit && {
                    is_sell_suit: queryParams.is_sell_suit
                }),
                ...(queryParams.file_id && { file_id: queryParams.file_id }),
                ...(queryParams.album_id && { album_id: queryParams.album_id })
            };

            api.events
                .getEventPhotosList(Number(id), page, filter)
                .then((resp) => {
                    setIsPhotosNext(!Boolean(resp.data.next === null));
                    setPhotosList(
                        isPhotosMerge
                            ? [...photosList, ...resp.data.results]
                            : resp.data.results
                    );
                    setPhotosTotal(resp.data.count);
                })
                .finally(() => {
                    setIsPhotosPending(false);
                })
                .catch(() => {
                    setIsPhotosNext(false);
                    setIsPhotosPending(false);
                });
        }
    }, [isPhotosPending]);

    useEffect(() => {
        if (isEventInfoPending) {
            if (id && queryParams.parent) {
                api.events
                    .getEventEventsInfo(queryParams.parent, Number(id))
                    .then((resp) => {
                        setEventInfo(resp.data);
                        setIsEventInfoPending(false);
                    })
                    .catch((err) => {
                        setError(err.message);
                        setIsEventInfoPending(false);
                    });
            } else if (id) {
                api.events
                    .getEventInfo(Number(id))
                    .then((resp) => {
                        setEventInfo(resp.data);
                        setIsEventInfoPending(false);
                    })
                    .catch((err) => {
                        if (!axios.isCancel(err)) {
                            console.error(err);

                            setError(err.message);
                            setIsEventInfoPending(false);
                        }
                    });
            }
        }
    }, [isEventInfoPending]);

    useEffect(() => {
        if (isLoadingParentInfo && eventInfo) {
            api.events.getEventInfo(queryParams.parent)
                .then((payload) => {
                    setParentInfo(payload.data);
                    setIsLoadingParentInfo(false);
                })
                .catch(() => {
                    setIsLoadingParentInfo(false);
                });
        }
    }, [isLoadingParentInfo, eventInfo]);

    const onFileLoaded = (file: CreateTempFile): void => {
        setImage(file);

        onSubmitForm();
    };

    const choosePersons = (personId: number) => {
        const index = chosenPersonIds.indexOf(personId);
        let newList = [...chosenPersonIds];

        if (index > -1) {
            newList = newList.filter((item) => item !== personId);
        } else {
            newList.push(personId);
        }

        setChosenPersonIds(newList);
    };

    const onClickPerson = (personId: number) => {
        choosePersons(personId);
    };

    useEffect(() => {
        const { photographer_id, ...restQueryParams } = queryParams;

        const data = {
            ...restQueryParams,
            ...(albumFilterValue && { photographer_id: albumFilterValue })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [albumFilterValue]);

    const onSubmitForm = debounce(
        useCallback(() => {
            const payload = registry.form.getPayload();

            let person_id: Array<number> = [];
            if (personsList.length && chosenPersonIds.length) {
                person_id = chosenPersonIds.map((person) => person);
            } else if (queryParams.person_id && !isQueryChecked) {
                person_id = queryParams.person_id;
                if (typeof queryParams.person_id === 'string') {
                    setChosenPersonIds([Number(queryParams.person_id)]);
                } else {
                    const chosenPersons = queryParams.person_id.map(
                        (item: string) => {
                            return Number(item);
                        }
                    );
                    setChosenPersonIds(chosenPersons);
                }
            }
            setIsQueryChecked(true);

            const data = {
                ...(queryParams.parent && { parent: queryParams.parent }),
                ...(payload.search && { search: payload.search }),
                ...(person_id.length && { person_id }),
                ...(payload.photo && { file_id: payload.photo.id }),
                ...(payload.name && { search: payload.name }),
                ...(payload.location && {
                    location_id: payload.location.value
                }),
                ...(payload.location && {
                    location_name: payload.location.label
                }),
                ...(payload.club?.value && { club_id: payload.club.value }),
                ...(payload.club?.label && { club_name: payload.club.label }),
                ...(payload.event_date_range?.date_from && {
                    event_date_from: payload.event_date_range.date_from
                }),
                ...(payload.event_date_range?.date_to && {
                    event_date_to: payload.event_date_range.date_to
                }),
                ...(payload.color && { color_id: payload.color }),
                ...(payload.sport_number && { number: payload.sport_number }),
                ...(payload.time && { time: payload.time }),
                ...(payload.partner_search && {
                    is_person_find_partner: payload.partner_search
                }),
                ...(payload.is_stock && { is_stock: payload.is_stock }),
                ...(payload.suit_sale && { suit_sale: payload.suit_sale }),
                ...(payload.timeRange?.valueFrom && {
                    time_from: payload.timeRange.valueFrom
                }),
                ...(payload.timeRange?.valueTo && {
                    time_to: payload.timeRange.valueTo
                }),
                ...(payload.is_sell_suit && {
                    is_sell_suit: payload.is_sell_suit
                    }),
                ...(queryParams.photographer_id && {
                    photographer_id: queryParams.photographer_id
                }),
                ...(queryParams.album_id && { album_id: queryParams.album_id })
            };

            history.replace({
                search: stringify(data, {
                    arrayFormat: 'none'
                }),
                state: {
                    noScroll: true
                }
            });

            setIsPhotosNext(true);
            setPhotosPage(1);
            setIsPhotosMerge(false);
            setIsPhotosPending(true);

            setIsPersonsNext(true);
            setPersonsPage(1);
            setIsPersonsMerge(false);
            setIsPersonsPending(true);
        }, [chosenPersonIds]),
        300
    );

    const onReset = useCallback(() => {
        registry.form.clearForm();
        setChosenPersonIds([]);
        onSubmitForm();
    }, []);

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setChosenPersonIds([]);
        registry.form.clearForm();
        setImage(null);
    }, []);

    const onSubmitAlbumFilter = (value: SelectOption) => {
        if (value === null) {
            setAlbumFilterValue(null);

            return;
        }

        setAlbumFilterValue(value.value);
    };

    const $bottomPreviousPosts = useIntersect(
        (entry) => {
            if (entry.isIntersecting && isPhotosNext) {
                setIsPhotosMerge(true);
                setPhotosPage((prevState) => prevState + 1);
                setIsPhotosPending(true);
            }
        },
        {
            rootMargin: '500px 0px'
        }
    );

    const elButtonBeforePosts = () => {
        if (!isPhotosPending && photosList.length && isPhotosNext) {
            if (!(photosList.length === photosTotal)) {
                return <Loader ref={$bottomPreviousPosts} />;
            }
        }
    };

    const elImageResult = useMemo(() => {
        if (image) {
            return (
                <Suspense
                    fallback={(
                        <div>
                            <Loader text="" />
                        </div>
                    )}
                >
                    <LazyImageComponent url={image.url} />
                </Suspense>
            );
        }
    }, [image]);

    const elUploadContent = useMemo(() => {
        if (isLoadingImage) {
            return <Loader />;
        }

        return (
            <Fragment>
                {elImageResult}
                <InputPhoto
                    defaultValue={image || undefined}
                    onFileLoaded={onFileLoaded}
                    registry={registry.field}
                    name="photo"
                />
                {image?.url && (
                    <div className={cn('event__photo-menu')}>
                        <div
                            onClick={onClickRemove}
                            className={cn('event__photo-menu-item')}
                        >
                            <IconCross
                                className={cn('event__photo-menu-icon')}
                            />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }, [JSON.stringify(image), isLoadingImage]);

    const elBreadcrumbs = useMemo(() => {
        if (!isEventInfoPending && !isLoadingParentInfo) {
            const { album_id, photographer_id: photographer, ...restQueryParams} = parse(location.search);
            const { parent, album_id: parent_album, photographer_id, ...restParentInfoParams} = parse(location.search);

            const query = stringify(restQueryParams, { arrayFormat: 'none' });
            const parentQuery = stringify(restParentInfoParams, { arrayFormat: 'none' });

            const breadcrumbItems = [
                { label: 'Главная', to: '/' },
                parentInfo ? { label: parentInfo.name, to: `/events/${parentInfo.id}?${parentQuery}` } : null,
                eventInfo ?
                {
                    label: eventInfo.name,
                    to: `/events/${id}?${query}`
                } : null,
                albumInfo ? { label: albumInfo.name} : null
            ];

            return <Breadcrumbs items={breadcrumbItems} />;
        }
    }, [eventInfo, parentInfo, albumInfo, isEventInfoPending, isLoadingParentInfo]);

    const elPhotos = useMemo(() => {
        if (photosList?.length) {
            const query_data = { ...parse(location.search) };
            const query = stringify(query_data, { arrayFormat: 'none' });
            const link = `/events/${id}`;

            return (
                <EventPhotoList
                    className={cn('event__photo-list')}
                    elIcons={false}
                    elControls={false}
                    elPortal={false}
                    list={photosList}
                    link={link}
                    query={`?${query}`}
                />
            );
        }

        return (
            <span className={cn('event__empty')}>
                {t('route.event.content.empty')}
            </span>
        );
    }, [JSON.stringify(photosList)]);

    const elContent = useMemo(() => {
        if (isPhotosPending && photosList.length === 0) {
            return <Loader className={cn('event__loader')} />;
        }

        return (
            <Fragment>
                <div className={cn('event__content-photo')}>
                    {elPhotos}
                    {elError}
                    {elButtonBeforePosts()}
                </div>
            </Fragment>
        );
    }, [JSON.stringify(photosList), error, isPhotosPending]);

    const elError = useMemo(() => {
        if (error) {
            return (
                <ErrorBlock className={cn('event__error')}>{error}</ErrorBlock>
            );
        }
    }, [error]);

    const elEventDate = useMemo(() => {
        if (eventInfo?.date) {
            return moment(eventInfo?.date).format('LL');
        }
    }, [eventInfo?.date]);

    const elEventLocation = useMemo(() => {
        if (eventInfo?.location) {
            const locationText = eventInfo.location.name;

            return locationText;
        }
    }, [eventInfo?.location]);

    const elPageHeader = useMemo(() => {
        if (eventInfo?.name) {
            const separate = !elEventLocation || !elEventDate ? '' : ', ';

            return (
                <div className={cn('event__header-wrapper')}>
                    <h1 className={cn('event__header')}>{eventInfo.name}</h1>
                    <div className={cn('event__subheader')}>
                        {eventStat && (
                            <div
                                className={cn(
                                    'event__subheader-count',
                                    'event__subheader-margin'
                                )}
                            >
                                {eventStat.photo_count > 0 && (
                                    <div>
                                        Фотографий: {eventStat.photo_count}
                                    </div>
                                )}
                                {eventStat.looking_partner_count > 0 && (
                                    <div>
                                        В поисках партнера:{' '}
                                        {eventStat.looking_partner_count}
                                    </div>
                                )}
                                {eventStat.suit_count > 0 && (
                                    <div>
                                        Костюмов в продаже:{' '}
                                        {eventStat.suit_count}
                                    </div>
                                )}
                                {isAdmin && (
                                    <div>
                                        На модерации:{' '}
                                        {eventStat.unverified_photo_count}
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className={cn(
                                'event__subheader-event',
                                'event__subheader-margin'
                            )}
                        >
                            {elEventLocation}
                            {separate}
                            {elEventDate}
                        </div>
                    </div>
                </div>
            );
        }
    }, [eventInfo, eventStat, isAdmin]);

    const elPageAlbumHeader = useMemo(() => {
        if (albumInfo) {
            return (
                <div className={cn('event__header-wrapper')}>
                    <h3 className={cn('event__header')}>{albumInfo.name}</h3>
                    <div className={cn('event__subheader')}>
                        <div
                            className={cn(
                                'event__subheader-count',
                                'event__subheader-margin'
                            )}
                        >
                            {albumInfo.created_at && (
                                <div>
                                    Дата: {moment(albumInfo.created_at).format('ll')}
                                </div>
                            )}
                            {albumInfo.description && (
                                <div>
                                    Описание: {albumInfo.description}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }, [albumInfo]);

    const onClickNext = useCallback(() => {
        if (isPersonsNext && !isPersonsPending && !isPersonsLoadMore) {
            setPersonsPage((prevState) => prevState + 1);
            setIsPersonsMerge(true);
            setIsPersonsLoadMore(true);
        }
    }, [isPersonsNext, isPersonsPending, isPersonsLoadMore]);

    const elPersonCarousel = useMemo(() => {
        if (isPersonsPending) {
            return <Loader />;
        }

        if (personsList.length) {
            return (
                <PersonCarousel
                    onClickNext={onClickNext}
                    isLoading={isPersonsLoadMore}
                >
                    {personsList.map((item, index) => {
                        const params = {
                            item: {
                                id: item.id,
                                name:
                                    item.first_name && item.last_name
                                        ? item.full_name
                                        : item.id.toString(),
                                photo_url: item.photo,
                                is_partner_profile: item.is_partner_profile
                            },
                            onClick: () => onClickPerson(item.id),
                            isSelected: chosenPersonIds.indexOf(item.id) > -1
                        };

                        return <CarouselItem key={index} {...params} />;
                    })}
                </PersonCarousel>
            );
        } else {
            return (
                <UI.Box className={cn('event__box')} padding={true}>
                    <div className={cn('search__persons-content')}>
                        Ничего не найдено
                    </div>
                </UI.Box>
            );
        }
    }, [
        isPersonsPending,
        isPersonsLoadMore,
        JSON.stringify(personsList),
        JSON.stringify(chosenPersonIds)
    ]);

    if (pendingFile) {
        return (
            <UI.Main className={cn('event__loader')}>
                <Loader />
            </UI.Main>
        );
    }

    return (
        <>
        {elBreadcrumbs}
        <UI.Main className={cn('event')}>
            {elPageHeader}
            {elPageAlbumHeader}
            <EventSubEvents parent={id} />
            <EventSubAlbums event_id={id} album_filter={albumFilterValue} />
            <div className={cn('event__grid')}>
                <div>
                    {personsTotal > 0 && (
                        <>
                            <UI.BoxHeader className={cn('event__title')}>
                                Участники события
                            </UI.BoxHeader>
                            <div className={cn('event__person-amount')}>
                                Общее количество персон: {personsTotal}
                            </div>
                            <UI.Box
                                padding={false}
                                className={cn('event__slider')}
                            >
                                {elPersonCarousel}
                            </UI.Box>
                        </>
                    )}
                    <UI.BoxHeader className={cn('event__title')}>
                        Фотографии
                    </UI.BoxHeader>
                    <UI.Box padding={true} className={cn('event__content')}>
                        {elContent}
                    </UI.Box>
                </div>
                <div className={cn('event__sidebar')}>
                    <UI.Box padding={true} className={cn('event__box')}>
                        <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
                        <Form registry={registry.form}>{elUploadContent}</Form>
                    </UI.Box>
                    <PhotographerFilter event_id={Number(id)} onSubmit={onSubmitAlbumFilter} default_value={albumFilterValue ? albumFilterValue : ''} />
                    <UI.Box padding={false} className={cn('event__box')}>
                        <FilterForm
                            registry={registry}
                            color={true}
                            personNumber={true}
                            timeRange={true}
                            search={true}
                            searchPrompt={true}
                            eventDate={true}
                            partnerSearch={true}
                            suitSale={true}
                            stockPhotos={true}
                            onChangeValidity={setValidity}
                            onReset={onReset}
                            onSubmitForm={onSubmitForm}
                            isSearchSubmit={false}
                        />
                    </UI.Box>
                </div>
            </div>
        </UI.Main>
        </>
    );
};
// tslint:disable-next-line max-file-line-count
export default Event;
