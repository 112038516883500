import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import {
    GetLocationsListData,
    LocationsAPIType,
    LocationsListItem
} from './types';
import httpClient from 'src/utils/http-сlient';

const getLocationsList = (
    page: Page,
    data?: GetLocationsListData
): Promise<AxiosResponse<PaginationResponse<LocationsListItem>>> => {
    return httpClient.get<PaginationResponse<LocationsListItem>>(
        '/api/v2/locations/choices/',
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 100 },
                ...data
            }
        }
    );
};

export default {
    getLocationsList
} as LocationsAPIType;
