import { AxiosResponse } from 'axios';
import { Page, PaginationResponse, PhotoPaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import {
    BulkUpdatePhoto,
    BulkUpdatePhotoData,
    CreatePhotoData,
    CreatePhotoItem,
    GetShareLink,
    PartialUpdatePhotoData,
    Photo,
    PhotoItem,
    PhotosAPIType,
    PhotosSearchFilter,
    UpdatePhotoData,
    UpdatePhotoItem
} from './types';
import queryString from 'query-string';

const getPhotosList = (
    page?: Page,
    filter?: PhotosSearchFilter
): Promise<AxiosResponse<PhotoPaginationResponse<Photo>>> => {
    return httpClient.get<PhotoPaginationResponse<Photo>>(`/api/v2/photos/`, {
        params: {
            ...{ page: page?.pageNumber && page.pageNumber },
            ...{ page_size: page?.pageSize ? page.pageSize : 40 },
            ...filter
        },
        paramsSerializer(params) {
            return queryString.stringify(params, { arrayFormat: 'none' });
        }
    });
};

const createPhoto = (
    data: CreatePhotoData
): Promise<AxiosResponse<CreatePhotoItem>> => {
    return httpClient.post<CreatePhotoItem>('/api/v2/photos/', data);
};

const getPhotoItem = (id: number): Promise<AxiosResponse<PhotoItem>> => {
    return httpClient.get<PhotoItem>(`/api/v2/photos/${id}/`);
};

const updatePhoto = (
    id: number,
    data: UpdatePhotoData
): Promise<AxiosResponse<UpdatePhotoItem>> => {
    return httpClient.put<UpdatePhotoItem>(`/api/v2/photos/${id}/`, data);
};

const partialUpdatePhoto = (
    id: number,
    data: PartialUpdatePhotoData
): Promise<AxiosResponse<UpdatePhotoItem>> => {
    return httpClient.put<UpdatePhotoItem>(`/api/v2/photos/${id}/`, data);
};

const createDownload = (id: number): Promise<AxiosResponse> => {
    return httpClient.get(`/api/v2/photos/${id}/original/`, {
        responseType: 'blob'
    });
};

const getShareLinks = (id: number): Promise<AxiosResponse<GetShareLink>> => {
    return httpClient.get<GetShareLink>(`/api/v2/photos/${id}/share-links/`);
};

const getPhotoThumbnail = (
    id: number,
    photo_size?: string
): Promise<AxiosResponse<PhotoItem>> => {
    return httpClient.get<PhotoItem>(`/api/v2/photos/${id}/thumbnail/`, {
        params: {
            photo_size
        }
    });
};

const bulkDeletePhoto = (
    photos: Array<number>
): Promise<AxiosResponse<Array<number>>> => {
    return httpClient.put<Array<number>>(`/api/v2/photos/bulk-delete/`, {
        photos
    });
};

const bulkUpdatePhoto = (
    data: BulkUpdatePhotoData
): Promise<AxiosResponse<BulkUpdatePhoto>> => {
    return httpClient.put<BulkUpdatePhoto>(`/api/v2/photos/bulk-update/`, data);
};

const createModerationRequest = (
    photos: Array<number>
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/photos/moderation-requests/`, {
        photos
    });
};

export default {
    getPhotosList,
    createPhoto,
    getPhotoItem,
    getShareLinks,
    updatePhoto,
    partialUpdatePhoto,
    createDownload,
    getPhotoThumbnail,
    bulkDeletePhoto,
    bulkUpdatePhoto,
    createModerationRequest
} as PhotosAPIType;
