import React, { useMemo, useRef, useState, useCallback, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import axios from 'axios';

import IconLogout from 'component/icon/logout';
import IconLoader from 'component/icon/loader';
import { useCancelToken } from 'component/core/cancel-token';
import useClassnames from 'hook/use-classnames';
import { logout } from 'component/api/user';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import { reset } from 'store/reducers/user/actions';
import { clearCart } from 'store/reducers/cart/actions';

import { IProps } from './types';
import style from './index.pcss';
import { resetTask } from 'src/store/reducers/task/actions';

const Menu = (props: IProps) => {
    const cn = useClassnames(style, props.className, true);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const isPremium = useSelector<IStore, boolean | null>((store) => !!store[keyUser].premium_account);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const userEmail = useSelector<IStore, string | undefined>((store) => store[keyUser].email);
    const userName = useSelector<IStore, string | undefined>((store) => store[keyUser].first_name);
    const tokenLogout = useCancelToken();

    const [pending, setPending] = useState<boolean>(false);

    const $root = useRef<HTMLElement>(null);

    useEffect(() => {
        return () => {
            tokenLogout.remove();
        };
    }, []);

    useOnClickOutside($root, props.onOutsideClick || null);

    const onClickLogout = useCallback((e: MouseEvent) => {
        e.preventDefault();

        if(userId && !pending) {
            setPending(true);

            localStorage.removeItem('jwt_token');
            localStorage.removeItem('jwt_token_refresh');

            dispatch(reset());
            dispatch(clearCart());
            dispatch(resetTask());

            setPending(false);
        }

        props.onClickLogout && props.onClickLogout(e);
    }, [pending]);

    const onClickItem = (e: MouseEvent): void => {
        if(props.onClickItem) {
            props.onClickItem(e);
        }
    };

    const elLogout = useMemo(() => {
        if(userId) {
            const _icon = pending ? IconLoader : IconLogout;

            return (
                <a
                    href="#logout"
                    onClick={onClickLogout}
                    className={cn('menu__item', 'menu__item_interactive', 'menu__item_logout', {
                        'menu__item_disabled': pending,
                        'menu__item_logout-premium': isPremium
                    })}
                >
                    <_icon width={24} height={24} className={cn('menu__item-icon')} />
                    {/* <span className={cn('menu__item-content')}>{t('components.user.menu.buttons.logout')}</span> */}
                </a>
            );
        }
    }, [userId, pending, i18n.language]);

    return (
        <nav ref={$root} className={cn('menu')}>
            <span className={cn('menu__item', 'menu__item-email', {'menu__item-email-premium': isPremium})}>
                <div className={cn('menu__item__name')}>
                    {userName}
                    <span className={cn('menu__item__name-email', {'menu__item__name-email-premium': isPremium})}>
                        {userEmail}
                    </span>
                </div>
                {elLogout}
            </span>
            <Link to="/history" className={cn('menu__item')} onClick={onClickItem}>
                {t('components.user.menu.history')}
            </Link>
            <Link to="/subscriptions" className={cn('menu__item')} onClick={onClickItem}>
                {t('components.user.menu.subscriptions')}
            </Link>
            <Link to="/dashboard/photos" className={cn('menu__item')} onClick={onClickItem}>
                {t('components.user.menu.photos')}
            </Link>
            <Link to="/invited-albums" className={cn('menu__item')} onClick={onClickItem}>
                {t('components.user.menu.albums')}
            </Link>
            <Link to="/advertisements/my" className={cn('menu__item')} onClick={onClickItem}>
                <div className={cn('menu__item-new')}>{'new'}</div>
                {t('components.user.menu.advertisements')}
            </Link>
            <Link to="/premium-account" className={cn('menu__item')} onClick={onClickItem}>
                <div className={cn('menu__item-new')}>{'new'}</div>
                {t('components.user.menu.premium')}
            </Link>
            <Link to="/dashboard" className={cn('menu__item')} onClick={onClickItem}>
                {t('components.user.menu.dashboard')}
            </Link>
        </nav>
    );
};

export default Menu;
