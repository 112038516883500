import React, { ReactNode } from 'react';

import styles from './style.pcss';
import { useClassnames } from 'hook/use-classnames';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    addonBefore?: ReactNode;
    addonAfter?: ReactNode;
    onClickAddonBefore?(): void;
    onClickAddonAfter?(): void;
    label?: string;
}

const Input = ({ addonBefore, addonAfter, onClickAddonBefore, onClickAddonAfter, label, ...props }: IInputProps) => {
    const cn = useClassnames(styles);

    return (
        <div>
            <p>{label}</p>
            <div className={cn('input')}>
                {addonBefore && <div className={cn('input__addon-before')} onClick={onClickAddonBefore}>{addonBefore}</div>}
                <input
                    {...props}
                    className={cn('input__component', props.className)}
                />
                {addonAfter && <div className={cn('input__addon-after')} onClick={onClickAddonAfter}>{addonAfter}</div>}
            </div>
        </div>
    );
};

export default Input;
