import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Redirect } from 'react-router-dom';

import cancelToken from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import IconError from 'component/icon/error';
import Form, { useRegistry } from 'component/form';
import sortPhotoArray, { ISortedMap } from 'component/helper/new-sort-photos-list';
import useIntersect from 'hook/use-intersect';

import style from './index.pcss';
import api from 'src/api';
import { SharedAlbumItem, SharedAlbumPhoto, GetSharedAlbumPhotoData } from 'src/api/shared-albums/types';
import { Page } from 'src/api/base';
import NewPhotoList from 'component/photo-list/new-photo-list';
import FilterForm from 'component/form/filter-form';
import InputPhoto from 'component/form/input-photo';
import IconCross from 'component/icon/cross';
import { DataPersonsItem } from 'component/api/types/api/main/get-person-list/get/code-200';
import {
    DataFilesItem,
    DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { parse, stringify } from 'query-string';
import { getNormalizedQuery } from 'component/advertisements/utils';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { getFile } from 'component/api/tmp-file';
import { AlbumPerson } from 'src/api/albums/types';
import PersonCarousel from 'component/person-carousel';
import CarouselItem from 'component/person-carousel/item';
import useDidUpdateEffect from 'hook/use-did-update-effect';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { CreateTempFile } from 'src/api/files/types';

const PHOTOS_LIMIT = 25;

const InvitedAlbum = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();
    const tokenAlbums = useMemo(cancelToken.create, []);
    const tokenPhotoList = useMemo(cancelToken.create, []);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const { id }: { id?: string } = useParams();

    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].tablet);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [error, setError] = useState<string | null>(null);

    const { field, form } = useRegistry();
    const [validity, setValidity] = useState<boolean>(false);
    const [image, setImage] = useState<CreateTempFile | null>(null);
    const [isQueryChecked, setIsQueryChecked] = useState<boolean>(false);

    const [persons, setPersons] = useState<Array<DataPersonsItem>>([]);
    const [album, setAlbum] = useState<SharedAlbumItem>();
    const [photosMap, setPhotosMap] = useState<ISortedMap | null>(null);
    const [isAlbumPending, setIsAlbumPending] = useState<boolean>(true);
    const [pendingFile, setPendingFile] = useState<boolean>(false);

    const [personsList, setPersonsList] = useState<Array<AlbumPerson>>([]);
    const [personsTotal, setPersonsTotal] = useState<number>(0);
    const [isPersonsLoadMore, setIsPersonsLoadMore] = useState<boolean>(false);
    const [isPersonsPending, setIsPersonsPending] = useState<boolean>(false);
    const [isPersonsMerge, setIsPersonsMerge] = useState<boolean>(false);
    const [isPersonsNext, setIsPersonsNext] = useState<boolean>(false);
    const [personsPage, setPersonsPage] = useState<number>(1);

    const [chosenPersonIds, setChosenPersonIds] = useState<Array<number>>([]);

    // photo list;
    const [photoList, setPhotoList] = useState<Array<SharedAlbumPhoto>>([]);
    const [photoTotal, setPhotoTotal] = useState<number>(0);
    const [photoPage, setPhotoPage] = useState<number>(1);
    const [isPhotoPending, setIsPhotoPending] = useState<boolean>(false);
    const [isPhotoMerge, setIsPhotoMerge] = useState<boolean>(false);
    const [isPhotoLoadMore, setIsPhotoLoadMore] = useState<boolean>(false);
    const [isPhotoNext, setIsPhotoNext] = useState<boolean>(true);

    const getPersonsLimit = (): number => {
        if (isMobile) {
            return 4;
        }

        if (isTablet) {
            return 6;
        }

        return 10;
    };

    if (!isAuth) {
        return <Redirect to="/login" />;
    }

    useEffect(() => {
        !id && history.push('/404');

        return () => {
            tokenAlbums.remove();
            tokenPhotoList.remove();
        };
    }, []);

    useDidUpdateEffect(() => {
        onSubmitForm();
    }, [JSON.stringify(chosenPersonIds)]);

    useEffect(() => {
        if (isAlbumPending) {
            setError(null);

            api.shared.getSharedAlbumRetrieve(Number(id))
                .then((resp) => {
                    setAlbum(resp.data);
                    setIsAlbumPending(false);
                })
                .catch(() => {
                    setIsAlbumPending(false);
                    setError('Что-то пошло не так');
                });
        }

    }, []);

    useEffect(() => {
        if (isPersonsPending || isPersonsLoadMore) {
            const page: Page = {
                pageNumber: personsPage,
                pageSize: getPersonsLimit()
            };

            const data: GetSharedAlbumPhotoData = {
                ...(queryParams.search && { search: queryParams.search })
            };

            api.shared.getSharedAlbumPersonList(Number(id), page, data)
            .then((resp) => {
                setPersonsList(
                    isPersonsMerge ?
                        [...personsList, ...resp.data.results] : resp.data.results
                );
                setPersonsTotal(resp.data.count);
                setIsPersonsNext(!Boolean(resp.data.next === null));
            })
            .finally(() => {
                setIsPersonsPending(false);
                setIsPersonsLoadMore(false);
            })
            .catch(() => {
                setIsPersonsNext(false);
                setIsPersonsPending(false);
                setIsPersonsLoadMore(false);
            });
        }
    }, [isPersonsPending, isPersonsLoadMore]);

    // Get photo list
    useEffect(() => {
        if (isPhotoPending || isPhotoLoadMore) {
            const page: Page = {
                pageNumber: photoPage,
                pageSize: PHOTOS_LIMIT
            };

            const data: GetSharedAlbumPhotoData = {
                album_id: [Number(id)],
                ...(queryParams.color_id && { color_id: queryParams.color_id }),
                ...(queryParams.search && { search: queryParams.search }),
                ...(queryParams.event_date_from && { date_after: queryParams.event_date_from }),
                ...(queryParams.event_date_to && { date_before: queryParams.event_date_to }),
                ...(queryParams.is_stock && { is_stock: queryParams.is_stock }),
                ...(queryParams.is_person_find_partner && { is_person_find_partner: queryParams.is_person_find_partner }),
                ...(queryParams.number && { number_id: queryParams.number }),
                ...(queryParams.time_from && { time_after: queryParams.time_from }),
                ...(queryParams.time_to && { time_before: queryParams.time_to }),
                ...(queryParams.person_id && { person_id: queryParams.person_id }),
                ...(queryParams.photographer_id && { photographer_id: queryParams.photographer_id })
            };

            api.shared.getSharedAlbumPhotoList(Number(id), page, data)
                .then((resp) => {
                    setPhotoTotal(resp.data.count);
                    const newList = isPhotoMerge ?
                    [...photoList, ...resp.data.results] : resp.data.results;
                    setPhotoList(newList);
                    if (newList.length) {
                        setPhotosMap(sortPhotoArray(newList));
                    } else {
                        setPhotosMap(null);
                    }

                    setIsPhotoMerge(false);
                    setIsPhotoLoadMore(false);
                    setIsPhotoPending(false);

                    setIsPhotoNext(!!resp.data);
                })
                .catch(() => {
                    setIsPhotoMerge(false);
                    setIsPhotoLoadMore(false);
                    setIsPhotoPending(false);

                    setIsPhotoNext(false);
                });
        }
    }, [isPhotoPending, isPhotoLoadMore]);

    const onClickLoadMorePhotos = () => {
        if(!isPhotoPending && !isPhotoLoadMore && isPhotoNext) {
            setPhotoPage((prev) => prev + 1);
            setIsPhotoMerge(true);
            setIsPhotoLoadMore(true);
        }
    };

    const elButtonBeforePosts = useMemo(() => {
        if(!isPhotoPending && photoList?.length) {
            if(photoList?.length === photoTotal || !isPhotoNext) {
                return (
                    <span className={cn('invite__empty')}>
                        {/* {t('global.massage.empty.photo_list')} */}
                    </span>
                );
            }

            return (
                <Button
                    // ref={$bottomPreviousPosts}
                    onClick={onClickLoadMorePhotos}
                    disabled={isPhotoPending}
                    isLoading={isPhotoPending}
                    isSecondary={true}
                    className={cn('invite__button-before')}
                >
                    {t('global.button.load-more')}
                </Button>
            );
        }
    }, [isPhotoPending, isPhotoLoadMore, isPhotoNext]);

    useEffect(() => {
        if (!isPhotoPending) {
            setPhotoPage(1);
            setIsPhotoNext(true);
            setIsPhotoPending(true);
        }
    }, [queryParams]);

    useEffect(() => {
        setPersonsPage(1);
        setIsPersonsMerge(false);
        setIsPersonsPending(true);
    }, []);

    useEffect(() => {
        if (form.checkValidity() && !pendingFile) {
            setPersonsPage(1);
            setIsPersonsMerge(false);
            setIsPersonsPending(true);
        }
    }, [queryParams.search, queryParams.number, pendingFile]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    const onSubmitForm = debounce(useCallback(() => {
        // Get photos
        const payload = form.getPayload();
        const faces = payload.photo?.tmp_faces;
        let personsFaces: Array<DataFilesTmpFacesPersonsItem> = [];

        if(faces?.length) {
            personsFaces = faces.reduce((acc: Array<DataFilesTmpFacesPersonsItem>, curr: DataFilesTmpFacesItem) => {
                if(curr.persons?.length) {
                    curr.persons.forEach((person) => {
                        const isExist = acc.find((item) => item.id === person.id);

                        if(person.id && !isExist) {
                            acc.push(person);
                        }
                    });
                }

                return acc;
            }, []);
        }

        setPersons(personsFaces);

        let person_id: Array<number> = [];
        if (personsFaces.length) {
            person_id = chosenPersonIds.length ? chosenPersonIds.map((person) => person) : personsFaces.map((item) => item.id);
        } else if (personsList.length && chosenPersonIds.length) {
            person_id = chosenPersonIds.map((person) => person);
        } else if (queryParams.person_id && !isQueryChecked) {
            person_id = queryParams.person_id;
            if (typeof queryParams.person_id === 'string') {
                setChosenPersonIds([Number(queryParams.person_id)]);
            } else {
                const chosenPersons = queryParams.person_id.map((item: string) => { return Number(item); });
                setChosenPersonIds(chosenPersons);
            }
        }
        setIsQueryChecked(true);

        const data = {
            ...(payload.search && {search: payload.search }),
            ...(payload.photo && { file_id: payload.photo.id }),
            ...(person_id.length && {person_id}),
            ...(payload.name && { search: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.club?.value && { club_id: payload.club.value }),
            ...(payload.club?.label && { club_name: payload.club.label }),
            ...(payload.event_date_range?.date_from && { event_date_from: payload.event_date_range.date_from }),
            ...(payload.event_date_range?.date_to && { event_date_to: payload.event_date_range.date_to }),
            ...(payload.color && { color_id: payload.color }),
            ...(payload.sport_number && { number: payload.sport_number }),
            ...(payload.time && { time: payload.time }),
            ...(payload.partner_search && { is_person_find_partner: payload.partner_search }),
            ...(payload.is_stock && { is_stock: payload.is_stock }),
            ...(payload.suit_sale && { suit_sale: payload.suit_sale }),
            ...(payload.timeRange?.valueFrom && { time_from: payload.timeRange.valueFrom }),
            ...(payload.timeRange?.valueTo && { time_to: payload.timeRange.valueTo }),
            ...(payload.photo && { file_id: payload.photo.id })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [
        JSON.stringify(chosenPersonIds),
        validity,
        JSON.stringify(image),
        JSON.stringify(form.getPayload()),
        JSON.stringify(persons),
        JSON.stringify(personsList)
    ]), 300);

    const onReset = useCallback(() => {
        setChosenPersonIds([]);
        form.clearForm();
        window.scrollTo(0, 0);
        onSubmitForm();

        setPhotoPage(1);
        setIsPhotoMerge(false);
        setIsPhotoPending(true);

        setPersonsPage(1);
        setIsPersonsMerge(false);
        setIsPersonsPending(true);
    }, []);

    const onClickRemove = () => {
        setChosenPersonIds([]);
        form.clearForm();
        setImage(null);
    };

    const onFileLoaded = (file: CreateTempFile): void => {
        setImage(file);
    };

    const elImageResult = () => {
        if(image?.url) {
            return (
                <div className={cn('event__image-box')}>
                    <img className={cn('event__photo-image')} src={image.url} />
                </div>
            );
        }
    };

    const elUploadContent = useMemo(() => {
        return (
            <Fragment>
                {elImageResult()}
                <InputPhoto defaultValue={image || undefined} onFileLoaded={onFileLoaded} registry={field} name="photo" />
                {image?.url && (
                    <div className={cn('event__photo-menu')}>
                        <div onClick={onClickRemove} className={cn('event__photo-menu-item')}>
                            <IconCross className={cn('event__photo-menu-icon')} />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }, [JSON.stringify(image)]);

    const elList = useMemo(() => {
        if(photosMap) {
            const keys = Object.keys(photosMap);

            return keys.map((key, index) => {
                const link = `/invited-albums/${id}`;

                return (
                    <div key={index}>
                        <NewPhotoList
                            is_prepaid={album?.is_prepaid || false}
                            dateLink={true}
                            list={photosMap[key]}
                            elControls={false}
                            elPortal={false}
                            link={link}
                        />
                    </div>
                );
            });
        }
    }, [JSON.stringify(photosMap)]);

    const elContent = useMemo(() => {
        if (isAlbumPending || isPhotoPending) {
            return <Loader />;
        }

        if (!album) {
            return (
                <span className={cn('invite__empty')}>
                    {t('global.massage.empty.album')}
                </span>
            );
        }

        if (!photoTotal) {
            return (
                <span className={cn('invite__empty')}>
                    {t('global.massage.empty.search-photo')}
                </span>
            );
        }

        if (queryParams.file_id && !queryParams.person_id) {
            return (
                <span className={cn('invite__empty')}>
                    {t('global.massage.empty.search-photo')}
                </span>
            );
        }

        if(photosMap) {
            return elList;
        }
    }, [album, photoTotal, isAlbumPending, isPhotoPending, JSON.stringify(photosMap)]);

    const elError = useMemo(() => {
        if (error) {
            return (
                <div className={cn('invite__error')}>
                    <IconError className={cn('invite__error-icon')} />
                    {error}
                </div>
            );
        }
    }, [error]);

    const elPageHeader = useMemo(() => {
        if(album?.name) {
            return <h1 className={cn('invite__header')}>{album.name}</h1>;
        }
    }, [album]);

    const choosePersons = (personId: number) => {
        const index = chosenPersonIds.indexOf(personId);
        let newList = [...chosenPersonIds];

        if(index > -1) {
            newList = newList.filter((item) => item !== personId);
        } else {
            newList.push(personId);
        }

        setChosenPersonIds(newList);
    };

    const onClickPerson = (personId: number) => {
        choosePersons(personId);
    };

    const onClickNext = useCallback(() => {
        if (isPersonsNext && !isPersonsPending && !isPersonsLoadMore) {
            setPersonsPage((prevState) => prevState + 1);
            setIsPersonsMerge(true);
            setIsPersonsLoadMore(true);
        }
    }, [isPersonsNext, isPersonsPending, isPersonsLoadMore]);

    const elPersonCarousel = useMemo(() => {
        if (isPersonsPending) {
            return <Loader />;
        }

        if (personsList.length) {
            return (
                <PersonCarousel onClickNext={onClickNext} isLoading={isPersonsLoadMore}>
                    {
                        personsList.map((item, index) => {
                            const params = {
                                item: {
                                    id: item.id,
                                    name: (item.first_name && item.last_name) ? item.full_name : item.id.toString(),
                                    photo_url: item.photo,
                                    is_partner_profile: item.is_partner_profile
                                },
                                // onClick: onClickPerson(item.id),
                                isSelected: chosenPersonIds.indexOf(item.id) > -1
                            };

                            return <CarouselItem key={index} {...params} onClick={() => onClickPerson(item.id)} />;
                        })
                    }
                </PersonCarousel>
            );
        } else {
            return (
                <UI.Box className={cn('event__box')} padding={true}>
                    <div className={cn('search__persons-content')}>
                        Ничего не найдено
                    </div>
                </UI.Box>
            );
        }
    }, [isPersonsPending, isPersonsLoadMore, JSON.stringify(personsList), JSON.stringify(chosenPersonIds)]);

    return (
        <UI.Main className={cn('invite')}>
            {elPageHeader}
            <div className={cn('invite__grid')}>
                <div className={cn('invite__content')}>
                    {personsTotal > 0 && (
                        <>
                            <UI.BoxHeader className={cn('invite__content__person-title')}>Найденные персоны</UI.BoxHeader>
                            <div className={cn('invite__content__person-amount')}>
                                Общее количество персон: {personsTotal}
                            </div>
                            <UI.Box padding={false} className={cn('invite__content__slider')}>
                                {elPersonCarousel}
                            </UI.Box>
                        </>
                    )}
                    <section className={cn('invite__bottom')}>
                        <UI.Main className={cn('invite__bottom-content')}>
                            <UI.Content>
                                <UI.Box padding={true} className={cn('invite__content-box')}>
                                    {elError}
                                    {elContent}
                                    {elButtonBeforePosts}
                                </UI.Box>
                            </UI.Content>
                        </UI.Main>
                    </section>
                </div>
                <UI.Sidebar className={cn('invite__sidebar')}>
                        <UI.Box padding={true} className={cn('event__box')}>
                            <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
                            <Form registry={form}>
                                {elUploadContent}
                            </Form>
                        </UI.Box>
                        <UI.Box padding={false} className={cn('event__box')}>
                            <FilterForm
                                registry={{form, field}}
                                color={true}
                                personNumber={true}
                                timeRange={true}
                                search={true}
                                searchPrompt={true}
                                eventDate={true}
                                partnerSearch={true}
                                stockPhotos={true}
                                onSubmitForm={onSubmitForm}
                                onChangeValidity={setValidity}
                                onReset={onReset}
                            />
                        </UI.Box>
                </UI.Sidebar>
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line:max-file-line-count
export default InvitedAlbum;
