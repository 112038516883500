import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import style from './index.pcss';
import api from 'src/api';

const Invite = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();

    const { token }: { token: string } = useParams();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [pendingToken, setPendingToken] = useState<boolean>(false);
    const [status, setStatus] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);

    useEffect(() => {
        if (!isAuth) {
            history.push(`/login?from=/invite/${token}`);

            return;
        }

        _requestCheckToken();
    }, []);

    const _requestCheckToken = () => {
        setStatus(false);
        setPendingToken(true);

        api.albums.checkCreateAlbumsInvites(token)
            .then(() => {
                setIsValidated(true);
            })
            .catch((err) => {
                setIsValidated(false);
                setStatus(false);
                setPendingToken(false);
            });
    };

    useEffect(() => {
        if (isValidated) {
            api.albums.confirmCreateAlbumsInvites(token)
                .then(() => {
                    setStatus(true);
                    setPendingToken(false);
                })
                .catch(() => {
                    setStatus(false);
                    setPendingToken(false);
                });
        }
    }, [isValidated]);

    const onClickButton = (e: MouseEvent): void => {
        e.preventDefault();
        history.push('/invited-albums');

        return;
    };

    const elContent = useMemo(() => {
        if(pendingToken) {
            return <Loader />;
        }

        return (
            <>
                {(isValidated && status) ? (
                    <div>{t('route.invite.access-text')}</div>
                ) : (isValidated && !status) ? (
                    <div>{t('route.invite.error-check-token')}</div>
                ) : (
                    <div>{t('route.invite.bad-token')}</div>
                )}
                <div className={cn('invite__wrapper-button')}>
                    <Button
                        className={cn('invite__button')}
                        onClick={onClickButton}
                    >
                        {t('route.invite.button-albums')}
                    </Button>
                </div>
            </>
        );
    }, [pendingToken, status]);

    return (
        <UI.Main className={cn('invite')}>

            <UI.Box padding={true}>
                <h1 className={cn('invite__header')}>{t('route.invite.header')}</h1>
                {elContent}
            </UI.Box>
        </UI.Main>
    );
};

export default Invite;
