import React, { useEffect, useMemo, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory } from 'react-router';

import useIntersect from 'hook/use-intersect';
import { useClassnames } from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';

import UI from 'component/ui';
import Loader from 'component/loader';
import Error from 'component/error';
import Button from 'component/button';

import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

import SubscriptionsItem from './subscription-item';
import style from './index.pcss';
import api from 'src/api';
import { SubscriptionsItem as SubscriptionsItemAPI } from 'src/api/subscriptions/types';
import { Page } from 'src/api/base';

const LIMIT = 15;

export default () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();
    const history = useHistory();

    const [pending, setPending] = useState<boolean>(true);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);
    const [list, setList] = useState<Array<SubscriptionsItemAPI>>([]);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState<number>(0);
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);

    const [isSubscriptionsPending, setIsSubscriptionsPending] = useState<boolean>(false);
    const [isSubscriptionsMerge, setIsSubscriptionsMerge] = useState<boolean>(false);
    const [isSubscriptionsNext, setIsSubscriptionsNext] = useState<boolean>(false);
    const [subscriptionsPage, setSubscriptionsPage] = useState<number>(1);

    useEffect(() => {
            if(error) {
                setError(null);
            }

            if (isSubscriptionsPending) {
                setPending(true);
                const page: Page = {
                    pageNumber: subscriptionsPage,
                    pageSize: LIMIT
                };

                api.subscriptions.getSubscriptionsList(page)
                    .then((resp) => {
                        setList(
                            isSubscriptionsMerge ?
                                [...list, ...resp.data.results] : resp.data.results
                        );

                        setTotal(resp.data.count);
                        setIsSubscriptionsNext(!Boolean(resp.data.next === null));
                        setPending(false);
                        setPendingBefore(false);
                    })
                    .finally(() => {
                        setIsSubscriptionsPending(false);
                    })
                    .catch((e) => {
                        setIsSubscriptionsNext(false);
                        setIsSubscriptionsPending(false);
                        setPending(false);
                        setError(e.message);
                    });
            }

    }, [isSubscriptionsPending]);

    useEffect(() => {
        setTimeout(() => {
            const authToken = localStorage.getItem('jwt_token');
            if (isAuth && authToken) {
                setIsSubscriptionsPending(true);
            }
        }, 300);
    }, [isAuth]);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting && isSubscriptionsNext && total !== list.length && isSubscriptionsPending) {
            setIsSubscriptionsMerge(true);
            setIsSubscriptionsPending(true);
        }
    }, {
        rootMargin: '100px 0px'
    });

    const onClickButtonBefore = (e: MouseEvent): void => {
        e.preventDefault();

        if (isSubscriptionsNext && !isSubscriptionsPending) {
            setIsSubscriptionsMerge(true);
            setSubscriptionsPage((prev) => prev + 1);
            setIsSubscriptionsPending(true);
        }
    };

    const renewList = () => {
        setSubscriptionsPage(1);
        setIsSubscriptionsNext(true);
        setIsSubscriptionsMerge(false);
        setIsSubscriptionsPending(true);
    };

    const elButtonBeforePosts = () => {
        if(!pending && list.length) {
            if(list.length !== total) {
                return (
                    <Button
                        ref={$bottomPreviousPosts}
                        disabled={pendingBefore}
                        isLoading={pendingBefore}
                        isSecondary={true}
                        className={cn('subs__button-before')}
                        onClick={onClickButtonBefore}
                    >
                        {t('route.home.button.before')}
                    </Button>
                );
            }
        }
    };

    const elError = useMemo(() => {
        if(error) {
            return <Error>{error}</Error>;
        }
    }, [error]);

    const elContent = useMemo(() => {
        if (!list.length && !pending) {
            return <p className={cn('subs__content-empty')}>У вас еще нет активных подписок.</p>;
        }

        if (pending) {
            return <Loader />;
        }

        if (list.length) {
            return list.map((item) => (
                <SubscriptionsItem
                    onRenewList={renewList}
                    item={item}
                    key={item.id}
                />
            ));
        }
    }, [JSON.stringify(list), pending]);

    const elButton = () => {
        return (
            <div className={cn('subs__btn')}>
                <Button
                    onClick={() => history.push('/search')}
                >
                    Найти фотографии
                </Button>
            </div>
        );
    };

    if(!isAuth) {
        return <Redirect to="/login" />;
    }

    return (
        <UI.Main className={cn('subs')}>
            <UI.PageHeader className={cn('subs__title')} text={'Мои подписки'} />
            <div className={cn('subs__content-block')}>
                <UI.Box padding={true} className={cn('subs__content')}>
                    {elContent}
                    {elError}
                    {elButtonBeforePosts()}
                </UI.Box>
            </div>
            {!list.length && elButton()}
        </UI.Main>
    );
};
