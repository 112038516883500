import React, { useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import cancelToken, { IReturnInterface, useCancelTokens } from 'component/core/cancel-token';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';

import { useClassnames } from 'hook/use-classnames';
import Modal from 'component/modal';
import Button from 'component/button';
import Loader from 'component/loader';
import ArrowDirDown from 'component/icon/arrow-dir-down';
import Directory from 'component/icon/directory';
import ImagesIcon from 'component/icon/images';

import { userSelfInfo } from 'component/api/user';
import { set } from 'store/reducers/user/actions';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import { IPropsExternal, IDataItemExternal, IListData } from './types';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import style from './index.pcss';
import Presets from './presets';
import { useRegistry } from '..';
import ModalMessageSetPhone from 'component/modal/message-set-phone';
import api from 'src/api';

const searchTree = (nodes: Array<IDataItemExternal>, searchPath: string): IDataItemExternal | null => {
    for (const element of nodes) {
        if(element.value === searchPath) {
            return element;
        } else if (element.children?.length) {
            let i;
            let result = null;
            for(i = 0; result === null && i < element.children.length; i++) {
                // @ts-ignore
                result = searchTree(element.children[i], searchPath);
            }

            return result;
        }

        return null;
    }

    return null;
};

const ModalExternal = (props: IPropsExternal) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const registry = useRegistry();
    const [tokenAuthInit, tokenAuthExit, tokenList, tokenEstimate, tokenCreateDownload, tokenUserSelfInfo] = useCancelTokens(6);
    const tokens = useRef<Array<IReturnInterface>>([]);

    const isYandexAuth = useSelector<IStore, boolean | null>((store) => store[keyUser].is_yandex_auth || null);
    const userPhone = useSelector<IStore, string | null>((store) => store[keyUser].fps_phone_number || null);
    const phoneStatus = useSelector<IStore, string | null>((store) => store[keyUser]?.phone_number_status || null);

    const [checked, setChecked] = useState<Array<string>>([]);
    const [expanded, setExpanded] = useState<Array<string>>([]);
    const [list, setList] = useState<Array<IDataItemExternal>>([]);
    const [pending, setPending] = useState<boolean>(false);
    const [pendingEstimate, setPendingEstimate] = useState<boolean>(false);
    const [estimate, setEstimate] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [totalFiles, setTotalFiles] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [warning, setWarning] = useState<string | null>(null);
    const [modalPrice, setModalPrice] = useState<boolean>(false);

    const _requestInit = () => {
        setPending(true);

        api.auth.getAuthYandexUrl()
        .then((resp) => {
            window.location.href = resp.data;
        })
        .catch((err) => {
            console.warn(err);
            setPending(false);
        });
    };

    const _requestExit = (): void => {
        setPending(true);
        setError(null);

        api.auth.delAuthYandexRevoke()
            .then((resp) => {
                return api.accounts.getAccountRetrieve();
            })
            .then((payload) => {
                dispatch(set(payload.data));

                if (props.onCloseModal) {
                    props.onCloseModal();
                }
            })
            .catch((err) => {
                console.warn(err);
                setError('Ошибка выхода из диска. Повторите попытку позже.');
                setPending(false);
            });
    };

    const _requestList = (path: string): void => {
        setPending(true);
        setError(null);
        setWarning(null);

        const filter = {
            item_type: 'dir',
            path
        };

        api.cloud.getYandexDiskItems(filter)
            .then((resp) => {
                const newList = resp.data.items.map((item) => ({
                    value: item.path || '',
                    label: item.name || ''
                }));

                setPending(false);
                checkNesting(newList);
            })
            .catch((err) => {
                console.warn(err);
                setError('Не удалось загрузить список каталогов');
                setPending(false);
            });
    };

    const checkNesting = (data: Array<IDataItemExternal>, node: string | null = null) => {
        setPending(true);

        const promises: Array<Promise<IListData>> = [];

        data.forEach((item, index) => {
            tokens.current[index] = cancelToken.create();
            const filter = {
                item_type: 'dir',
                path: item.value
            };

            promises.push(new Promise((resolve, reject) => {
                api.cloud.getYandexDiskItems(filter)
                    .then((resp) => {
                        const newList = resp.data.items.map((itemList) => ({
                            value: itemList.path || '',
                            label: itemList.name || ''
                        }));

                        if (newList.length) {
                            item.children = newList;
                        }

                        resolve(resp.data);

                        tokens.current[index].remove();

                        // tslint:disable-next-line no-dynamic-delete
                        delete tokens.current[index];
                    })
                    .catch((err) => {
                        console.warn(err);
                        reject(index);
                    });

            }));
        });

        Promise.all(promises.map((promise) => {
            return promise
                .then((response) => ({
                    status: 'fulfilled',
                    response
                }))
                .catch((response) => ({
                    status: 'rejected',
                    response
                }));
        })).then((responses) => {
            if (node) {
                const newList = [...list];
                const res = searchTree(newList, node);
                if (res) {
                    res.children = data;
                    setList(newList);
                }
            } else {
                setList(data);
            }

            setPending(false);
        });
    };

    useEffect(() => {
        if (props.service === 'yandex') {
            isYandexAuth ? _requestList('/') : _requestInit();
        }

        return () => {
            tokenAuthInit.remove();
            tokenList.remove();
        };
    }, []);

    const _requestEstimate = (): void => {
        setPendingEstimate(true);
        setError(null);

        const data = {
            paths: checked.map((item) => item),
            item_type: 'file'
        };

        api.cloud.getYandexDiskItemsTotal(data)
            .then((resp) => {
                setTotalFiles(resp.data.count);
                if (resp.data.count > 0) {
                    setEstimate(true);
                }
                setPendingEstimate(false);
            })
            .catch((err) => {
                console.warn(err);
                setError('Ошибка обработки фотографий. Повторите попытку позже.');
                setPendingEstimate(false);
            });
    };

    const onExit = useCallback(() => {
        _requestExit();
    }, []);

    const onCheck = useCallback((arr) => {
        setChecked(arr);
    }, []);

    const onExpanded = useCallback((arrExpanded: Array<string>, targetNode: IDataItemExternal) => {
        if (targetNode?.children?.length) {
            checkNesting(targetNode.children, targetNode.value);
        }
        setExpanded(arrExpanded);
    }, []);

    const onClickContinue = useCallback(() => {
        setTotalFiles(null);
        if (!checked.length) {
            return setWarning('Необходимо выбрать каталог');
        }

        setWarning(null);
        _requestEstimate();
    }, [checked]);

    const elCount = useMemo(() => {
        return (
            <div className={cn('input-external__count')}>
                Выбрано каталогов: {checked.length}
            </div>
        );
    }, [checked]);

    const submitFiles = (is_params: boolean) => (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        const presets = getUploadPresets();
        let photo_data;
        let paramsValid = true;

        if (is_params) {
            if (presets.year && presets.year.value?.value && presets.event && !presets.event.value?.value) {
                presets.event.setError('Укажите событие');
                paramsValid = false;
            }

            if (presets.price && presets.price?.value && Number(presets.price?.value) < 20) {
                presets.price.setError('Минимальная стоимость 20 руб.');
                paramsValid = false;
            }

            if (presets.album && !presets.album?.value?.value) {
                presets.album.setError('Укажите альбом');
                paramsValid = false;
            }

            if (!paramsValid) {
                setWarning('Заполните параметры фото');

                return void(0);
            }

            if (!userPhone || phoneStatus === 'ON_MODERATION') {
                    setModalPrice(true);

                    return void(0);
            }
        }

        if (is_params) {
            photo_data = {
                ...(presets.price?.value && { price: Number(presets?.price?.value) }),
                ...(presets.event?.value.value && { event_id: Number(presets?.event?.value?.value) }),
                ...(presets.album?.value.value && { album_id: Number(presets?.album?.value?.value) })
            };
        }

        setPending(true);
        setError(null);
        setWarning(null);

        api.cloud.createYandexPhoto(
            checked.map((item) => item),
            photo_data
            )
            .then((resp) => {
                setCreate(true);
                setPending(false);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 400:
                        setError(err.response.data.message);
                        break;
                    case 409:
                        setError('Данный каталог уже загружается. Дождитесь загрузки.');
                        break;
                    default:
                        setError('Неизвестная ошибка...');
                        break;
                }
                setPending(false);
            });
    };

    const onClickCloseMessage = (): void => {
        setModalPrice(false);
    };

    const elModalMessageSetPhone = useMemo(() => {
        if (modalPrice) {
            return <ModalMessageSetPhone onClickClose={onClickCloseMessage} />;
        }
    }, [modalPrice]);

    const elTree = useMemo(() => {
        if (pending) {
            return (
                <Loader
                    theme="pink"
                    text="Загрузка каталогов..."
                />
            );
        }

        if (pendingEstimate) {
            return (
                <Loader
                    theme="pink"
                    text="Подсчет фотографий..."
                />
            );
        }

        if (create) {
            return (
                <div className={cn('input-external__wrapper-images')}>
                    <div>Фотографии загружаются...</div>
                    <div>Через некоторое время Вы можете просмотреть их в разделе "Мои фотографии"</div>
                </div>
            );
        }

        if (totalFiles && totalFiles > 0) {
            return (
                <div className={cn('input-external__wrapper-images')}>
                    <ImagesIcon className={cn('input__icon', 'input__icon_images')} />
                    Найдено Фотографий: {totalFiles}
                </div>
            );
        }

        return (
            // @ts-ignore
            <CheckboxTree
                icons={{
                    check: <div className={cn('input-external__checkbox-inner', 'input-external__checkbox-inner_checked')} />,
                    uncheck: <div className={cn('input-external__checkbox-inner')} />,
                    halfCheck: <div className={cn('input-external__checkbox-inner', 'input-external__checkbox-inner_half')} />,
                    expandClose: <ArrowDirDown className={cn('input-external__icon-rotate')} />,
                    expandOpen: <ArrowDirDown />,
                    parentClose: <Directory className={cn('input-external__icons')} />,
                    parentOpen: <Directory className={cn('input-external__icons')} />,
                    leaf: <Directory className={cn('input-external__icons')} />
                }}
                nodes={list}
                checked={checked}
                expanded={expanded}
                onCheck={onCheck}
                // @ts-ignore
                onExpand={onExpanded}
            />
        );
    }, [
        JSON.stringify(list),
        JSON.stringify(checked),
        JSON.stringify(expanded),
        pending, pendingEstimate,
        estimate, create, totalFiles
    ]);

    const elParams = useMemo(() => {
        if (estimate && !create && !error) {
            return(
                <Presets registry={registry} />
            );
        }
    }, [estimate, create, error]);

    const elError = useMemo(() => {
        const message = error
            ? error
            : totalFiles === 0 ? 'В выбранных каталогах нет фотографий для загрузки!' : null;

        const warn = warning;

        if (message) {
            return (
                <div className={cn('input-external__error')}>
                    {message}
                </div>
            );
        }

        if (warn) {
            return (
                    <div className={cn('input-external__error', 'input-external__error_warn')}>
                        {warn}
                    </div>
            );
        }
    }, [totalFiles, error, warning]);

    const elButtonNext = useMemo(() => {
        if (create || error) {
            return(
                <Button
                    type="button"
                    isSmall={true}
                    disabled={pending || pendingEstimate}
                    onClick={props.onCloseModal}
                >
                    Закрыть
                </Button>
            );
        }

        if (estimate && !error && !create) {
            return(
                <div className={cn('input-external__buttons-params')}>
                    <Button
                        type="button"
                        isSmall={true}
                        disabled={pending || pendingEstimate || !totalFiles || !!error}
                        onClick={submitFiles(true)}
                    >
                        Начать загрузку с параметрами
                    </Button>
                </div>
            );
        }

        return (
            <Button
                type="button"
                isSmall={true}
                disabled={pending || pendingEstimate || !!error}
                onClick={onClickContinue}
            >
                Продолжить
            </Button>
        );
    }, [
        JSON.stringify(list),
        JSON.stringify(checked),
        JSON.stringify(expanded),
        pending, pendingEstimate,
        estimate, create, totalFiles,
        error
    ]);

    const getUploadPresets = () => {
        const fields = registry.form.getFields();

        return {
            year: fields && fields.get('upload_year') || null,
            event: fields && fields.get('upload_tournament') || null,
            price: fields && fields.get('upload_price') || null,
            album: fields && fields.get('upload_album') || null
        };
    };

    return (
        <Modal onClickClose={props.onCloseModal}>
            <h3 className={cn('input-external__header')}>Выберите каталог для импорта</h3>
            {elError}
            <div
                className={cn('input-external__wrapper', {
                    'input-external__wrapper-center': pending || pendingEstimate || create || estimate
                })}
            >
                {elTree}
            </div>
            {elModalMessageSetPhone}
            {elCount}
            {elParams}
            <div className={cn('input-external__footer')}>
                {!error && !create && (
                    <ControlButton action={onExit} title={'Выйти из диска'} active={pending || pendingEstimate} />
                )}
                <div className={cn('input-external__buttons')}>
                    {!error && !create && (
                        <ControlButton action={props.onCloseModal} title={'Отмена'} active={pending || pendingEstimate} />
                    )}
                    {elButtonNext}
                </div>
            </div>
        </Modal>
    );
};

const ControlButton = ({ action, title, active }: { action?(e: MouseEvent): void, title: string, active: boolean}) => {
    const cn = useClassnames(style);

    return (
        <Button
            type="button"
            className={cn('input-external__button-exit')}
            isSmall={true}
            isSecondary={true}
            disabled={active}
            onClick={action}
        >
            {title}
        </Button>
    );
};

// tslint:disable-next-line:max-file-line-count
export default ModalExternal;
