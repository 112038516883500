import React, { useState, useEffect, useRef, useMemo, FC, MouseEvent, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { useClassnames } from 'hook/use-classnames';
import qaAttributes from 'component/helper/qa-attributes';
import ImagesIcon from 'component/icon/images';
import DownloadIcon from 'component/icon/download';
import Loader from 'component/loader';
import Error from 'component/error';
import Button from 'component/button';
import Status from 'route/upload/status';
import ModalExternal from './external';
import ModalMessageSetPhone from 'component/modal/message-set-phone';
import Presets from './presets';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

import { IProps, TError, IDefaultPresets, IDefaultPresetsItem } from './types';
import style from './index.pcss';
import ModalExternalLink from './external-link';
import api from 'src/api';
import { CreatePhotoData, CreatePhotoItem } from 'src/api/photos/types';
import { FileItem } from './class-item';
import LoadingPhotoList from './loading-photos';

const ERROR_CLEAR_TIMER = 10000;

const formatSize = (bytes: number): string => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

    let l = 0;
    let n = bytes || 0;

    // tslint:disable-next-line increment-decrement
    while(n >= 1024 && ++l) {
        n = n / 1024;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};

const InputFile: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();

    const userPhone = useSelector<IStore, string | null>((store) => store[keyUser].fps_phone_number || null);
    const phoneStatus = useSelector<IStore, string | null>((store) => store[keyUser]?.phone_number_status || null);

    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [value, setValue] = useState<Array<CreatePhotoItem>>([]);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [errorRequest, setErrorRequest] = useState<TError>(null);
    const [firstClick, setFirstClick] = useState<boolean>(false);
    const [canStartLoading, setCanStartLoading] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [createPending, setCreatePending] = useState<boolean>(false);
    const [size, setSize] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [service, setService] = useState<string>('');
    const [modalPrice, setModalPrice] = useState<boolean>(false);
    const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
    const photoDataRef = useRef<Omit<CreatePhotoData, 'file'> | {}>({});
    const $label = useRef<HTMLLabelElement>(null);

    const uploadQueue = useRef<Array<{file: FormData, status: string, index: number}>>([]);
    // new

    const [uploadProgress, setUploadProgress] = useState<Array<number>>([]);
    const [errorList, setErrorList] = useState<Array<string | null>>([]);
    const [fileItems, setFileItems] = useState<Array<FileItem>>([]);
    const [isLoadingPhotos, setIsLoadingPhotos] = useState<boolean>(false);
    const [loadedSize, setLoadedSize] = useState<number>(0);
    const [loadedItems, setLoadedItems] = useState<number>(0);
    const [isFinishedLoading, setIsFinishedLoading] = useState<boolean>(false);

    const refValue = useRef<Array<CreatePhotoItem>>(value);

    const getUploadPresets = () => {
        const fields = props.registry.form.getFields();

        return {
            year: fields && fields.get('upload_year') || null,
            event: fields && fields.get('upload_tournament') || null,
            price: fields && fields.get('upload_price') || null,
            album: fields && fields.get('upload_album') || null,
            is_save_params: fields && fields.get('save_params') || null
        };
    };

    const stopUpload = () => {
        uploadQueue.current = [];
        clearState();
    };

    useEffect(() => {
        const presets = getUploadPresets();

        if (presets.year && !presets.year.value?.value) {
            presets.event?.setError(null);
        }
    }, [getUploadPresets()]);

    useEffect(() => {
        return () => {
            stopUpload();
        };
    }, []);

    const onDrop = useCallback((files: Array<File>): void => {
        if(errorExternal) {
            setErrorExternal(null);
        }

        if(errorRequest) {
            setErrorRequest(null);
        }

        setPending(true);

        const newFileItems: Array<FileItem> = files.map((file, index) => new FileItem(file, index));

        setFileItems(() => [...newFileItems]);
        setUploadProgress(() => {
            return newFileItems.map(() => 0);
        });

        if(files) {
            const sizes: Array<number> = [];

            // tslint:disable-next-line prefer-for-of
            for(let i = 0; i < files.length; i++) {
                const maxSizeValue = props.maxSize ? files[i].size <= props.maxSize * 1024 * 1024 : true;
                sizes.push(files[i].size);

                if (!maxSizeValue) {
                    const newErrorRequest = t('components.form.input-file.error-max-size', {
                        name: files[i].name
                    });

                    setErrorRequest(newErrorRequest);
                }

                if(i + 1 === files.length) {
                    setPending(false);
                }
            }

            const newSize = sizes.reduce((accumulator: number, current: number) => accumulator + current, 0);

            setSize(newSize);
        }

        if(newFileItems.length) {
            setCanStartLoading(true);
        }

        setPending(false);
    }, [fileItems.length, errorExternal, errorRequest]);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = !!value.length;

            if(!newIsValid) {
                newErrorInternal = t('components.form.input-file.error');
            }
        }

        if(newIsValid && errorRequest) {
            newErrorInternal = errorRequest;
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect(() => {
        checkValidity();
    }, [errorRequest]);

    useEffect(() => {
        props.registryField.set(props.name, {
            value,
            setError  : setErrorExternal,
            isAutoFill: false,
            clear     : () => {
                setValue([]);
            },
            isValid: checkValidity()
        });

        const handler = props.registryField.onChange();

        if(handler) {
            handler();
        }

        refValue.current = value;
    }, [JSON.stringify(value)]);

    const onClickSubmitFiles = () => (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        const presets = getUploadPresets();

        let paramsValid = true;

        if (presets.year && presets.year.value?.value && presets.event && !presets.event?.value?.value) {
            presets.event.setError('Укажите событие');
            paramsValid = false;
        }

        if (presets.price && presets.price.value && (Number(presets.price?.value) < 20)) {
            presets.price.setError('Минимальная стоимость 20 руб.');
            paramsValid = false;
        }

        if (presets.album && !presets.album?.value?.value) {
            presets.album.setError('Укажите альбом');
            paramsValid = false;
        }

        if (!paramsValid && !props.isPhotoPreview) {
            return void(0);
        }

        if ((!userPhone || phoneStatus === 'ON_MODERATION')
            && presets.price
            && presets.price.value
        ) {
            setModalPrice(true);

            return void(0);
        }

        if (!!presets.is_save_params?.value && paramsValid && !props.isPhotoPreview) {
            const yearPreset: IDefaultPresetsItem = {};

            if (presets.year && presets.year.value && presets.year.value.value && presets.year.value.label) {
                yearPreset.value = presets.year?.value.value;
                yearPreset.label = presets.year?.value.label;
            }

            const eventPreset: IDefaultPresetsItem = {};

            if (presets.event && presets.event.value && presets.event.value.value && presets.event.value.label) {
                eventPreset.value =  presets.event?.value.value;
                eventPreset.label = presets.event?.value.label;
            }

            const albumPreset: IDefaultPresetsItem = {};

            if (presets.album && presets.album.value && presets.album.value.value && presets.album.value.label) {
                albumPreset.value =  presets.album?.value.value;
                albumPreset.label = presets.album?.value.label;
            }

            const defaultParams: IDefaultPresets = {
                year: yearPreset.value && yearPreset.label ? yearPreset : {},
                event: eventPreset.value && eventPreset.label ? eventPreset : {},
                price: presets.price?.value ? presets.price?.value : '',
                album: albumPreset.value && albumPreset.label ? albumPreset : {}
            };

            localStorage.setItem('upload_params', JSON.stringify(defaultParams));
        }

        if (!presets.is_save_params?.value) {
            localStorage.removeItem('upload_params');
        }

        let dataCreatePhoto: Omit<CreatePhotoData, 'file'> = {
            price: String(presets.price?.value),
            ...(presets.event?.value?.value && { event: Number(presets.event?.value?.value) }),
            album: Number(presets.album?.value?.value)
        };

        if (!props.isPhotoPreview) {
            dataCreatePhoto = {
                price: String(presets.price?.value),
                ...(presets.event?.value?.value && { event: Number(presets.event?.value?.value) }),
                album: Number(presets.album?.value?.value)
            };
        } else {
            const uploadVideoPresets = localStorage.getItem('upload_video_params');
            if (props.uploadingInfo) {
                dataCreatePhoto = {
                    price: String(presets.price?.value),
                    event: Number(props.uploadingInfo.event?.value),
                    album: Number(props.uploadingInfo.album?.value)
                };
            } else if (props.isHasVideo) {
                dataCreatePhoto = {
                    price: String(presets.price?.value),
                    event: props.isHasVideo.event.id,
                    album: props.isHasVideo.album.id
                };
            } else if (props.isHasAlbum) {
                dataCreatePhoto = {
                    price: String(presets.price?.value),
                    event: Number(presets.event?.value),
                    album: props.isHasAlbum.id
                };
            } else if (uploadVideoPresets) {
                dataCreatePhoto = {
                    event: JSON.parse(uploadVideoPresets).event.value,
                    album: JSON.parse(uploadVideoPresets).album.value,
                    price: String(presets.price?.value)
                };
            }
        }

        photoDataRef.current = dataCreatePhoto;
        setCanStartLoading(false);
        setCreatePending(true);
        setIsLoadingPhotos(true);
        uploadFilesAndCreatePhotos();

        if (props.onClickStartLoading) {
            props.onClickStartLoading();
        }
    };

    const onClick = (): void => {
        if(!firstClick) {
            setFirstClick(true);
        }
    };

    const elError = useMemo((): ReactNode => {
        if(errorInternal || errorExternal) {
            return <Error elIcon={true} className={cn('input__error')}>{errorInternal || errorExternal}</Error>;
        }
    }, [errorInternal, errorExternal]);

    const elLabel = useMemo((): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('input__label', {
                        'input__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    }, [props.children, props.required]);

    const onClickLabel = (): void => {
        if($label?.current) {
            $label?.current.click();
        }
    };

    const onClickCancel = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        clearState(true);
    };

    // Reset uploading
    const clearState = (is_cancel?: boolean): void => {
        setPending(false);
        setCreatePending(false);
        setValue([]);
        setSize(0);
        setCanStartLoading(false);

        // new
        setFileItems([]);
        setIsLoadingPhotos(false);
        setLoadedSize(0);
        setLoadedItems(0);

        if(props.onCancelLoading && is_cancel) {
            props.onCancelLoading();
        }
    };

    const onClickCloseMessage = (): void => {
        setModalPrice(false);
    };

    // Phone number not set
    const elModalMessageSetPhone = useMemo(() => {
        if (modalPrice) {
            return <ModalMessageSetPhone onClickClose={onClickCloseMessage} />;
        }
    }, [modalPrice]);

    // Upload with yandex disk
    const onClickUploadDisk = useCallback((serv: string) => (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setService(serv);
        setShowModal(true);
    }, []);

    // Upload with yandex disk link
    const onClickUploadLink = useCallback(() => (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setShowLinkModal(true);
    }, []);

    // Close modal
    const onCloseModal = useCallback(() => {
        setShowModal(false);
        setShowLinkModal(false);
    }, []);

    const uploadFile = async (item: FormData, index: number) => {
        if (fileItems[index].status !== 'success') {
            fileItems[index].setStatus('uploading');
            fileItems[index].setError(null);
            setErrorList((oldState) => {
                const newState = [...oldState];
                newState[index] = null;

                return newState;
            });
        }
        const response = await api.files.createFilesPhoto(item, {
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    const percent = ((progressEvent.loaded * 100 / progressEvent.total) * 0.75).toFixed(0);
                    fileItems[index].setProgress(parseInt(percent, 10));
                    setUploadProgress((oldState) => {
                        const newState = [...oldState];

                        newState[index] = parseInt(percent, 10);

                        return newState;
                    });
                }
            }
        })
        .catch((error) => {
            if (error && error.response) {
                switch (error.response.status) {
                    case 500:
                    case 408:
                        fileItems[index].setError(t('components.form.input-file.error-default'));
                        fileItems[index].setProgress(100);
                        setErrorList((oldState) => {
                            const newState = [...oldState];
                            newState[index] = t('components.form.input-file.error-default');

                            return newState;
                        });
                        break;
                    case 400:
                        fileItems[index].setError(error.response.data.file[0]);
                        fileItems[index].setProgress(100);
                        fileItems[index].setStatus('uploaded');
                        setSize((prev) => prev - fileItems[index].file.size);
                        setErrorList((oldState) => {
                            const newState = [...oldState];
                            newState[index] = error.response.data.file[0];

                            return newState;
                        });
                        break;
                    default:
                        fileItems[index].setError(error.response.data.file[0]);
                        fileItems[index].setProgress(100);
                        fileItems[index].setStatus('error');
                        setErrorList((oldState) => {
                            const newState = [...oldState];
                            newState[index] = error.response.data.file[0];

                            return newState;
                        });
                        break;
                }
            } else {
                fileItems[index].setError(t('components.form.input-file.error-default'));
                fileItems[index].setProgress(100);
                setErrorList((oldState) => {
                    const newState = [...oldState];
                    newState[index] = t('components.form.input-file.error-default');

                    return newState;
                });
            }
            fileItems[index].setProgress(100);
            });

        if (response) {
            const result = await response.data.id;
            fileItems[index].id = result;

            return result;
        }
    };

    const createPhotoItem = async (id: string, index: number) => {
        const data: CreatePhotoData = {
            ...photoDataRef.current as Omit<CreatePhotoData, 'file'>,
            file: id
        };

        if (fileItems[index].status !== 'success') {
            fileItems[index].setStatus('creating');
            fileItems[index].setError(null);
            setErrorList((oldState) => {
                const newState = [...oldState];
                newState[index] = null;

                return newState;
            });
        }

        const response = await api.photos.createPhoto(data)
        .catch((error) => {
            if (error && error.response) {
                switch (error.response.status) {
                    case (error.response.status === 500):
                    case (error.response.status === 408):
                        fileItems[index].setError(t('components.form.input-file.error-create'));
                        fileItems[index].setStatus('pending');
                        fileItems[index].id = null;
                        fileItems[index].setProgress(100);
                        setErrorList((oldState) => {
                            const newState = [...oldState];
                            newState[index] = t('components.form.input-file.error-create');

                            return newState;
                        });
                        break;
                    default:
                        fileItems[index].setError(error.response.data[0]);
                        fileItems[index].setProgress(100);
                        fileItems[index].setStatus('error');
                        setErrorList((oldState) => {
                            const newState = [...oldState];
                            newState[index] = error.response.data[0];

                            return newState;
                        });
                        break;
                }
            } else {
                fileItems[index].setError(t('components.form.input-file.error-create'));
                fileItems[index].setProgress(100);
                fileItems[index].setStatus('pending');
                fileItems[index].id = null;
                setErrorList((oldState) => {
                    const newState = [...oldState];
                    newState[index] = t('components.form.input-file.error-create');

                    return newState;
                });
            }
            fileItems[index].setProgress(100);
            });

        if (response) {
            const result = await response.data;

            setLoadedSize((prev) => prev + fileItems[index].file.size);
            setLoadedItems((prev) => prev + 1);
            fileItems[index].setStatus('success');
            fileItems[index].setProgress(100);

            setUploadProgress((oldState) => {
                const newState = [...oldState];

                newState[index] = 100;

                return newState;
            });
            setValue((prev) => [...prev, result]);

            return result;
        }
    };

    const processPhoto = async (currFile: {file: FormData, status: string, index: number}) => {
        if (currFile.status === 'pending' || currFile.status === 'uploading') {
            const fileId = await uploadFile(currFile.file, currFile.index);

            if (fileId !== undefined) {
                const photo = await createPhotoItem(fileId, currFile.index);

                return photo;
            }
        }

        if (currFile.status === 'creating') {
            const id = fileItems[currFile.index].id;

            if (id) {
                const photo = await createPhotoItem(id, currFile.index);

                return photo;
            }
        }
    };

    const processPhotosQueue = async (files: Array<FileItem>): Promise<Array<CreatePhotoItem | undefined>> => {
        uploadQueue.current = files.map((file, index) => {
            return {
                file: file.formData,
                status: file.status,
                index
            };
        });
        const successUploaded: Array<CreatePhotoItem | undefined> = [];
        const activeProcesses: Array<Promise<CreatePhotoItem | undefined>> = [];

        const startNextProcess = async () => {
            if (uploadQueue.current.length === 0) {
                return;
            }
            const currFile = uploadQueue.current.shift();

            if (currFile) {
                const processPromise = processPhoto(currFile);
                activeProcesses.push(processPromise);
                processPromise.then((resp) => {
                    successUploaded.push(resp);
                });

                processPromise.finally(() => {
                    activeProcesses.splice(activeProcesses.indexOf(processPromise), 1);
                    startNextProcess();
                });
            }
        };

        for (let i = 0; i < 5; i++) {
            startNextProcess();
        }

        while (activeProcesses.length > 0) {
            await Promise.all(activeProcesses);
        }

        return successUploaded;
    };

    const uploadFilesAndCreatePhotos = async () => {
        setIsFinishedLoading(false);
        const photos = await processPhotosQueue(fileItems);

        const newPhotosLength = photos.filter((item) => item !== undefined);

        setIsFinishedLoading(true);

        if (!!newPhotosLength.length && newPhotosLength.length === fileItems.filter((item) => item.status !== 'uploaded').length) {
            setPending(false);
            setCreatePending(false);
            setIsLoadingPhotos(false);

            if (props.onFilesLoaded) {
                props.onFilesLoaded(newPhotosLength);
            }
        }
    };

    const elModalUploadDisk = useMemo(() => {
        if (showModal) {
            return (
                <ModalExternal
                    service={service}
                    onCloseModal={onCloseModal}
                />
            );
        }
    }, [showModal, service]);

    const elModalUploadLink = useMemo(() => {
        if (showLinkModal) {
            return (
                <ModalExternalLink onCloseModal={onCloseModal} />
            );
        }
    }, [showLinkModal]);

    const elPlaceholder = useMemo((): ReactNode => {
        return (
            <>
                <span
                    className={cn('input__placeholder', {
                        'input__placeholder_invalid': errorInternal || errorExternal
                    })}
                >
                    {t('components.form.input-file.placeholder', {
                        context: createPending ? 'uploading' : fileItems.length ? 'images' : 'default',
                        count  : fileItems.length,
                        size   : formatSize(size)
                    })}
                    {fileItems.length ? null : (
                        <>
                            <br />
                            <br />
                            {t('route.upload.sidebar.empty.image')}
                        </>
                    )}
                    {props.isPhotoPreview && (
                        <>
                            <br />
                            {t('components.form.input-file.placeholder_limit', {
                                count: 10
                            })}
                        </>
                    )}
                </span>
            </>
        );
    }, [errorInternal, errorExternal, size, createPending, fileItems.length]);

    const elPlaceholderDisk = useMemo((): ReactNode => {
        if (props.external) {
            return (
                <span className={cn('input__placeholder')}>
                    ...или загрузите с
                    <span className={cn('input__placeholder-pointer')} onClick={onClickUploadDisk('yandex')}>Яндекс.Диска</span>
                </span>
            );
        }
    }, [props.external]);

    const elPlaceholderLink = useMemo((): ReactNode => {
        if (props.external) {
            return (
                <>
                    <span className={cn('input__placeholder')}>
                        <span className={cn('input__placeholder-new')}>New*</span> Вставьте ссылку на
                        <span className={cn('input__placeholder-pointer')} onClick={onClickUploadLink()}>Яндекс.Диск</span>
                    </span>
                </>
            );
        }
    }, [props.external]);

    const elPlaceholderImage = useMemo(() => {
        if(fileItems.length) {
            return <ImagesIcon className={cn('input__icon', 'input__icon_images')} />;
        }

        return <DownloadIcon className={cn('input__icon')} />;
    }, [fileItems.length]);

    const elPlaceholderButtons = useMemo(() => {
        if(fileItems.length) {
            return (
                <div className={cn('input__buttons')}>
                    <Button
                        type="button"
                        className={cn('input__upload-button', 'input__upload-button_two-line')}
                        onClick={onClickSubmitFiles()}
                        isSmall={true}
                        disabled={!!errorRequest}
                    >
                        {t('components.form.input-file.button-start')}
                    </Button>
                    <Button
                        type="button"
                        isSecondary={true}
                        className={cn('input__upload-button', 'input__upload-button_secondary', 'input__upload-button_two-line')}
                        onClick={onClickCancel}
                        isSmall={true}
                    >
                        {t('components.form.input-file.button-reset')}
                    </Button>
                </div>
            );
        }

        return (
            <Button
                type="button"
                className={cn('input__upload-button')}
                onClick={onClickLabel}
                isSmall={true}
            >
                {t('components.form.input-file.button')}
            </Button>
        );
    }, [fileItems.length, createPending, errorRequest]);

    const elPreviewPlaceholder = (): ReactNode => {
        const className = cn('input__preview-content', {
            'input__preview-content_default'            : canStartLoading,
            'input__preview-content_placeholder-invalid': errorInternal || errorExternal
        });

        if (isLoadingPhotos && fileItems.length) {
            return (
                <LoadingPhotoList files={fileItems} />
            );
        }

        return (
            <div className={className}>
                {elPlaceholderImage}
                {elPlaceholder}
                {elPlaceholderButtons}
                {!props.isPhotoPreview && !props.isVideoUploaded && (
                    <>
                        {elPlaceholderDisk}
                        {elPlaceholderLink}
                    </>
                )}
            </div>
        );
    };

    const elLoader = useMemo(() => {
        if(pending) {
            return <Loader className={cn('input__loader')} />;
        }
    }, [pending]);

    const onClickResume = useCallback(() => {
        if(props.onFilesLoaded) {
            props.onFilesLoaded(value);
        }
    }, [JSON.stringify(value)]);

    const elResumeButton = useMemo(() => {
        if(value.length && loadedSize < size && isFinishedLoading) {
            return (
                <Button
                    type="button"
                    className={cn('input__stop-button')}
                    onClick={onClickResume}
                >
                    {t('components.form.input-file.button-resume')}
                </Button>
            );
        }
    }, [JSON.stringify(value), loadedSize, size, isFinishedLoading]);

    const elSidebar = useMemo(() => {
        if(acceptedFiles.length && createPending) {

            const queue = acceptedFiles.length;
            const isAllErrors = acceptedFiles.length;

            return (
                <div
                    className={cn('input__sidebar', {
                        'input__sidebar-video': props.isPhotoPreview
                    })}
                >
                    <h3 className={cn('input__page-header')}>{t('route.upload.sidebar.uploading.header')}</h3>
                    <Status progress={loadedSize / size * 100} isDone={fileItems.length === loadedItems} />
                    <div className={cn('input__sidebar-items')}>
                        {queue > 0 && (
                            <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                                <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.remain')}</span>
                                <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {/* TODO: вычислять оставшееся время */}
                                    {t('route.upload.sidebar.uploading.remain-time', { count: 0 })}
                                </span>
                                </div>
                            </div>
                        )}
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.total')}</span>
                            <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {t('route.upload.sidebar.uploading.total-count', { count: fileItems.length })}
                                </span>
                                <span className={cn('input__sidebar-sub-text')}>
                                    {t('route.upload.sidebar.uploading.total-size', { size: formatSize(size) })}
                                </span>
                            </div>
                        </div>
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.uploaded')}</span>
                            <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {t('route.upload.sidebar.uploading.uploaded-remain', { loaded: loadedItems })}
                                </span>
                                <span className={cn('input__sidebar-sub-text')}>
                                    {t('route.upload.sidebar.uploading.uploaded-size', { size: formatSize(loadedSize) })}
                                </span>
                            </div>
                        </div>
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.queue')}</span>
                            <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {t('route.upload.sidebar.uploading.queue-remain', { count: fileItems.filter((item) => item.status !== 'uploaded').length - loadedItems })}
                                </span>
                                <span className={cn('input__sidebar-sub-text')}>
                                    {t('route.upload.sidebar.uploading.queue-size', { size: formatSize(size - loadedSize) })}
                                </span>
                            </div>
                        </div>
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.errors')}</span>
                            <div className={cn('input__sidebar-text')}>{Object.entries(fileItems).filter((item) => item[1].error !== null).length}</div>
                        </div>
                        <Button
                            type="button"
                            className={cn('input__stop-button', 'input__stop-button_secondary')}
                            isSecondary={true}
                            onClick={onClickCancel}
                        >
                            {t('components.form.input-file.button-pause', { context: isAllErrors ? 'errors' : 'default' })}
                        </Button>
                        {elResumeButton}
                        {loadedSize < size && isFinishedLoading && (
                            <Button
                                type="button"
                                className={cn('input__stop-button')}
                                onClick={uploadFilesAndCreatePhotos}
                            >
                                Повторить загрузку
                            </Button>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <Presets
                    registry={props.registry}
                    isSaveParams={props.isSaveParams}
                    isPhotoPreview={props.isPhotoPreview}
                    isHasAlbum={props.isHasAlbum}
                    isHasVideo={props.isHasVideo}
                    uploadingInfo={props.uploadingInfo}
                />
            </div>
        );
    }, [JSON.stringify(acceptedFiles), size, createPending, JSON.stringify(value), errorList, isFinishedLoading]);

    const elInput = () => {
        return (
            <div className={cn('input__group')}>
                <div
                    {...getRootProps({
                        className: cn('input__wrapper', {
                            'input__wrapper_active'  : isDragActive,
                            'input__wrapper_disabled': props.disabled
                        })
                    })}
                >
                    {elPreviewPlaceholder()}
                    <input
                        {...qaAttributes(props['data-qa'] ? `file:${props['data-qa']}` : 'file')}
                        {...getInputProps({
                            accept   : props.accept,
                            name     : props.name,
                            id       : props.id || props.name,
                            multiple : true,
                            type     : 'file',
                            disabled : props.disabled || pending || value.length >= 20 || !!value.length || createPending || canStartLoading,
                            required : props.required,
                            value    : '',
                            title    : '',
                            tabIndex : props.tabIndex,
                            className: cn('input__field'),
                            onClick
                        })}
                    />
                </div>
            </div>
        );
    };

    return (
        <div
            className={cn('input', {
            'input__video': props.isPhotoPreview && !props.isStartLoadingVideoPhotos
            })}
        >
            <div className={cn('input__content')}>
                <div className={cn('input__field-wrapper')}>
                    {elModalMessageSetPhone}
                    {!props.isPhotoPreview && !props.isVideoUploaded && (
                        <>
                            {elModalUploadDisk}
                            {elModalUploadLink}
                        </>
                    )}
                    {elLoader}
                    {elLabel}
                    {elInput()}
                    {elError}
                </div>
            </div>
            {elSidebar}
        </div>
    );
};

InputFile.defaultProps = {
    direction: 'row',
    accept   : '.jpg,.jpeg,.png,.cr2,.raw',
    maxSize  : 50,
    maxCount : 100
};

// tslint:disable-next-line max-file-line-count
export default InputFile;
