import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { SimilarPersonsAPIType, SimilarPersonsRequest } from './types';

const createSimilarPersonsRequest = (
    similar_persons: Array<number>
): Promise<AxiosResponse<SimilarPersonsRequest>> => {
    return httpClient.post<SimilarPersonsRequest>(
        '/api/v2/similar-persons/moderation-requests/',
        {
            similar_persons
        }
    );
};

export default {
    createSimilarPersonsRequest
} as SimilarPersonsAPIType;
