import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useClassnames from 'hook/use-classnames';

import Button from 'component/button';
import Error from 'component/error';
import IconSuccess from 'component/icon/apply';
import Form, { createRegistry } from 'component/form';
import { IPayload, IError } from 'component/form/types';
import FormInput from 'component/form/input';

import generatePassword from 'component/helper/generate-string';

import { IProps } from './types';
import style from './index.pcss';

const NEEDED_PASSWORD_LENGTH = 6;

const PasswordSet: FC<IProps> = (props) => {
    const cn = useClassnames(style);
    const registry = useMemo(createRegistry, []);
    const { t, i18n } = useTranslation();
    const [password, setPassword] = useState<string | null>(null);
    const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
    const [generatedPassword, setGeneratedPassword] = useState<string | null>(null);
    const [validity, setValidity] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError> | null>(props.errors || null);
    const [error, setError] = useState<string | null>(props.error || null);

    useEffect(() => {
        setError(error);
    }, [error]);

    useEffect(() => {
        setErrors(errors);
    }, [JSON.stringify(errors)]);

    const onSubmit = useCallback((formData: IPayload) => {
        props.onSubmit && props.onSubmit(formData.password);
    }, []);

    const onChangeForm = useCallback(() => {
        setError(null);
        setErrors(null);

        const fields = registry.form.getFields();

        if(fields) {
            const pass = fields.get('password');
            const confirmPass = fields.get('password_confirm');

            setPassword(pass?.value || null);
            setConfirmPassword(confirmPass?.value || null);
            setGeneratedPassword(null);
        }
    }, [JSON.stringify(registry.form.getFields())]);

    const onGeneratePassword = useCallback(() => {
        setGeneratedPassword(generatePassword(15));
    }, []);

    const elError = useMemo(() => {
        if(error) {
            return (
                <Error className={cn('set-password__error')}>
                    {error}
                </Error>
            );
        }
    }, [error, i18n.language]);

    const elRuleIcon = useCallback((isRuleValid: boolean) => {
        if(isRuleValid) {
            return <IconSuccess className={cn('set-password__rule-icon-valid')} />;
        }

        return <span className={cn('set-password__rule-icon')} />;
    }, []);

    return (
        <div className={cn('set-password__content')}>
            <h2 className={cn('set-password__header')}>
                {t('components.password-set.button.send')}
            </h2>
            <p className={cn('set-password__hint')}>
                <Trans
                    i18n={i18n}
                    i18nKey="components.password-set.hint"
                >
                    or&nbsp;
                    <span onClick={onGeneratePassword} className={cn('set-password__generate')}>generate password automatically</span>
                </Trans>
            </p>
            <Form
                registry={registry.form}
                className={cn('set-password__form')}
                onSubmit={onSubmit}
                onChangeValidity={setValidity}
                errors={errors}
                onChange={onChangeForm}
            >
                <FormInput
                    registry={registry.field}
                    name="password"
                    type="password"
                    pattern={/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g}
                    patternError={t('components.password-set.input.password.pattern-error')}
                    defaultValue={generatedPassword}
                    className={cn('set-password__input')}
                    required={true}
                    minLength={NEEDED_PASSWORD_LENGTH}
                    direction="column"
                    children={t('components.password-set.input.password.label')}
                />
                <FormInput
                    registry={registry.field}
                    name="password_confirm"
                    type="password"
                    pattern={/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g}
                    patternError={t('components.password-set.input.password.pattern-error')}
                    defaultValue={generatedPassword}
                    className={cn('set-password__input')}
                    required={true}
                    minLength={NEEDED_PASSWORD_LENGTH}
                    direction="column"
                    children={t('components.password-set.input.password_confirm.label')}
                />
                <div className={cn('set-password__rules')}>
                    <div className={cn('set-password__rule')}>
                        {elRuleIcon(!!(password && password.length >= NEEDED_PASSWORD_LENGTH))}
                        {t('components.password-set.rules.length')}
                    </div>
                    <div className={cn('set-password__rule')}>
                        {elRuleIcon(!!(password && /\d/.test(password)))}
                        {t('components.password-set.rules.numbers')}
                    </div>
                    <div className={cn('set-password__rule')}>
                        {elRuleIcon(!!(password && /[A-Z]/.test(password)))}
                        {t('components.password-set.rules.capital-letters')}
                    </div>
                    <div className={cn('set-password__rule')}>
                        {elRuleIcon(!!(confirmPassword && (confirmPassword === password)))}
                        {t('components.password-set.rules.same')}
                    </div>
                </div>
                {elError}
                <div className={cn('set-password__button-wrapper')}>
                    <Button
                        type="submit"
                        className={cn('set-password__button-submit')}
                        isLoading={props.pending}
                        disabled={!validity || props.pending}
                    >
                        {t('components.password-set.button.send')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default PasswordSet;
