import React, { useEffect, useMemo, useState, useCallback, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axios from 'axios';
import { parse, stringify } from 'query-string';
import debounce from 'lodash.debounce';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Subscribe from 'component/subscribe';
import SearchResult from 'component/search-result';
import Person from 'component/icon/person';
import history from 'component/core/history';
import Loader from 'component/loader';
import Input from 'component/form/input';
import InputEvents from 'component/form/input-events';
import InputLocation from 'component/form/input-location';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { normalizeObject } from 'component/helper/normalize-object';
import style from './index.pcss';
import useIntersect from 'hook/use-intersect';
import api from 'src/api';
import { Event, EventsSearchFilter } from 'src/api/events/types';
import { Page } from 'src/api/base';
import { PhotosSearchFilter } from 'src/api/photos/types';
import { LatestPhotoItem } from 'src/api/latest-photos/types';
import { PhotographerRetrieve } from 'src/api/photographers/types';

const EVENTS_LIMIT = 10;

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const Photographer = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { form, field } = useRegistry();

    const { id }: { id: string } = useParams();

    const [validity, setValidity] = useState<boolean>(false);
    const [listPending, setListPending] = useState<boolean>(false);
    const [photographer, setPhotographer] = useState<PhotographerRetrieve | null>(null);
    const [isPhotographerLoading, setIsPhotographerLoading] = useState<boolean>(true);
    const [list, setList] = useState<Array<Event>>([]);
    const [eventCount, setEventCount] = useState<number>(0);
    const [subscription, setSubscription] = useState<number | null>(null);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    // Last photos
    const [totalPhotos, setTotalPhotos] = useState<number>(0);
    const [lastPhotos, setLastPhotos] = useState<Array<LatestPhotoItem>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    const [isEventListLoading, setIsEventListLoading] = useState<boolean>(false);
    const [isEventListLoadMore, setIsEventListLoadMore] = useState<boolean>(false);

    useEffect(() => {
        if (!isPhotographerLoading && !photographer) {
            history.push('/404');
        }
    }, [isPhotographerLoading]);

    useEffect((): void => {
        if (!isPhotographerLoading && photographer) {
            const page: Page = {
                pageNumber: 1,
                pageSize: 5
            };

            const filter: PhotosSearchFilter = {
                photographer_id: Number(id)
            };

            api.latestPhotos.getLatestPhotosList(page, filter)
                .then((resp) => {
                    setLastPhotos(resp.data.results);
                    setTotalPhotos(resp.data.count);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [isPhotographerLoading]);

    useEffect(() => {
        if (isEventListLoading || isEventListLoadMore) {
            setListPending(true);
            const page: Page = {
                pageNumber: currentPage,
                pageSize: EVENTS_LIMIT
            };

            const filter: EventsSearchFilter = {
                is_empty: false,
                photographer_id: Number(id),
                ...(queryParams.person_name && { search: queryParams.person_name }),
                ...(queryParams.event_id && { event_id: queryParams.event_id }),
                ...(queryParams.location_id && { location_id: queryParams.location_id })
            };

            if (isNextPage) {
                api.events.getEventsList(page, filter)
                    .then((resp) => {
                        setList(
                            isEventListLoadMore
                                ? [...list, ...resp.data.results]
                                : resp.data.results
                        );
                        setEventCount(resp.data.count);
                        setListPending(false);
                        setIsEventListLoading(false);
                        setIsEventListLoadMore(false);
                        if (resp.data.next !== null) {
                            setCurrentPage((prev) => prev + 1);
                        } else {
                            setIsNextPage(false);
                        }
                    })
                    .catch((err) => {
                        if(!axios.isCancel(err)) {
                            setListPending(false);
                            setIsEventListLoading(false);
                            setIsEventListLoadMore(false);
                        }
                    });
            }
        }
    }, [isEventListLoading, isEventListLoadMore]);

    const _request = () => {
        api.photographers.getPhotographerItem(Number(id))
            .then((resp) => {
                setPhotographer(resp.data);
                if (resp.data.subscription) {
                    setSubscription(resp.data.subscription.id || null);
                }
                setIsPhotographerLoading(false);

                setIsEventListLoading(true);
            })
            .catch(() => {
                setIsPhotographerLoading(false);
            });
    };

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        _request();
    }, [id]);

    useEffect(() => {
        if(form.checkValidity() && !isPhotographerLoading && photographer) {
            setCurrentPage(1);
            setIsNextPage(true);
            setIsEventListLoading(true);
        }
    }, [JSON.stringify(queryParams), isPhotographerLoading]);

    const onChangeForm = debounce(useCallback(() => {
        const payload = form.getPayload();
        const data = {
            ...(payload.name && queryParams.person_name && { person_name: queryParams.person_name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.event?.value && { event_id: payload.event.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [validity, JSON.stringify(form.getPayload()), JSON.stringify(photographer), queryParams]), 300);

    const onReset = useCallback(() => {
        form.clearForm();
        window.scrollTo(0, 0);

        setCurrentPage(1);
        setIsNextPage(true);
        setIsEventListLoading(true);
    }, []);

    const onSubmitForm = debounce(useCallback(() => {
        const payload = form.getPayload();
        const data = {
            ...(payload.name && { person_name: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.event?.value && { event_id: payload.event.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [validity, JSON.stringify(form.getPayload()), JSON.stringify(photographer)]), 300);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        // @ts-ignore
        if (event.key === 'Enter') {
            onSubmitForm();
        }
    };

    const  elSubscription = useMemo(() => {
        if(!isPhotographerLoading && photographer?.id) {
            return <Subscribe type="user" subscription={subscription} photographerId={photographer?.id} persons={[photographer?.id]} className={cn('photographer__subscription')} />;
        }
    }, [JSON.stringify(photographer), isPhotographerLoading, JSON.stringify(subscription)]);

    const elImageBlock = useMemo(() => {
        if(isPhotographerLoading) {
            return <Loader />;
        }

        if(photographer?.avatar) {
            return (
                <div className={cn('photographer__image-block')}>
                    <img src={photographer.avatar} className={cn('photographer__image')} />
                </div>
            );
        }

        return (
            <div className={cn('photographer__image-empty')}>
                <Person className={cn('photographer__empty-icon')} />
            </div>
        );
    }, [JSON.stringify(photographer), isPhotographerLoading]);

    const $LoadMoreEvent = useIntersect((entry) => {
        if(entry.isIntersecting && isNextPage) {
            setIsEventListLoadMore(true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elLoadMoreEvent = () => {
        if (!isEventListLoading && !isEventListLoadMore && list.length) {
            if (eventCount > list.length) {
                return <Loader ref={$LoadMoreEvent} />;
            }
        }
    };

    const elList = useMemo(() => {
        if(listPending || isPhotographerLoading) {
            return (
                <UI.Box padding={true} className={cn('photographer__empty')}>
                    <Loader />
                </UI.Box>
            );
        }

        if(list?.length && !listPending) {
            return (
                <div className={cn('photographer__results')}>
                    {list.map((item, index) => (
                        <SearchResult
                            photo_attachments={item.photos}
                            key={index}
                            title={item.name}
                            place={item.location_name}
                            date={item.date}
                            link={`/events/${item.id}`}
                            query={`photographer_id=${id}`}
                            eventId={item.id}
                            photographerId={Number(id)}
                        />
                    ))}
                    {elLoadMoreEvent()}
                </div>
            );
        }

        return (
            <UI.Box padding={true} className={cn('photographer__empty')}>
                {t('route.photographer.results.empty')}
            </UI.Box>
        );
    }, [JSON.stringify(list), listPending, isNextPage, isEventListLoadMore, isEventListLoading]);

    const elPersonName = () => {
        if (!isPhotographerLoading) {
            const text = photographer?.first_name && photographer.last_name ? `${photographer?.first_name} ${photographer?.last_name}`.trim() : t('route.photographer.info.no-name');

            return <h4 className={cn('photographer__name')}>{text}</h4>;
        }
    };

    const elLocation = () => {
        let defaultLocation = null;

        if(queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        return (
            <InputLocation
                registry={field}
                clearable={true}
                defaultValue={defaultLocation}
                name="location"
                children="Город"
                direction="column"
                className={cn('photographer__input-block')}
            />
        );
    };

    const elLastPhoto = useMemo(() => {
        if (lastPhotos.length) {
            return (
                <SearchResult
                    title={'Фото за последние 7 дней'}
                    link={'/persons/photos'}
                    photo_attachments={lastPhotos.map((item) => item.photo_url)}
                    photos_count={totalPhotos}
                    query={`photographer_id=${id}`}
                />
            );
        }
    }, [lastPhotos, totalPhotos]);

    return (
        <UI.Main className={cn('photographer')}>
            <div className={cn('photographer__grid')}>
                <div className={cn('photographer__sidebar')}>
                    <UI.Box padding={true} className={cn('photographer__box')}>
                        {elImageBlock}
                        <div className={cn('photographer__data')}>
                            <div className={cn('photographer__name-age')}>
                                {elPersonName()}
                                <span className={cn('photographer__age')}>{t('route.photographer.info.role')}</span>
                            </div>
                            {elSubscription}
                        </div>
                    </UI.Box>
                    <Form
                        registry={form}
                        onChangeValidity={setValidity}
                        onChange={onChangeForm}
                    >
                        <UI.Box padding={true} className={cn('photographer__box')}>
                            <UI.BoxHeader>{t('route.photographer.sidebar.header')}</UI.BoxHeader>
                            <Input
                                registry={field}
                                name="name"
                                defaultValue={queryParams.person_name}
                                type="text"
                                direction="column"
                                children={t('global.form.items.person')}
                                className={cn('photographer__input-block')}
                                isSubmitButton={true}
                                onKeyDown={handleKeyDown}
                                onClickSubmitButton={onSubmitForm}
                            />
                            <InputEvents
                                registry={field}
                                name="event"
                                default_id={queryParams.event_id}
                                clearable={true}
                                children={t('global.form.items.event')}
                                direction="column"
                                className={cn('photographer__input-block')}
                                photographer_id={Number(id)}
                            />
                            {elLocation()}
                            <Button
                                onClick={onReset}
                                type="reset"
                                className={cn('photographer__button-submit')}
                                disabled={isPhotographerLoading}
                            >
                                {t('route.photographer.button-reset')}
                            </Button>
                        </UI.Box>
                    </Form>
                </div>
                <div className={cn('photographer__content')}>
                    {elLastPhoto}
                    {elList}
                </div>
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Photographer;
