import Content from './content';
import Sidebar from './sidebar';
import Main from './main';
import Box from './box';
import BoxHeader from './box-header';
import PageHeader from './page-header';
import Input from './input/input';

export default {
    Content,
    Sidebar,
    Main,
    Box,
    BoxHeader,
    PageHeader,
    Input
};
