import React, { useMemo } from 'react';
import UI from 'component/ui';
import moment from 'moment';
import { PhotoItem } from 'src/api/photos/types';
import { useClassnames } from 'hook/use-classnames';
import style from './index.pcss';
import { Link } from 'react-router-dom';
import { bytesToMegaBytes } from 'src/utils/bytes-to-megabytes';
import { getColor } from 'src/utils/get-color';

interface IProps {
    currentPhoto: PhotoItem;
}

const PhotoInfoBlock = ({currentPhoto}: IProps) => {
    const cn = useClassnames(style);

    const hasTime = currentPhoto.original_file_date &&
        moment(currentPhoto.original_file_date, moment.ISO_8601, true).isValid() &&
        moment(currentPhoto.original_file_date).format('HH:mm:ss') !== '00:00:00';

    const formattedDate = currentPhoto.original_file_date
        ? hasTime ? moment(currentPhoto.original_file_date).format('HH:mm:ss')
        : moment(currentPhoto.original_file_date).format('DD.MM.YYYY')
        : '';

    const dateLink = hasTime ? `/events/${currentPhoto.event?.id}?time_from=${hasTime
        ? moment(formattedDate, 'HH:mm:ss').subtract(1, 'minute').format('HH:mm:ss') :
        ''}&time_to=${hasTime
            ? moment(formattedDate, 'HH:mm:ss').add(1, 'minute').format('HH:mm:ss')
            : ''}`
    : `/events/${currentPhoto.event?.id}`; // Added optional chaining here

    const elPhotoTime = useMemo(() => {
        const date = currentPhoto?.created_at;
        const eventId = currentPhoto?.event?.id || 0; // Added optional chaining here

        if (date) {
            const time = moment(date).format('HH:mm:ss');
            const [h, m, s] = time.split(':');
            const d = new Date();

            d.setHours(Number(h), Number(m) - 1, Number(s), 0);

            const timeFrom = moment(d).format('HH:mm:ss');

            d.setHours(Number(h), Number(m) + 1, Number(s), 0);

            const timeTo = moment(d).format('HH:mm:ss');

            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Время</span>
                    <Link
                        to={`/events/${eventId}?time_from=${timeFrom}&time_to=${timeTo}`}
                        className={cn('person-photo__info-item-link')}
                    >
                        {time}
                    </Link>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoColors = useMemo(() => {
        const colors = currentPhoto?.colors;
        const eventId = currentPhoto?.event?.id || 0; // Added optional chaining here

        if (Array.isArray(colors) && colors.length) {
            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Цвета</span>
                    <div className={cn('person-photo__colors')}>
                        {
                            colors.map((item) => {
                                const color = getColor(item.id);
                                const link = `/events/${eventId}?color_ids=${item.id}`;

                                return (
                                    <a href={link} target="_blank" key={item.id}>
                                        <div
                                            className={
                                                cn('person-photo__colors-item', `person-photo__colors-item-${color}`)
                                            }
                                        />
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoNumbers = useMemo(() => {
        const numbers = currentPhoto?.numbers;
        const eventId = currentPhoto?.event?.id || 0; // Added optional chaining here

        if (Array.isArray(numbers) && numbers.length) {
            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Номера</span>
                    <div className={cn('person-photo__numbers')}>
                        {
                            numbers.map((item) => {
                                const link = `/events/${eventId}?number=${item}`;

                                return (
                                    <a href={link} target="_blank" key={item}>
                                        <div
                                            className={cn(
                                                'person-photo__numbers-item',
                                                'person-photo__info-item-link'
                                            )}
                                        >
                                            {item}
                                        </div>
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    return (
        <UI.Box padding={true} className={cn('person-photo__info')}>
            <UI.BoxHeader>О снимке</UI.BoxHeader>
            {(currentPhoto.original_file_width && currentPhoto.original_file_height) ? (
                <div className={cn('person-photo__info-item')}>
                    <span>Разрешение</span>
                    <span>{`${currentPhoto.original_file_width}x${currentPhoto.original_file_height}`}</span>
                </div>
            ) : ''}
            {currentPhoto?.original_file_size ? (
                <div className={cn('person-photo__info-item')}>
                    <span>Размер</span>
                    <span>{`${bytesToMegaBytes(currentPhoto?.original_file_size)} Мб`}</span>
                </div>
            ) : ''}
            {formattedDate.length ? (
                <div className={cn('person-photo__info-item')}>
                    <span>{hasTime ? 'Время' : 'Дата'}</span>
                    <Link
                        to={dateLink}
                        className={cn('person-photo__info-item-link')}
                    >
                        {formattedDate}
                    </Link>
                </div>
            ) : ''}
            {currentPhoto?.photographer?.id ? (
                <div className={cn('person-photo__info-item')}>
                    <span>Фотограф</span>
                    <Link
                        to={`/photographer/${currentPhoto.photographer.id}`}
                        className={cn('person-photo__info-item-link')}
                    >
                        {`${currentPhoto.photographer.first_name} ${currentPhoto.photographer.last_name}`}
                    </Link>
                </div>
            ) : ''}
            {elPhotoTime}
            {elPhotoColors}
            {elPhotoNumbers}
        </UI.Box>
    );
};

export default PhotoInfoBlock;
