import { AxiosResponse } from 'axios';
import { CommonStatistic, EventStatistic, PersonStatistic, PhotosStatistic, StatisticAPIType } from './types';
import httpClient from 'src/utils/http-сlient';

const getCommonStatistics = (): Promise<AxiosResponse<CommonStatistic>> => {
    return httpClient.get<CommonStatistic>('/api/v2/statistics/common/');
};

const getEventStatistics = (
    id: number
): Promise<AxiosResponse<EventStatistic>> => {
    return httpClient.get<EventStatistic>(`/api/v2/statistics/event/${id}/`, {});
};

const getPersonStatistics = (
    id: number
): Promise<AxiosResponse<PersonStatistic>> => {
    return httpClient.get<PersonStatistic>(`/api/v2/statistics/persons/${id}/`);
};

const getPhotosStatistics = (): Promise<AxiosResponse<PhotosStatistic>> => {
    return httpClient.get<PhotosStatistic>('/api/v2/statistics/photos/');
};

export default {
    getCommonStatistics,
    getEventStatistics,
    getPersonStatistics,
    getPhotosStatistics
} as StatisticAPIType;
