import React, { useEffect, useState } from 'react';
import UI from 'component/ui';
import { useClassnames } from 'hook/use-classnames';

import style from '../index.pcss';
import InputSelect from 'component/form/input-select';
import { useRegistry } from 'component/form';
import { useTranslation } from 'react-i18next';
import api from 'src/api';
import { GetAlbumFilterValuesData } from 'src/api/albums/types';
import { ValueType } from 'react-select';

interface IProps {
    event_id: number;
    onSubmit?(value: ValueType<SelectOption> | null): void;
    default_value?: string;
}

export type SelectOption = {
    value: string;
    label: string;
};

const PhotographerFilter = ({ event_id, onSubmit, default_value }: IProps) => {
    const cn = useClassnames(style);
    const registry = useRegistry();
    const { t } = useTranslation();

    const [filterOptions, setFilterOptions] = useState<Array<SelectOption>>([]);

    useEffect(() => {
        const data: GetAlbumFilterValuesData = {
            event: event_id,
            filter_name: 'PHOTOGRAPHER'
        };

        api.albums.getAlbumFilterValues(data)
            .then((resp) => {
                const formattedOptions = resp.data.map((item) => ({
                    value: String(item.id),
                    label: String(item.name)
                }));
                setFilterOptions(formattedOptions);

                if (!formattedOptions.length && onSubmit) {
                    onSubmit(null);
                }
            });
    }, []);

    const onChangeSelectOption = (value: ValueType<SelectOption> | null) => {
        if (onSubmit) {
            onSubmit(value);
        }
    };

    useEffect(() => {
        if (filterOptions.length === 1 && onSubmit) {
            onSubmit(filterOptions[0]);
        }
    }, [filterOptions]);

    const elAlbumYearInput = () => {
        return (
            <InputSelect
                registry={registry.field}
                name="photographer_album"
                options={filterOptions}
                clearable={true}
                children={t('components.sidebar-action.forms.items.photographer-album')}
                className={cn('filter-form__input-block')}
                direction="column"
                searchable={true}
                defaultValue={filterOptions.length === 1 ? filterOptions[0] : filterOptions.find((item) => item.value === default_value)}
                disabled={!filterOptions.length || filterOptions.length === 1}
                onChange={onChangeSelectOption}
            />
        );
    };

    return (
        <UI.Box padding={true} className={cn('event__box')}>
            {elAlbumYearInput()}
        </UI.Box>
    );
};

export default PhotographerFilter;
