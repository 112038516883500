import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import { GetSalesListData, SalesAPIType, SalesListItem, SalesRevenue } from './types';

const getSalesList = (
    page: Page,
    data?: GetSalesListData
): Promise<AxiosResponse<PaginationResponse<SalesListItem>>> => {
    return httpClient.get<PaginationResponse<SalesListItem>>('/api/v2/sales/', {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...data
        }
    });
};

const getSalesRevenue = (): Promise<AxiosResponse<SalesRevenue>> => {
    return httpClient.get<SalesRevenue>('/api/v2/sales/revenue/');
};

export default {
    getSalesList,
    getSalesRevenue
} as SalesAPIType;
