import React, { FC, Fragment, MouseEvent, useCallback, useMemo, useRef, useState } from 'react';

import UI from 'component/ui';
import Input from 'component/form/input';
import Button from 'component/button';
import Form from 'component/form';
import { useClassnames } from 'hook/use-classnames';

import { IProps } from './types';
import style from 'route/search/index.pcss';
import { useTranslation } from 'react-i18next';
import InputLocation from 'component/form/input-location';
import InputYears from 'component/form/input-years';
import InputEvents from 'component/form/input-events/index-tsx-async';
import InputPhoto from 'component/form/input-photo';
import IconCross from 'component/icon/cross';
import ArrowDown from 'component/icon/arrow-dir-down';
import debounce from 'lodash.debounce';
import { CreateTempFile } from 'src/api/files/types';

const SearchForm: FC<IProps> = (props) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    const $canvas = useRef<HTMLCanvasElement>(null);
    const $image = useRef<HTMLImageElement>(null);

    const [isShowFilter, setIsShowFilter] = useState(true);
    const [image, setImage] = useState<CreateTempFile | null>(null);

    const onChangeWindowSize = debounce(useCallback(() => {
        if (window.innerWidth >= 1023) {
            setIsShowFilter(true);
        }
    }, [window.innerWidth]), 1000);

    window.addEventListener('resize', onChangeWindowSize);

    const onClickFilterForm = useCallback(() => {
        if (!isShowFilter) {
            setIsShowFilter(true);

            return;
        }

        if (window.innerWidth <= 1023) {
            setIsShowFilter(false);
        }
    }, [isShowFilter]);

    const elLocation = useMemo(() => {
        return (
            <InputLocation
                registry={props.registry.field}
                clearable={true}
                defaultValue={props.defaultLocation}
                name="location"
                children={t('global.form.items.city')}
                direction="column"
                className={cn('search__form-input')}
            />
        );
    }, []);

    const elEvent = useMemo(() => {
        let year;
        let locationID;

        if (props.defaultYear) {
            year = Number(props.defaultYear.value);
        }

        if (props.defaultLocation) {
            locationID = Number(props.defaultLocation.value);
        }

        return (
            <Fragment>
                <InputYears
                    registry={props.registry.field}
                    name="year"
                    clearable={true}
                    defaultValue={props.defaultYear}
                    children={t('components.sidebar-action.forms.items.year')}
                    className={cn('search__form-input')}
                    direction="column"
                />
                <InputEvents
                    registry={props.registry.field}
                    name="event"
                    year={year}
                    location={locationID}
                    default_id={props.defaultEventID}
                    clearable={true}
                    children={t('global.form.items.event')}
                    direction="column"
                    className={cn('search__form-input')}
                />
            </Fragment>
        );
    }, [props.defaultYear]);

    const onReset = () => {
        window.scrollTo(0, 0);
        props.registry.form.clearForm();

        if (props.onReset) {
            props.onReset();
        }
    };

    const elImageResult = () => {
        if(image) {

            return (
                <div className={cn('search__image-box')}>
                    <img ref={$image} className={cn('search__photo-image')} src={image.url} />
                </div>
            );
        }
    };

    const onFileLoaded = (file: CreateTempFile): void => {
        setImage(file);

        if (!file?.id) {
            return;
        }

        if (window.innerWidth <= 1023) {
            setIsShowFilter(false);
        }

        if (props.onFileLoaded) {
            props.onFileLoaded(file);
        }
    };

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        onReset();
        setImage(null);
    }, []);

    const elUploadContent = useMemo(() => {
        return (
            <Fragment>
                {elImageResult()}
                <InputPhoto
                    defaultValue={image || undefined}
                    onFileLoaded={onFileLoaded}
                    registry={props.registry.field}
                    name="photo"
                    skipRecognition={true}
                    hideBtn={true}
                />
                {image?.url && (
                    <div className={cn('search__photo-menu')}>
                        <div onClick={onClickRemove} className={cn('search__photo-menu-item')}>
                            <IconCross className={cn('search__photo-menu-icon')} />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }, [JSON.stringify(image), $canvas.current]);

    const handleKeyDown = (event: unknown) => {
        // @ts-ignore
        if (event.key === 'Enter' && props.onSubmit) {
            props.onSubmit();
        }
    };

    const onClickSubmitButton = () => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const elFilterForm = useMemo(() => {
        return (
            <Form
                registry={props.registry.form}
                onChange={props.onChange}
                onSubmit={props.onSubmit}
                onChangeValidity={props.onChangeValidity}
                className={cn('search__form')}
            >
                <UI.Box padding={true} className={cn('search__box')}>
                    <div onClick={onClickFilterForm} className={cn('search__box__header')}>
                        <UI.BoxHeader>{t('route.search.sidebar.filter-header')}</UI.BoxHeader>
                        <ArrowDown className={cn('search__box__header__arrow', {'search__box__header__arrow_active': isShowFilter})} />
                    </div>
                    {isShowFilter && (
                        <>
                            <Input
                                registry={props.registry.field}
                                name="name"
                                defaultValue={props.defaultPersonName}
                                type="text"
                                direction="column"
                                children={t('global.form.items.person')}
                                className={cn('search__form-input')}
                                onKeyDown={handleKeyDown}
                                onClickSubmitButton={onClickSubmitButton}
                                isSubmitButton={true}
                            />
                            {elLocation}
                            {elEvent}
                            <div className={cn('search__form-btn')}>
                                <Button
                                    onClick={onReset}
                                    type="reset"
                                    // disabled={pending}
                                >
                                    Сбросить
                                </Button>
                            </div>
                        </>
                    )}
                </UI.Box>
            </Form>
        );
    }, [isShowFilter, props.defaultYear]);

    return (
        <Fragment>
            <Form registry={props.registry.form} onChange={props.onChange} className={cn('search__form')}>
                <UI.Box padding={true} className={cn('search__box')}>
                    <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
                    {elUploadContent}
                </UI.Box>
            </Form>
            {elFilterForm}
        </Fragment>
    );
};

export default SearchForm;
