import React, { useState, FC, ReactNode, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash.debounce';
import ReactSelectAsync from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select/src/types';
import axios from 'axios';

import useClassnames from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';
import Error from 'component/error';
import { TError } from 'component/form/input/types';

import { IProps, IValue } from './types';
import styles from './style';
import style from './index.pcss';
import api from 'src/api';
import { GetEventsYearsListData } from 'src/api/events/types';
import { Page } from 'src/api/base';

const InputSelect: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const token = useCancelToken();
    const { t } = useTranslation();

    const [isFocus, setIsFocus] = useState(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);
    const [value, setValue] = useState<ValueType<IValue>>(props.defaultValue || []);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [suggests, setSuggests] = useState<Array<IValue>>([]);
    const [suggestsOriginal, setSuggestsOriginal] = useState<Array<IValue>>([]);

    const request = useCallback(() => {
        setPending(true);

        const page: Page = {
            pageNumber: 1
        };

        const data: GetEventsYearsListData = {};

        api.events.getEventsYearsList(page, data)
            .then((payload) => {
                const options = payload.data.results.map((year): IValue => ({
                    label  : String(year.value),
                    value  : String(year.value),
                    payload: year
                }));

                setSuggestsOriginal(options);
                setSuggests(options);
                setPending(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setPending(false);
                }
            });
    }, []);

    const onLoadOptions = debounce((q: string, callback) => {
        const page: Page = {
            pageNumber: 1
        };

        const data: GetEventsYearsListData = {
            search: q
        };

        api.events.getEventsYearsList(page, data)
            .then((payload) => {
                const result = payload.data.results.map((year): IValue => ({
                    label  : String(year.value),
                    value  : String(year.value),
                    payload: year
                }));

                callback(result);
            })
            .catch(() => {
                callback(null);
            });
    }, 150);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = !!value;

            if(!newIsValid) {
                newErrorInternal = t('components.form.input-select.error');
            }
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect((): void => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            value,
            clear   : () => {
                setValue(null);
                setIsWatch(false);
            },
            isAutoFill: false,
            isValid: checkValidity()
        });
    }, [value]);

    useEffect(() => {
        request();

        return () => {
            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        if(props.defaultValue) {
            setValue(props.defaultValue);
        }
    }, [JSON.stringify(props.defaultValue)]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [value, isValid]);

    const onChange = (inputValue: ValueType<IValue> | null): void => {
        setValue(inputValue);

        if(errorExternal) {
            setErrorExternal(null);
        }

        if(props.onChange) {
            props.onChange(inputValue);
        }
    };

    const onInputChange = (inputValue: string): void => {
        let newSuggests = suggestsOriginal;

        if(inputValue) {
            newSuggests = suggestsOriginal.filter((suggest: IValue) => {
                return suggest.label && suggest.label.toLowerCase().includes(inputValue.toLowerCase());
            });
        }

        setSuggests(newSuggests);
    };

    const onFocus = (): void => {
        if(!isFocus) {
            setIsFocus(true);
        }
    };

    const onBlur = (): void => {
        if(isFocus) {
            setIsFocus(false);
        }

        if(!isWatch) {
            setIsWatch(true);
        }
    };

    const elLabel = useMemo((): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('input__label', {
                        'input__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    }, [props.children, props.required]);

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('input__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    const elInput = (): ReactNode => {
        const selectStyles = props.styles ? { ...styles(!!props.icon), ...props.styles } : styles(!!props.icon);

        return (
            <ReactSelectAsync
                className={cn('input__field', {
                    'input__field_invalid': isWatch && !isValid
                })}
                autoFocus={props.autoFocus}
                placeholder={props.placeholder || t('components.form.input-select.placeholder')}
                isMulti={props.isMulti}
                isSearchable={props.searchable}
                isClearable={true}
                tabIndex={props.tabIndex}
                onInputChange={onInputChange}
                isDisabled={props.disabled}
                loadOptions={onLoadOptions}
                defaultOptions={suggestsOriginal}
                value={value}
                noOptionsMessage={(inputValue) => inputValue.inputValue.length ? 'Нет результатов' : 'Начните вводить название...'}
                defaultValue={value}
                onFocus={onFocus}
                onBlur={onBlur}
                options={suggests}
                onChange={onChange}
                styles={selectStyles}
            />
        );
    };

    return (
        <label className={cn('input')}>
            <div className={cn('input__wrapper', `input__wrapper_${props.direction}`)}>
                {elLabel}
                {elInput()}
            </div>
            {elError()}
        </label>
    );
};

InputSelect.defaultProps = {
    direction: 'row'
};

export default InputSelect;
