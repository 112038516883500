import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'component/modal';
import Input from 'component/form/input';
import Form from 'component/form';
import { IProps } from 'component/modal/sale/types';
import Button from 'component/button';
import { useClassnames } from 'hook/use-classnames';
import style from 'component/modal/sale/style.pcss';
import { useCancelTokens } from 'component/core/cancel-token';
import { IPayload } from 'component/form/types';
import ReactTooltip from 'react-tooltip';
import api from 'src/api';
import { DiscountItem } from 'src/api/discounts/types';

const PhotoSaleModal: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const [tokenCreate, tokenUpdate, tokenGet, tokenDelete] = useCancelTokens(4);

    const [createMode, setCreateMode] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [deleteMode, setDeleteMode] = useState<boolean>(false);

    const [threeOrMore, setThreeOrMore] = useState<number>(0);
    const [sixOrMore, setSixOrMore] = useState<number>(0);
    const [moreThanTen, setMoreThanTen] = useState<number>(0);

    useEffect(() => {
        _getPhotoSale();

        return () => {
            tokenCreate.remove();
            tokenUpdate.remove();
            tokenGet.remove();
            tokenDelete.remove();
        };
    }, []);

    const prepareData = (data: IPayload): Array<DiscountItem> => {
        return [
            {
                value: Number(data.threeOrMore),
                photo_count: 'THREE_OR_MORE'
            },
            {
                value: Number(data.sixOrMore),
                photo_count: 'SIX_OR_MORE'
            },
            {
                value: Number(data.moreThanTen),
                photo_count: 'MORE_THAN_TEN'
            }
        ];
    };

    const setFormData = (items: Array<DiscountItem>) => {
        items.forEach((item) => {
            switch (item.photo_count) {
                case 'THREE_OR_MORE':
                    setThreeOrMore(item.value);
                    break;
                case 'SIX_OR_MORE':
                    setSixOrMore(item.value);
                    break;
                case 'MORE_THAN_TEN':
                    setMoreThanTen(item.value);
                    break;
            }
        });
    };

    const _getPhotoSale = () => {
        if (props.albumId) {
            api.discounts.getAlbumDiscountsList(props.albumId)
                .then((resp) => {
                    if (!resp.data.length) {
                        setCreateMode(true);
                    } else {
                        setFormData(resp.data);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            api.discounts.getDiscountsList()
                .then((resp) => {
                    if (!resp.data.length) {
                        setCreateMode(true);
                    } else {
                        setFormData(resp.data);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const _createPhotoSale = () => {
        const data = prepareData(props.registry.form.getPayload());

        api.discounts.createDiscounts(data, props.albumId ? props.albumId : undefined)
            .then(() => {
                setCreateMode(false);
            }).catch((err) => {
                console.error(err);
            });
    };

    const _updatePhotoSale = () => {
        const data = prepareData(props.registry.form.getPayload());

        if (props.albumId) {
            api.discounts.updateAlbumDiscounts(props.albumId, data)
                .then(() => {
                    setEditMode(false);
                }).catch((err) => {
                    console.error(err);
                });
        } else {
            api.discounts.updateDiscounts(data)
                .then(() => {
                    setEditMode(false);
                }).catch((err) => {
                    console.error(err);
                });
        }
    };

    const _deletePhotoSale = () => {
        if (props.albumId) {
            api.discounts.destroyAlbumDiscounts(props.albumId)
                .then(() => {
                    setThreeOrMore(0);
                    setSixOrMore(0);
                    setMoreThanTen(0);
                    _getPhotoSale();
                    setDeleteMode(false);
                }).catch((err) => {
                    console.error(err);
                });
        } else {
            api.discounts.destroyDiscounts()
                .then(() => {
                    setThreeOrMore(0);
                    setSixOrMore(0);
                    setMoreThanTen(0);
                    _getPhotoSale();
                    setDeleteMode(false);
                }).catch((err) => {
                    console.error(err);
                });
        }
    };

    const elBtn = useMemo(() => {
        if (editMode) {
            return (
                <div className={cn('modal__form-btn')}>
                    <Button type="submit">Применить</Button>
                    <Button onClick={() => setEditMode(false)}>Отмена</Button>
                </div>
            );
        }

        if (createMode) {
            return (
                <div className={cn('modal__form-btn')}>
                    <Button type="submit">Создать</Button>
                </div>
            );
        }
    }, [editMode, createMode]);

    const elInput = useMemo(() => {
        const isDisabled = Boolean(!editMode && !createMode);

        return (
            <div className={cn('modal__form-input')}>
                <Input
                    disabled={isDisabled}
                    children="3-5"
                    registry={props.registry.field}
                    name="threeOrMore"
                    type="number"
                    min={0}
                    max={80}
                    defaultValue={String(threeOrMore)}
                />
                <Input
                    disabled={isDisabled}
                    children="6-10"
                    registry={props.registry.field}
                    name="sixOrMore"
                    type="number"
                    min={0}
                    max={80}
                    defaultValue={String(sixOrMore)}
                />
                <Input
                    disabled={isDisabled}
                    children="10+"
                    registry={props.registry.field}
                    name="moreThanTen"
                    type="number"
                    min={0}
                    max={80}
                    defaultValue={String(moreThanTen)}
                />
            </div>
        );
    }, [threeOrMore, sixOrMore, moreThanTen, editMode, createMode]);

    const onClickSubmit = useCallback(() => {
        if (editMode) {
            _updatePhotoSale();
        }

        if (createMode) {
            _createPhotoSale();
        }
    }, [editMode, createMode]);

    const onChange = (): void => {
        const payload = props.registry.form.getPayload();
        let sale = Number(payload.threeOrMore);

        if (payload.sixOrMore && Number(payload.sixOrMore) > sale) {
            sale = Number(payload.sixOrMore);
        }

        if (Number(payload.sixOrMore) === 0 || Number(payload.sixOrMore) < sale) {
            setSixOrMore(sale);
        }

        if (Number(payload.moreThanTen) === 0 || Number(payload.moreThanTen) < sale) {
            setMoreThanTen(sale);
        }
    };

    const elHeader = () => {
        return (
            <div className={cn('modal__header')}>
                <h2 className={cn('modal__title')}>Скидки</h2>
                <ReactTooltip />
                <FontAwesomeIcon
                    data-tip="Редактировать"
                    className={cn('modal__header-btn')}
                    onClick={() => setEditMode(true)}
                    icon={faEdit}
                />
                <FontAwesomeIcon
                    data-tip="Удалить"
                    className={cn('modal__header-btn')}
                    onClick={() => setDeleteMode(true)}
                    icon={faTrash}
                />
            </div>
        );
    };

    const elContent = useMemo(() => {
        if (deleteMode) {
            return (
                <Fragment>
                    <span>Вы уверены, что хотите удалить скидки?</span>
                    <div className={cn('modal__form-btn')}>
                        <Button onClick={_deletePhotoSale}>Да</Button>
                        <Button onClick={() => setDeleteMode(false)}>Нет</Button>
                    </div>
                </Fragment>
            );
        }

        return (
            <div>
                <span>Укажите размер скидки в % для каждого количества фотографий:</span>
                <Form registry={props.registry.form} onSubmit={onClickSubmit} onChange={onChange} className={cn('modal__form')}>
                    {elInput}
                    {elBtn}
                </Form>
            </div>
        );
    }, [editMode, createMode, deleteMode, threeOrMore, sixOrMore, moreThanTen]);

    return (
        <Modal onClickClose={props.onClickClose} className={cn('modal')}>
            {elHeader()}
            {elContent}
        </Modal>
    );
};

PhotoSaleModal.defaultProps = {
    albumId: null
};

export default PhotoSaleModal;
