import { AxiosResponse } from 'axios';
import {
    CreateDiscountsResponse,
    DiscountItem,
    DiscountRetrieve,
    DiscountsAPIType,
    UpdateDiscountsResponse
} from './types';
import httpClient from 'src/utils/http-сlient';

const getDiscountsList = (): Promise<AxiosResponse<Array<DiscountRetrieve>>> => {
    return httpClient.get<Array<DiscountRetrieve>>('/api/v2/discounts/');
};

const createDiscounts = (
    discounts: Array<DiscountItem>,
    album?: number
): Promise<AxiosResponse<CreateDiscountsResponse>> => {
    return httpClient.post<CreateDiscountsResponse>('/api/v2/discounts/', {
        ...(album && { album }),
        discounts
    });
};

const updateDiscounts = (
    discounts: Array<DiscountItem>
): Promise<AxiosResponse<UpdateDiscountsResponse>> => {
    return httpClient.put<UpdateDiscountsResponse>('/api/v2/discounts/', {
        discounts
    });
};

const destroyDiscounts = (): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>('/api/v2/discounts/');
};

const getAlbumDiscountsList = (
    id: number
): Promise<AxiosResponse<Array<DiscountItem>>> => {
    return httpClient.get<Array<DiscountItem>>(
        `/api/v2/discounts/albums/${id}/`
    );
};

const updateAlbumDiscounts = (
    id: number,
    discounts: Array<DiscountItem>
): Promise<AxiosResponse<UpdateDiscountsResponse>> => {
    return httpClient.put<UpdateDiscountsResponse>(
        `/api/v2/discounts/albums/${id}/`,
        {
            discounts
        }
    );
};

const destroyAlbumDiscounts = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/discounts/albums/${id}/`);
};

export default {
    getDiscountsList,
    createDiscounts,
    updateDiscounts,
    destroyDiscounts,
    getAlbumDiscountsList,
    updateAlbumDiscounts,
    destroyAlbumDiscounts
} as DiscountsAPIType;
