import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { WithdrawalsAPIType } from './types';

const withdrawalsCreate = (amount: string): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/withdrawals/', {
        amount
    });
};

export default {
    withdrawalsCreate
} as WithdrawalsAPIType;
