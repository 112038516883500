import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Button from 'component/button';
import { INormalizeObject } from 'component/helper/normalize-object';
import Loader from 'component/loader';
import api from 'src/api';
import { EventAlbumItem, EventAlbumListItem, GetEventAlbumsListData } from 'src/api/events/types';
import { getNormalizedQuery } from 'src/utils/get-normalized-query';
import { Page } from 'src/api/base';
import UI from 'component/ui';
import EventAlbum from 'component/event-album';
import { useClassnames } from 'hook/use-classnames';
import styles from '../index.pcss';
import { parse, stringify } from 'query-string';

interface IProps {
    event_id?: string;
    album_filter: string | null;
}

const ALBUMS_LIMIT = 2;

const EventSubAlbums = ({ event_id, album_filter }: IProps) => {
    const cn = useClassnames(styles);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(
        getNormalizedQuery()
    );
    // new sub albums
    const [isAlbumListPending, setIsAlbumListPending] = useState<boolean>(
        false
    );
    const [isAlbumListLoadMore, setIsAlbumListLoadMore] = useState<boolean>(
        false
    );
    const [albumListPage, setAlbumListPage] = useState<number>(1);
    const [isAlbumListNext, setIsAlbumListNext] = useState<boolean>(true);
    const [albumList, setAlbumList] = useState<Array<EventAlbumListItem>>([]);
    const [albumListTotal, setAlbumListTotal] = useState<number>(0);

    useEffect(() => {
        if (album_filter) {
            setIsAlbumListNext(true);
            setAlbumListPage(1);
            setIsAlbumListPending(true);
        } else {
            setAlbumList([]);
        }
    }, [album_filter]);

    useEffect(() => {
        if (isAlbumListPending || isAlbumListLoadMore) {
            const page: Page = {
                pageNumber: albumListPage,
                pageSize: ALBUMS_LIMIT
            };

            const data: GetEventAlbumsListData = {
                photographer_id: Number(album_filter)
            };

            api.events
                .getEventAlbumsList(Number(event_id), page, data)
                .then((resp) => {
                    setAlbumListTotal(resp.data.count);
                    setAlbumList(
                        isAlbumListLoadMore
                            ? [...albumList, ...resp.data.results]
                            : resp.data.results
                    );
                    setIsAlbumListPending(false);
                    setIsAlbumListLoadMore(false);
                    setIsAlbumListNext(!!resp.data.next);
                })
                .catch(() => {
                    setIsAlbumListPending(false);
                    setIsAlbumListLoadMore(false);
                    setIsAlbumListNext(false);
                });
        }
    }, [isAlbumListPending, isAlbumListLoadMore]);

    const onClickMoreAlbums = (e: React.MouseEvent): void => {
        e.preventDefault();

        if (isAlbumListNext) {
            setAlbumListPage((prev) => prev + 1);
            setIsAlbumListLoadMore(true);
        }
    };

    const elButtonBeforeSubAlbums = () => {
        if (isAlbumListLoadMore) {
            return <Loader />;
        }

        if (
            !isAlbumListPending &&
            !isAlbumListLoadMore &&
            albumList?.length &&
            isAlbumListNext
        ) {
            if (!(albumList?.length - 1 === albumListTotal)) {
                return (
                    <Button
                        disabled={isAlbumListPending}
                        isLoading={isAlbumListPending || isAlbumListLoadMore}
                        isSecondary={true}
                        onClick={onClickMoreAlbums}
                    >
                        Загрузить ещё
                    </Button>
                );
            }
        }
    };

    const elSubAlbumsContent = useMemo(() => {
        if (isAlbumListPending && !albumList.length) {
            return <Loader />;
        }

        if (
            albumList.filter((item) => item.pk !== Number(queryParams.album_id))
                .length
        ) {

            // @ts-ignore
            const { photographer_id, album_id, person_id, ...restQueryParams } = parse(location.search);
            const query = stringify(restQueryParams, { arrayFormat: 'none' });

            return (
                <Fragment>
                    <UI.PageHeader text="Вложенные альбомы" />
                    <div className={cn('event-sub__items')}>
                        {albumList.map((item, index) => {
                            if (item.pk === Number(queryParams.album_id)) {
                                return;
                            }

                            return (
                                <EventAlbum
                                    key={index}
                                    album={item}
                                    event_id={Number(event_id)}
                                    photos={item.photos}
                                    photos_count={albumListTotal}
                                    className={cn('event-sub__post')}
                                    isBlankLink={true}
                                    query={`?${query}`}
                                />
                            );
                        })}
                    </div>
                </Fragment>
            );
        }
    }, [JSON.stringify(albumList), isAlbumListPending, queryParams.album_id, queryParams.photographer_id]);

    return (
        <div className={cn('event-sub')}>
            {elSubAlbumsContent}
            {elButtonBeforeSubAlbums()}
        </div>
    );
};

export default EventSubAlbums;
