import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import Carousel, { RenderArrowProps } from 'react-elastic-carousel';
import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import IconCross from 'component/icon/cross';
import Error from 'component/error';
import MiniInfo from 'component/mini-info';
import ModalMerge from './modal-merge';
import { IProps } from './types';
import style from './index.pcss';
import { SubscriptionsItemSimilar } from 'src/api/subscriptions/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import api from 'src/api';
import { useAlert } from 'component/alert/provider';

export default (props: IProps) => {
    const cn = useClassnames(style);
    const { show, hide } = useAlert();
    // const { t } = useTranslation();
    const [tokenDelete] = useCancelTokens(1);

    const carouselRef = useRef(null);
    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].tablet);

    const [similarPersonList, setSimilarPersonList] = useState<Array<SubscriptionsItemSimilar>>(props.similar_persons || []);
    const [selectedPerson, setSelectedPerson] = useState<SubscriptionsItemSimilar | null>(null);
    const [modalMerge, setModalMerge] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        return () => {
            tokenDelete.remove();
        };
    }, []);

    const getItemCount = (): number => {
        if (isTablet || isMobile) {
            return 3;
        }

        return 9;
    };

    const onPersonMerged = () => {
        props.onPersonChanged && props.onPersonChanged();
    };

    const _requestDeletePerson = (similar_id: number) => {
        setError(null);

        api.similarPersons.createSimilarPersonsRequest([similar_id])
            .then(() => {

                show('Персона успешно удалена из похожих', 'success');
                setTimeout(() => {
                    hide();
                }, 5000);

                if (props.onPersonChanged) {
                    props.onPersonChanged();
                } else {
                    const newSimilarPersonList = similarPersonList.filter((item) => item.id !== similar_id);
                    setSimilarPersonList(newSimilarPersonList);
                }
            })
            .catch((err) => {
                if (err.response.data[0]) {
                    show(err.response.data[0], 'warning');
                    setTimeout(() => {
                        hide();
                    }, 5000);
                } else {
                    show('Что-то пошло не так', 'warning');
                    setTimeout(() => {
                        hide();
                    }, 5000);
                }
            });
    };

    const onClickClose = useCallback(() => {
        setModalMerge(false);
        setSelectedPerson(null);
    }, []);

    const elModalMerge = useMemo(() => {
        if (modalMerge && selectedPerson && props.person) {
            return (
                <ModalMerge
                    person={props.person}
                    selectedPerson={selectedPerson}
                    onClickClose={onClickClose}
                    onPersonMerged={onPersonMerged}
                />
            );
        }
    }, [modalMerge, selectedPerson]);

    const onSelectPerson = (person: SubscriptionsItemSimilar) => () => {
        setSelectedPerson(person);
        setModalMerge(true);
    };

    const onDeletePerson = (person_id: number) => () => {
        _requestDeletePerson(person_id);
    };

    const elNavArrows = ({ type, onClick, isEdge}: RenderArrowProps) => {

        const pointer = type === 'PREV'
            ? <FontAwesomeIcon icon={faChevronCircleLeft} size="lg" />
            : <FontAwesomeIcon icon={faChevronCircleRight} size="lg" />;
        const callback = type === 'PREV'
            ? onClick
            : onClick;

        return (
            <div
                className={cn('similar-person__persons-list__btn', {
                    'similar-person__persons-list__btn-disable': isEdge,
                    'similar-person__persons-list__btn-left': type === 'PREV',
                    'similar-person__persons-list__btn-right': type !== 'PREV'
                })}
            >
                <button
                    onClick={callback}
                    disabled={isEdge}
                >
                    {pointer}
                </button>
            </div>
        );
    };

    const elPersons = useMemo(() => {
        return (
            <div className={cn('similar-person__persons')}>
                <div className={cn('similar-person__persons-title')}>похожие персоны</div>
                <Carousel
                    className={cn('similar-person__persons-list')}
                    isRTL={false}
                    enableMouseSwipe={false}
                    pagination={false}
                    itemsToShow={getItemCount()}
                    itemsToScroll={getItemCount()}
                    renderArrow={elNavArrows}
                    ref={carouselRef}
                >
                    {similarPersonList.map((item, i) => {
                        return (
                            <div key={i} className={cn('similar-person__persons-item')}>
                                <MiniInfo
                                    key={item.id}
                                    className={cn('similar-person__persons-item-info')}
                                    avatar={{
                                        imgSrc: item.photo_url
                                    }}
                                    onClick={onSelectPerson(item)}
                                />
                                <IconCross
                                    className={cn('similar-person__persons-button')}
                                    title="Нажмите, если это не ваша персона"
                                    onClick={onDeletePerson(item.id)}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        );
    }, [similarPersonList, isTablet, isMobile]);

    if (similarPersonList.length) {
        return (
            <div>
                {elPersons}
                {elModalMerge}
            </div>
        );
    }

    return null;
};
