import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useCancelTokens } from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import Loader from 'component/loader';
import Form, { useRegistry } from 'component/form';
import { IPayload } from 'component/form/types';
import Input from 'component/form/input';
import Button from 'component/button';
import Modal from 'component/modal';
import MiniInfo from 'component/mini-info';
import IconError from 'component/icon/error';
import IconOK from 'component/icon/ok';
import IconDelete from 'component/icon/close';
import {
    acceptAccessRequestAlbum,
    rejectAccessRequestAlbum
} from 'component/api/album';
import { IProps } from './types';
import style from './style.pcss';
import UserRightButtons from 'component/modal/album-access/right-btns';
import api from 'src/api';
import { Page } from 'src/api/base';
import { AlbumAccessesListItem, AlbumRequestsListItem } from 'src/api/albums/types';

const AlbumAccess: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const {field, form} = useRegistry();
    const [tokenAction] = useCancelTokens(1);

    const btnParams = {
        className: cn('album-access__button'),
        isSecondary: true,
        isSmall: true
    };

    const [link, setLink] = useState<string | null>('');
    const [requesting, setRequesting] = useState<Array<AlbumRequestsListItem>>([]);
    const [permitted, setPermitted] = useState<Array<AlbumAccessesListItem>>([]);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [pendingAccess, setPendingAccess] = useState<boolean>(true);
    const [pendingRequest, setPendingRequest] = useState<boolean>(true);
    const [pendingAction, setPendingAction] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // TODO: Добавить пагинацию вызова
    // Пользователи, ожидающие подтверждения
    const _requestRequestList = () => {
        setPendingRequest(true);

        const page: Page = {
            pageNumber: 1
        };

        api.albums.getAlbumRequestsList(props.albumId, page)
            .then((payload) => {
                if (payload.data.results.length) {
                    setRequesting(payload.data.results);
                }
                setPendingRequest(false);
            })
            .catch((err) => {
                setPendingRequest(false);
            });
    };

    // TODO: Добавить пагинацию вызова
    // Приглашенные пользователи
    const _requestAccessList = () => {
        setPendingAccess(true);

        const page: Page = {
            pageNumber: 1
        };

        api.albums.getAlbumAccessesList(props.albumId, page)
            .then((payload) => {
                if (payload.data.results.length) {
                    setPermitted(payload.data.results);
                }
                setPendingAccess(false);
            })
            .catch((err) => {
                setPendingAccess(false);
            });
    };

    useEffect(() => {
        _requestRequestList();
        _requestAccessList();

        return () => {
            tokenAction.remove();
        };
    }, []);

    const onClickGetLink = (e: MouseEvent): void => {
        e.preventDefault();
        if (link) {
            navigator.clipboard.writeText(link);

            return;
        }
        setPendingAction(true);

        api.albums.createAlbumRequestsTokens(props.albumId)
            .then((payload) => {
                const port = window.location.port ? `:${window.location.port}` : '';
                const fullLink = `${window.location.protocol}//${window.location.hostname}${port}/invite/${payload.data.token}`;
                setLink(fullLink);
                setPendingAction(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAction(false);
                }
                setError(err.message);
            });
    };

    // Удаление пользователя из списка
    const onClickDeleteAccess = (user_access_id: number) => (e: MouseEvent): void => {
        e.preventDefault();
        setPendingAction(true);

        api.albums.destroyAlbumsAccess(props.albumId, user_access_id)
            .then(() => {
                const newPermitted = permitted.filter((item) => item.id !== user_access_id);
                setPermitted(newPermitted);
                setPendingAction(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAction(false);
                }
                setError(err.message);
            });
    };

    const onClickAddRequesting = (access_request_id: number) => (e: MouseEvent): void => {
        e.preventDefault();
        setPendingAction(true);

        acceptAccessRequestAlbum({
            cancelToken: tokenAction.new(),
            data: {access_request_id}
        })
            .then(() => {
                const newRequesting = requesting.filter((item) => item.id !== access_request_id);
                setRequesting(newRequesting);
                setPendingAction(false);
                _requestAccessList();
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAction(false);
                }
                setError(err.message);
            });
    };

    const onClickDeleteRequesting = (access_request_id: number) => (e: MouseEvent): void => {
        e.preventDefault();
        setPendingAction(true);

        rejectAccessRequestAlbum({
            cancelToken: tokenAction.new(),
            data: {access_request_id}
        })
            .then(() => {
                const newRequesting = requesting.filter((item) => item.id !== access_request_id);
                setRequesting(newRequesting);
                setPendingAction(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAction(false);
                }
                setError(err.message);
            });
    };

    const elRequestingList = useMemo(() => {
        return requesting.map((item) => (
            <div key={item.id} className={cn('album-access__share-item')}>
                <MiniInfo
                    className={cn('album-access__share-item-input')}
                    title={`${item.user?.name}`}
                    size={32}
                />
                <Button onClick={onClickAddRequesting(item.id)} {...btnParams} disabled={props.pending || pendingAction}>
                    <IconOK className={cn('album-access__icon')} />
                </Button>
                <Button onClick={onClickDeleteRequesting(item.id)} {...btnParams} disabled={props.pending || pendingAction}>
                    <IconDelete className={cn('album-access__icon')} />
                </Button>
            </div>
        ));
    }, [requesting, pendingAction]);

    const elPermittedList = useMemo(() => {
        if (permitted.length) {
            return permitted.map((item) => (
                <div key={item.id} className={cn('album-access__share-item')}>
                    <MiniInfo
                        className={cn('album-access__share-item-input')}
                        title={`${item.user?.name}`}
                        size={32}
                    />
                    <UserRightButtons albumID={props.albumId} accessID={item.id} userRights={item.rights} />
                    <Button
                        onClick={onClickDeleteAccess(item.id)}
                        {...btnParams}
                        disabled={props.pending || pendingAction}
                    >
                        <IconDelete className={cn('album-access__icon')} />
                    </Button>
                </div>
            ));
        }
    }, [permitted, pendingAction]);

    // Добавление пользователя по email
    const onClickAdd = useCallback((formData: IPayload) => {
        setPendingAction(true);
        setError(null);

        api.albums.createAlbumRequestsInvites(props.albumId, formData.email)
            .then(() => {
                form.clearForm();
                setShowMessage(true);
                setPendingAction(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAction(false);
                }
                setError(err.message);
            });
    }, []);

    const elRequesting = useMemo(() => {
        if (requesting.length) {
            return (
                <>
                    <strong className={cn('album-access__share-title')}>
                        {t('route.album.access.title-request')}
                        <span className={cn('album-access__count')}>({requesting.length || ''})</span>
                    </strong>
                    <div className={cn('album-access__requesting')}>
                        {elRequestingList}
                    </div>
                </>
            );
        }
    }, [requesting]);

    const elPermitted = useMemo(() => {
        return (
            <>
                <strong className={cn('album-access__share-title')}>
                    {t('route.album.access.title-access')}
                    <span className={cn('album-access__count')}>{permitted.length || ''}</span>
                </strong>
                <Form registry={form} onSubmit={onClickAdd}>
                    <div className={cn('album-access__permitted')}>
                        <div className={cn('album-access__share-item')}>

                                <Input
                                    className={cn('album-access__share-item-input')}
                                    registry={field}
                                    type="email"
                                    name="email"
                                    placeholder={t('route.album.access.email-placeholder')}
                                    disabled={props.pending || pendingAction}
                                    required={true}
                                />
                                <Button
                                    type="submit"
                                    className={cn('album-access__button-add')}
                                    isSecondary={true}
                                    disabled={props.pending || pendingAction}
                                    isLoading={pendingAction}
                                >
                                    {t('route.album.access.button-add')}
                                </Button>

                        </div>
                        {elPermittedList}
                    </div>
                </Form>
            </>
        );
    }, [permitted, pendingAction, onClickAdd]);

    const elLink = useMemo(() => {
        const caption = link ? t('route.album.access.link-copy') : t('route.album.access.link-get');

        return (
            <div className={cn('album-access__link-wrapper')}>
                <Button
                    onClick={onClickGetLink}
                    isSecondary={true}
                    disabled={props.pending || pendingAction}
                >
                    {caption}
                </Button>
                <Input
                    className={cn('album-access__share-item-input')}
                    registry={field}
                    name="link"
                    placeholder={t('route.album.access.link-placeholder')}
                    disabled={true}
                    defaultValue={link}
                    icon="link"
                />
            </div>
        );
    }, [link, pendingAction]);

    const elContent = useMemo(() => {
        if (pendingAccess || pendingRequest) {
            return <Loader />;
        }

        return (
            <>
                {elLink}
                <div className={cn('album-access__share')}>
                    {elRequesting}
                    {elPermitted}
                </div>
            </>
        );
    }, [link, requesting, permitted, pendingAccess, pendingRequest, pendingAction]);

    const onClickCloseModalMessage = (): void => {
        setShowMessage(false);
    };

    const elModalMessage = useMemo(() => {
        if (showMessage) {
            return (
                <Modal onClickClose={onClickCloseModalMessage}>
                    <div className={cn('album-access__message')}>
                        <IconOK className={cn('album-access__icon-ok')} /> {t('route.album.access.message-invite')}
                    </div>
                </Modal>
            );
        }
    }, [showMessage]);

    const elError = useMemo(() => {
        if (error) {
            return (
                <div className={cn('album-access__error')}>
                    <IconError className={cn('album-access__error-icon')} />
                    {error}
                </div>
            );
        }
    }, [error]);

    return (
        <Modal onClickClose={props.onClickClose} className={cn('album-access')}>
            <h2>{t('route.album.access.title')}</h2>
            {elModalMessage}
            {elContent}
            {elError}
        </Modal>
    );
};

AlbumAccess.defaultProps = {
    pending: false
};

export default AlbumAccess;
