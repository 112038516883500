import React, { useEffect, useState, useRef, useMemo, useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Chart from 'chart.js';

import { useClassnames } from 'hook/use-classnames';
import Exit from 'component/icon/exit';
import Loader from 'component/loader';
import Error from 'component/error';
import Plus from 'component/icon/plus';
import Tags from 'component/icon/tags';
import UI from 'component/ui';
import Input from 'component/form/input';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';
import Modal from 'component/modal';
import Phone from './phone';

import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

import style from './style.pcss';
import InputInterval from 'component/form/input-interval';
import debounce from 'lodash.debounce';
import api from 'src/api';
import { Page } from 'src/api/base';
import { GetSalesListData, SalesListItem } from 'src/api/sales/types';

const MIN_MONEY_WITHDRAWAL = 1000;
const SELLS_LIMIT = 20;

const Dashboard = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    const withdrawalRegistry = useRegistry();
    const sellsRegistry = useRegistry();

    const [errorRevenue, setErrorRevenue] = useState<string | null>(null);
    const [errorSells, setErrorSells] = useState<string | null>(null);
    const [errorPhotoList, setErrorPhotoList] = useState<string | null>(null);
    const [errorWithdrawal, setErrorWithdrawal] = useState<string | null>(null);

    const [pendingRevenue, setPendingRevenue] = useState<boolean>(true);
    const [moneyWithdrawal, setMoneyWithdrawal] = useState<boolean>(false);

    const [modalMessageWithdrawal, setModalMessageWithdrawal] = useState<boolean>(false);
    const [modalFormWithdrawal, setModalFormWithdrawal] = useState<boolean>(false);

    const [revenue, setRevenue] = useState<number | null>(null);
    const [photoListCount, setPhotoListCount] = useState<number | null>(null);
    const [photoListAllCount, setPhotoListAllCount] = useState<number | null>(null);
    const $canvas = useRef<HTMLCanvasElement>(null);

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [isPhotosStatisticLoading, setIsPhotosStatisticLoading] = useState<boolean>(false);

    const [soldTotal, setSoldTotal] = useState<number>(0);

    const [sellsList, setSellsList] = useState<Array<SalesListItem>>([]);
    const [sellsCount, setSellsCount] = useState<number>(0);
    const [sellsPage, setSellsPage] = useState<number>(1);
    const [isSellsPageNext, setIsSellsPageNext] = useState<boolean>(true);
    const [isSellsLoading, setIsSellsLoading] = useState<boolean>(false);
    const [isSellsLoadMore, setIsSellsLoadMore] = useState<boolean>(false);

    const _requestRevenue = () => {
        setPendingRevenue(true);

        api.sales.getSalesRevenue()
            .then((resp) => {
                setRevenue(resp.data.current_revenue);
                setMoneyWithdrawal(resp.data ? resp.data.current_revenue >= MIN_MONEY_WITHDRAWAL : false);
                setPendingRevenue(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setErrorRevenue(err.message);
                    setPendingRevenue(false);
                }
            });
    };

    useEffect(() => {
        if (isSellsLoading || isSellsLoadMore) {
            const payload = sellsRegistry.form.getPayload();

            const page: Page = {
                pageNumber: sellsPage,
                pageSize: SELLS_LIMIT
            };
            const data: GetSalesListData = {
                ...(payload.date_range?.date_from && { date_after: payload.date_range.date_from }),
                ...(payload.date_range?.date_to && { date_before: payload.date_range.date_to })
            };

            api.sales.getSalesList(page, data)
                .then((resp) => {
                    const items = resp.data.results;

                    setSellsCount(resp.data.count);
                    setSellsList((prev) => isSellsLoadMore ? [...prev, ...items] : items);
                    setIsSellsLoading(false);
                    setIsSellsLoadMore(false);
                })
                .catch((err) => {
                    console.error(err);

                    setErrorSells(err.message);
                    setIsSellsLoading(false);
                    setIsSellsLoadMore(false);
                    setIsSellsPageNext(false);
                });
        }

    }, [isSellsLoading, isSellsLoadMore]);

    useEffect(() => {
        if (isPhotosStatisticLoading) {
            api.statistic.getPhotosStatistics()
                .then((resp) => {
                    setPhotoListCount(resp.data.on_sale);
                    setPhotoListAllCount(resp.data.total);
                    setSoldTotal(resp.data.sold);
                    setIsPhotosStatisticLoading(false);
                })
                .catch((err) => {
                        console.error(err);

                        setErrorPhotoList(err.message);
                        setIsPhotosStatisticLoading(false);
                });
        }
    }, [isPhotosStatisticLoading]);

    const _requestMoneyWithdrawal = (amount: string) => {
        setPendingRevenue(true);
        setErrorWithdrawal(null);

        api.withdrawals.withdrawalsCreate(amount)
            .then(() => {
                setModalFormWithdrawal(false);
                setModalMessageWithdrawal(true);
                setPendingRevenue(false);
            })
            .catch((err) => {
                console.error(err);
                setErrorWithdrawal(err.message);
                setPendingRevenue(false);
            });
    };

    const onClickCloseMessage = (): void => {
        setModalFormWithdrawal(false);
        setModalMessageWithdrawal(false);
    };

    const onClickMore = (e: MouseEvent): void => {
        e.preventDefault();

        setSellsPage((prev) => prev + 1);
        setIsSellsLoadMore(true);
    };

    const onSubmitWithdrawal = useCallback(() => {
        const fields = withdrawalRegistry.form.getFields();
        if (fields) {
            const fieldAmount = fields.get('amount_withdrawal');
            if (fieldAmount) {
                if (!fieldAmount.value) {
                    fieldAmount.setError(t('route.dashboard.error.no-amount'));
                } else {
                    _requestMoneyWithdrawal(fieldAmount.value);
                }
            }
        }
    }, [modalMessageWithdrawal, pendingRevenue, modalFormWithdrawal]);

    const elModalMessageWithdrawal = useMemo(() => {
        if (modalFormWithdrawal) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    <Form
                        registry={withdrawalRegistry.form}
                        className={cn('dashboard__modal-withdrawal')}
                        onSubmit={onSubmitWithdrawal}
                    >
                        <Input
                            registry={withdrawalRegistry.field}
                            name="amount_withdrawal"
                            children={t('route.dashboard.modal.withdrawal.revenue')}
                            defaultValue={String(revenue)}
                            placeholder={t('route.dashboard.modal.withdrawal.revenue-placeholder')}
                            className={cn('dashboard__withdrawal-input')}
                            disabled={true}
                        />
                        <Button
                            type="submit"
                            disabled={pendingRevenue}
                            isLoading={pendingRevenue}
                            className={cn('dashboard__grid-item_phone-button')}
                        >
                            {t('route.dashboard.modal.withdrawal.button')}
                        </Button>
                    </Form>
                    {errorWithdrawal && <Error>{errorWithdrawal}</Error>}
                </Modal>
            );
        }

        if (modalMessageWithdrawal) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    <div className={cn('persons__message')}>
                        {t('route.dashboard.modal.withdrawal.message-ok')}
                    </div>
                </Modal>
            );
        }
    }, [moneyWithdrawal, modalFormWithdrawal, modalMessageWithdrawal, pendingRevenue, revenue, errorWithdrawal]);

    useEffect(() => {
        setIsPhotosStatisticLoading(true);
        _requestRevenue();
    }, []);

    useEffect(() => {
        const ctx = $canvas.current?.getContext('2d');

        if(ctx) {
            // tslint:disable-next-line no-unused-expression
            new Chart(ctx, {
                type: 'line',
                data: {
                    labels: [
                        t('route.dashboard.graph.jan').toString(),
                        t('route.dashboard.graph.feb').toString(),
                        t('route.dashboard.graph.mar').toString(),
                        t('route.dashboard.graph.apr').toString(),
                        t('route.dashboard.graph.may').toString(),
                        t('route.dashboard.graph.jun').toString(),
                        t('route.dashboard.graph.jul').toString(),
                        t('route.dashboard.graph.aug').toString(),
                        t('route.dashboard.graph.sep').toString(),
                        t('route.dashboard.graph.oct').toString(),
                        t('route.dashboard.graph.nov').toString(),
                        t('route.dashboard.graph.dec').toString()
                    ],
                    datasets: [
                        {
                            label: t('route.dashboard.graph.label'),
                            backgroundColor: '#FF5487',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        }
                    ]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }
    }, [JSON.stringify(sellsList)]);

    const onClickFormWithdrawal = useCallback(() => {
        if (moneyWithdrawal) {
            setErrorWithdrawal(null);
            setModalFormWithdrawal(true);
        }
    }, [modalFormWithdrawal, moneyWithdrawal]);

    // На счету
    const elMoney = useMemo(() => {
        if(pendingRevenue) {
            return <Loader />;
        }

        if(errorRevenue) {
            return <Error>{errorRevenue}</Error>;
        }

        return (
            <div className={cn('dashboard__grid-item', 'dashboard__grid-item_money')}>
                <div className={cn('dashboard__item-head')}>
                    <h3 className={cn('dashboard__item-header')}>{t('route.dashboard.stats.balance')}</h3>
                    <Exit
                        className={cn('dashboard__icon', {'dashboard__icon_exit': moneyWithdrawal})}
                        onClick={onClickFormWithdrawal}
                    />
                </div>
                <span className={cn('dashboard__stats-text')}>{revenue} {t('route.dashboard.money.unit')}</span>
                {!moneyWithdrawal && <div className={cn('dashboard__stats-info')}>{t('route.dashboard.money.message')}</div>}
            </div>
        );
    }, [moneyWithdrawal, pendingRevenue]);

    // Фото на продаже
    const elPhotoListCount = () => {
        if(isPhotosStatisticLoading) {
            return <Loader />;
        }

        if(errorPhotoList) {
            return <Error>{errorPhotoList}</Error>;
        }

        return (
            <a
                href="/dashboard/photos"
                target="_blank"
                className={cn('dashboard__grid-item', 'dashboard__grid-item_count')}
            >
                <h3 className={cn('dashboard__item-header')}>{t('route.dashboard.stats.onsale')}</h3>
                <span className={cn('dashboard__stats-text')}>{photoListCount}</span>
                <span className={cn('dashboard__stats-separator')}> {t('route.dashboard.stats.from')} </span>
                <span className={cn('dashboard__stats-text')}>{photoListAllCount}</span>
            </a>
        );
    };

    // Продаж
    const elSold = () => {
        if(isPhotosStatisticLoading) {
            return <Loader />;
        }

        return (
            <div className={cn('dashboard__grid-item', 'dashboard__grid-item_sells')}>
                <h3 className={cn('dashboard__item-header')}>{t('route.dashboard.stats.sales')}</h3>
                <span className={cn('dashboard__stats-text')}>{soldTotal}</span>
            </div>
        );
    };

    const onChangeForm = debounce(useCallback(() => {
        setIsSellsPageNext(true);
        setSellsPage(1);
        setIsSellsLoading(true);
    }, []), 300);

    const elButtonBeforeSells = useMemo(() => {
        if (sellsList?.length < sellsCount && isSellsPageNext) {
            return (
                <div className={cn('dashboard__history-button')}>
                    <Button
                        disabled={isSellsLoadMore}
                        isLoading={isSellsLoadMore}
                        isSecondary={true}
                        onClick={onClickMore}
                    >
                        Загрузить ещё
                    </Button>
                </div>
            );
        }
    }, [sellsList, sellsCount, isSellsPageNext, isSellsLoadMore]);

    const elHistoryList = useMemo(() => {
        if(isSellsLoading) {
            return <Loader />;
        }

        if(errorSells) {
            return <Error>{errorSells}</Error>;
        }

        if(!isSellsLoading && !sellsList.length) {
            return (
                <div className={cn('dashboard__history-empty')}>
                    <p>{t('route.dashboard.history.empty')}</p>
                </div>
            );
        }

        return (
            <div>
                <div className={cn('dashboard__history-list')}>
                    {sellsList.map((item, index) => (
                        <div
                            key={index}
                            className={cn('dashboard__history-item')}
                        >
                            {
                                item.event
                                    ? (
                                        <Link to={`/events/${item.event.id}/photos/${item.photo.pk}`}>
                                            <img src={item.photo?.url} className={cn('dashboard__history-image')} alt="" />
                                        </Link>
                                    )
                                    : <img src={item.photo?.url} className={cn('dashboard__history-image')} alt="" />
                            }
                            <div className={cn('dashboard__history-content')}>
                                {item.photo && (
                                    item.event
                                        ? (
                                            <Link to={`/events/${item.event?.id}`}>
                                                <span className={cn('dashboard__history-text')}>{item.event.name}</span>
                                            </Link>
                                        )
                                        : <span className={cn('dashboard__history-text')}>{t('route.dashboard.history.no-event')}</span>

                                )}
                                <span className={cn('dashboard__history-date')}>{moment(item.date_at).format('LL')}</span>
                                <span
                                    className={cn(
                                        'dashboard__history-sum'
                                    )}
                                >
                                    +{item.income} {t('route.dashboard.history.unit')}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>

                {elButtonBeforeSells}
            </div>
        );
    }, [sellsList, isSellsLoading, isSellsLoadMore, isSellsPageNext]);

    const elHistory = () => {
        return (
            <div className={cn('dashboard__history')}>
                <Form
                    registry={sellsRegistry.form}
                    onChange={onChangeForm}
                    className={cn('dashboard__history-form')}
                >
                    <InputInterval
                        registry={sellsRegistry.field}
                        name="date_range"
                        children={t('global.form.items.interval')}
                        direction="column"
                    />
                </Form>
                {elHistoryList}
            </div>
        );
    };

    // Action кнопки
    const elControls = () => {
        return (
            <div className={cn('dashboard__grid-item')}>
                <Link to="/upload-photo" className={cn('dashboard__control')}>
                    <Plus className={cn('dashboard__control-icon')} />
                    <div className={cn('dashboard__control-content')}>
                        <span className={cn('dashboard__control-text')}>{t('route.dashboard.controls.upload')}</span>
                    </div>
                </Link>
                <Link to="/dashboard/photos" className={cn('dashboard__control')}>
                    <Tags className={cn('dashboard__control-icon')} />
                    <div className={cn('dashboard__control-content')}>
                        <span className={cn('dashboard__control-text')}>{t('route.dashboard.controls.my-photo')}</span>
                    </div>
                </Link>
            </div>
        );
    };

    if(!userId) {
        return <Redirect to="/login" />;
    }

    return (
        <UI.Main className={cn('dashboard')}>
            {elModalMessageWithdrawal}
            <div className={cn('dashboard__grid')}>
                {elPhotoListCount()}
                {elSold()}
                {elMoney}
                <div className={cn('dashboard__grid-item_controls')}>
                    <Phone />
                    {elControls()}
                </div>
                <div className={cn('dashboard__grid-item', 'dashboard__grid-item_history')}>
                    <h4 className={cn('dashboard__grid-header')}>{t('route.dashboard.history.title')}</h4>
                    {elHistory()}
                </div>
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line max-file-line-count
export default Dashboard;
