import React, { FC, useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faTrash, faDollarSign, faTimes, faTrophy } from '@fortawesome/free-solid-svg-icons';

import { IProps } from 'component/action-bar/types';
import { useClassnames } from 'hook/use-classnames';
import style from 'component/action-bar/styles.pcss';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Form, { useRegistry } from 'component/form';
import InputForm from 'component/form/input';
import inputStyle from 'component/sidebar-actions/input.pcss';
import Button from 'component/button';
import Modal from 'component/modal';
import InputYears from 'component/form/input-years';
import InputEvents from 'component/form/input-events/index-tsx-async';
import { IValue } from 'component/form/input-years/types';
import InputClubs from 'component/form/input-clubs';
import InputAlbums from 'component/form/input-albums';

const ActionBar: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();

    const eventRegistry = useRegistry();
    const commentRegistry = useRegistry();
    const albumRegistry = useRegistry();
    const priceRegistry = useRegistry();
    const resetPriceRegistry = useRegistry();
    const removePhotoRegistry = useRegistry();

    const [showEvent, setShowEvent] = useState<boolean>(false);
    const [showComment, setShowComment] = useState<boolean>(false);
    const [showAlbum, setShowAlbum] = useState<boolean>(false);
    const [showPrice, setShowPrice] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showResetPrice, setShowResetPrice] = useState<boolean>(false);
    const [showRemove, setShowRemove] = useState<boolean>(false);
    const [year, setYear] = useState<number>(0);

    const hideAll = () => {
        setShowEvent(false);
        setShowComment(false);
        setShowAlbum(false);
        setShowPrice(false);
        setShowResetPrice(false);
        setShowRemove(false);
        setShowModal(false);
    };

    const onChangeYear = (value: IValue): void => {
        if (value) {
            setYear(Number(value?.value));
        }
    };

    // Открытие форм
    const onClickEvent = useCallback(() => {
        setYear(0);
        setShowEvent(true);
        setShowModal(true);
    }, []);

    const onClickComment = useCallback(() => {
        setShowComment(true);
        setShowModal(true);
    }, []);

    const onClickAlbum = useCallback(() => {
        setShowAlbum(true);
        setShowModal(true);
    }, []);

    const onClickPrice = useCallback(() => {
        setShowPrice(true);
        setShowModal(true);
    }, []);

    const onClickResetPrice = useCallback(() => {
        setShowResetPrice(true);
        setShowModal(true);
    }, []);

    const onClickRemove = useCallback(() => {
        setShowRemove(true);
        setShowModal(true);
    }, []);

    // Сабмиты форм
    const onSubmitEvent = useCallback((payload) => {
        props.update && props.update({ event_id: payload.event?.value });

        hideAll();
    }, [props.update]);

    const onSubmitComment = useCallback((payload) => {
        props.update && props.update({ club_id: payload.club?.value });

        hideAll();
    }, [props.update]);

    const onSubmitAlbum = useCallback((payload) => {
        props.update && props.update({album_id: payload.album?.value});

        hideAll();
    }, [props.update]);

    const onSubmitPrice = useCallback((payload) => {
        props.update && props.update({ price: payload.price });

        hideAll();
    }, [props.update]);

    const onSubmitResetPrice = useCallback(() => {
        props.update && props.update({ price: 0 });

        hideAll();
    }, [props.update]);

    const onSubmitRemove = useCallback(() => {
        props.update && props.update({is_deleted: true});

        hideAll();
    }, [props.update]);

    // Формы
    const elEventForm = useMemo(() => {
        const isDisabledEvents = !Boolean(year);

        return (
            <Form
                registry={eventRegistry.form}
                className={cn('action-bar__form')}
                onSubmit={onSubmitEvent}
            >
                <h1 className={cn('action-bar__form-title')}>
                    {t('components.sidebar-action.forms.titles.event')}
                </h1>
                <InputYears
                    registry={eventRegistry.field}
                    name="year"
                    clearable={true}
                    children={t('components.sidebar-action.forms.items.year')}
                    direction="column"
                    onChange={onChangeYear}
                />
                <InputEvents
                    registry={eventRegistry.field}
                    name="event"
                    required={true}
                    clearable={true}
                    excludeEmpty={false}
                    year={year}
                    children={t('components.sidebar-action.forms.items.event')}
                    direction="column"
                    disabled={isDisabledEvents}
                />
                <Button className={cn('action-bar__form-button')} type="submit" disabled={isDisabledEvents}>
                    {t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update, year]);

    const elCommentForm = useMemo(() => {
        return (
            <Form
                registry={commentRegistry.form}
                className={cn('action-bar__form')}
                onSubmit={onSubmitComment}
            >
                <h1 className={cn('action-bar__form-title')}>
                    {t('components.sidebar-action.forms.titles.club')}
                </h1>
                <InputClubs
                    registry={commentRegistry.field}
                    // children={t('components.sidebar-action.forms.items.cost')}
                    children={'Укажите комментарий'}
                    direction="column"
                    name="club"
                    required={true}
                    className={inputStyle}
                />
                <Button className={cn('action-bar__form-button')} type="submit">
                    {t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update]);

    const elAlbumForm = useMemo(() => {
        return (
            <Form
                registry={albumRegistry.form}
                className={cn('action-bar__form')}
                onSubmit={onSubmitAlbum}
            >
                <h1 className={cn('action-bar__form-title')}>
                    {t('components.sidebar-action.forms.titles.album')}
                </h1>
                <InputAlbums
                    registry={albumRegistry.field}
                    children={'Укажите альбом'}
                    direction="column"
                    name="album"
                    required={true}
                    className={inputStyle}
                />
                <Button className={cn('action-bar__form-button')} type="submit">
                    {t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update]);

    const elPriceForm = useMemo(() => {
        return (
            <Form
                registry={priceRegistry.form}
                className={cn('action-bar__form')}
                onSubmit={onSubmitPrice}
            >
                <h1 className={cn('action-bar__form-title')}>
                    {t('components.sidebar-action.forms.titles.cost')}
                </h1>
                <InputForm
                    registry={priceRegistry.field}
                    name="price"
                    children={t('components.sidebar-action.forms.items.cost')}
                    className={inputStyle}
                    onlyNumbers={true}
                    direction="column"
                    required={true}
                />
                <p className={cn('action-bar__form-commission')}>{t('components.sidebar-action.messages.commission-info')}</p>
                <Button className={cn('action-bar__form-button')} type="submit">
                    {t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update]);

    const elResetPriceForm = useMemo(() => {
        return (
            <Form
                registry={resetPriceRegistry.form}
                className={cn('action-bar__form')}
                onSubmit={onSubmitResetPrice}
            >
                <h1 className={cn('action-bar__form-title')}>
                    {/*{t('components.sidebar-action.forms.titles.del-photo')}*/}
                    {'Снять с продажи'}
                </h1>
                {/*<p>{t('components.sidebar-action.messages.del-photo')}</p>*/}
                <p>{'Вы уверены, что хотите снять указанные фото с продажи?'}</p>
                <Button className={cn('action-bar__form-button')} type="submit">
                    {t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update]);

    const elRemoveForm = useMemo(() => {
        return (
            <Form
                registry={removePhotoRegistry.form}
                className={cn('action-bar__form')}
                onSubmit={onSubmitRemove}
            >
                <h1 className={cn('action-bar__form-title')}>
                    {t('components.sidebar-action.forms.titles.del-photo')}
                </h1>
                <p>{t('components.sidebar-action.messages.del-photo')}</p>
                <Button className={cn('action-bar__form-button')} type="submit">
                    {t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update]);

    const onClickCloseModal = (): void => {
        hideAll();
    };

    const elModal = useMemo(() => {
        return (
            <Modal onClickClose={onClickCloseModal}>
                <div>
                    {showPrice && elPriceForm}
                    {showComment && elCommentForm}
                    {showAlbum && elAlbumForm}
                    {showEvent && elEventForm}
                    {showResetPrice && elResetPriceForm}
                    {showRemove && elRemoveForm}
                </div>
            </Modal>
        );
    }, [showPrice, showEvent, showComment, showAlbum, showResetPrice, showRemove, year]);

    return (
        <div className={cn('action-bar')}>
            {showModal && elModal}
            <div className={cn('action-bar__panel')}>
                <ReactTooltip />
                <ul className={cn('action-bar__panel-menu')}>
                    <li data-tip={'Указать событие'} onClick={onClickEvent} className={cn('action-bar__panel-menu_item')}><FontAwesomeIcon icon={faTrophy} /></li>
                    <li data-tip={'Переместить в альбом'} onClick={onClickAlbum} className={cn('action-bar__panel-menu_item')}><FontAwesomeIcon icon={faShareSquare} /></li>
                    <li data-tip={'Указать стоимость'} onClick={onClickPrice} className={cn('action-bar__panel-menu_item')}><FontAwesomeIcon icon={faDollarSign} /></li>
                    <li data-tip={'Снять с продажи'} onClick={onClickResetPrice} className={cn('action-bar__panel-menu_item')}><FontAwesomeIcon icon={faTimes} /></li>
                    <li data-tip={'Удалить фото'} onClick={onClickRemove} className={cn('action-bar__panel-menu_item')}><FontAwesomeIcon icon={faTrash} /></li>
                </ul>
            </div>
        </div>
    );
};

export default ActionBar;
