import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

import { useClassnames } from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';
import { changeUserAlbumRights } from 'component/api/album';

import { IProps } from './types';
import style from './styles.pcss';
import api from 'src/api';
import { AccessRights } from 'src/api/albums/types';

const UserRightButtons: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const token = useCancelToken();

    const [writeRight, setWriteRight] = useState<boolean>(false);
    const [uploadRight, setUploadRight] = useState<boolean>(false);

    useEffect(() => {
        setRights();
    }, []);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const setRights = () => {
        props.userRights.map((right) => {
            switch (right) {
                case 'WRITE': setWriteRight(true); break;
                case 'UPLOAD': setUploadRight(true); break;
            }
        });
    };

    const _request = (write: boolean, upload: boolean) => {
        let userRights: Array<AccessRights> = ['READ'];

        if (write) {
            userRights = [...userRights, 'WRITE'];
        }

        if (upload) {
            userRights = [...userRights, 'UPLOAD'];
        }

        api.albums.updateAlbumsAccesses(props.albumID, props.accessID, userRights)
            .then(() => {
                setWriteRight(write);
                setUploadRight(upload);
            });
    };

    const onClickWriteRight = (e: MouseEvent): void => {
        e.preventDefault();

        _request(!writeRight, uploadRight);
    };

    const onClickUploadRight = (e: MouseEvent): void => {
        e.preventDefault();

        _request(writeRight, !uploadRight);
    };

    return (
        <div className={cn('rights')}>
            <ReactTooltip />
            <div
                className={cn('rights__icon', {
                    'rights__icon-active': writeRight
                })}
            >
                <FontAwesomeIcon
                    data-tip={'Изменить право на редактирование'}
                    onClick={onClickWriteRight}
                    icon={faPenSquare}
                />
            </div>
            <div
                className={cn('rights__icon', {
                    'rights__icon-active': uploadRight
                })}
            >
                <FontAwesomeIcon
                    data-tip={'Изменить право на загрузку фото'}
                    onClick={onClickUploadRight}
                    icon={faUpload}
                />
            </div>
        </div>
    );
};

export default UserRightButtons;
