import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import UI from 'component/ui';
import Form from 'component/form';
import { useClassnames } from 'hook/use-classnames';
import InputPhoto from 'component/form/input-photo';
import IconCross from 'component/icon/cross';
import { DataFilesItem } from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { getPersonListByTmplFile } from 'component/api/main';
import { useCancelToken } from 'component/core/cancel-token';

import { IProps } from './types';
import style from './styles.pcss';
import { CreateTempFile } from 'src/api/files/types';
import { getNormalizedQuery } from 'component/advertisements/utils';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import api from 'src/api';
import Loader from 'component/loader';

const SearchPerson: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const token = useCancelToken();

    const $canvas = useRef<HTMLCanvasElement>(null);
    const $image = useRef<HTMLImageElement>(null);
    const [ queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const [image, setImage] = useState<CreateTempFile | null>(null);
    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        if (queryParams.file_id) {
            setIsLoadingImage(true);
            api.files.getTempFile(queryParams.file_id)
                .then((resp) => {
                    const href = URL.createObjectURL(resp.data);

                    const newImage = {
                        id: queryParams.file_id,
                        url: href
                    };

                    setImage(newImage);
                    setIsLoadingImage(false);
                })
                .catch(() => {
                    setIsLoadingImage(false);
                });
        }
    }, []);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    useEffect(() => {
        if (image && props.onFileLoaded) {
            props.onFileLoaded(image);
        }
    }, [image]);

    const onFileLoaded = (file: CreateTempFile): void => {
        setImage(file);

        if (props.onFileLoaded) {
            props.onFileLoaded(file);
        }

        if (!file?.id) {
            return;
        }
    };

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const fields = props.form.getFields();

        if (fields) {
            const photo = fields.get('photo');

            if (photo) {
                photo.clear();
            }
        }

        setImage(null);
    }, []);

    const elImageResult = () => {
        if(image?.url) {
            return (
                <div className={cn('search-person__image-box')}>
                    {/* <canvas ref={$canvas} className={cn('search__canvas')} /> */}
                    <img ref={$image} className={cn('search-person__photo-image')} src={image.url} />
                </div>
            );
        }
    };

    if (isLoadingImage) {
        return (
            <Loader />
        );
    }

    return (
        <UI.Box padding={true} className={cn('search-person')}>
            <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
            <Form registry={props.form}>
                {elImageResult()}
                <InputPhoto
                    defaultValue={image || undefined}
                    onFileLoaded={onFileLoaded}
                    registry={props.field}
                    name="photo"
                    skipRecognition={true}
                    hideBtn={true}
                />
                {image?.url && (
                    <div className={cn('search-person__photo-menu')}>
                        <div onClick={onClickRemove} className={cn('search-person__photo-menu-item')}>
                            <IconCross className={cn('search-person__photo-menu-icon')} />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Form>
        </UI.Box>
    );
};

export default SearchPerson;
