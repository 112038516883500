import React from 'react';
import { Link } from 'react-router-dom';
import { useClassnames } from 'hook/use-classnames';
import style from './styles.pcss';

interface IBreadcrumbItem {
  label: string;
  to?: string;
}

interface IProps {
  items: Array<IBreadcrumbItem | null>;
}

const Breadcrumbs: React.FC<IProps> = ({ items }) => {
  const cn = useClassnames(style);

  const formattedItems = items.filter((item) => item !== null);

  return (
    <nav className={cn('breadcrumbs')} aria-label="Breadcrumb">
      <ol className={cn('breadcrumbs__list')}>
        {formattedItems.map((item, index) => {
            if (item) {
                return (
                  <li key={index} className={cn('breadcrumbs__item')}>
                      {index > 0 && <span className={cn('breadcrumbs__separator')}>/</span>}
                      {item.to && index < formattedItems.length - 1 ? (
                      <Link className={cn('breadcrumbs__link')} target="_blank" to={item.to}>
                          {item.label}
                      </Link>
                      ) : (
                      <span className={cn('breadcrumbs__text')}>{item.label}</span>
                      )}
                  </li>
                );
            }
        }
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
