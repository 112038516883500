import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import { useCancelToken } from 'component/core/cancel-token';
import LazyLoad from 'component/lazy-load';
import Modal from 'component/modal';
import Form, { useRegistry } from 'component/form';
import Checkbox from 'component/form/checkbox';
import InputForm from 'component/form/input';
import InputYears from 'component/form/input-years';
import InputEvents from 'component/form/input-events/index-tsx-async';
import { IValue } from 'component/form/input-years/types';
import Button from 'component/button';
import { useAlert } from 'component/alert/provider';

import placeholder from './img/placeholder.png';
import { IProps } from './types';
import style from './styles.pcss';
import api from 'src/api';
import { BulkUpdateAlbumPhotosData } from 'src/api/albums/types';
import NewAttachments from 'component/new-attachments';

const Album: FC<IProps> = ({album, ...props}) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const { show } = useAlert();

    // Modal
    const token = useCancelToken();
    const registry = useRegistry();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [year, setYear] = useState<number>(0);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const elAttachments = useMemo(() => {
        if(Array.isArray(props.photos) && props.photos?.length && album) {
            const attachments = props.photos.map((item) => ({
                url: item
            }));

            const params = {[props?.isInvited ? 'invitedAlbumId' : 'albumId']: props?.id};
            const link = props?.isInvited ? `/invited-albums/${props?.id}` : `/albums/${props?.id}${props.parent ? `?parent=${props.parent}` : ''}`;

            return (
                <Fragment>
                    <ReactTooltip />
                    <LazyLoad className={cn('album__attachments')}>
                        <NewAttachments
                            data-tip={album.description}
                            className={cn('album__attachments')}
                            attachmentsCount={props.photos_count || attachments.length}
                            limit={3}
                            link={link}
                            isParent={!!props.parent}
                            children={props.photos}
                            isLink={true}
                            tooltip={album.description}
                            {...params}
                        />
                    </LazyLoad>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <ReactTooltip />
                    <LazyLoad className={cn('album__attachments')}>
                        <img
                            data-tip={album?.description}
                            src={placeholder}
                            alt={'placeholder'}
                        />
                    </LazyLoad>
                </ Fragment>
            );
        }
    }, [JSON.stringify(props.photos), album]);

    const elDate = useMemo(() => {
        if(album?.created_at) {
            return (
                <div className={cn('album__content-text')}>
                    <span>{t('route.dashboard.album.date')}</span>
                    <span>{moment(album?.created_at).format('ll')}</span>
                </div>

            );
        }
    }, [album?.created_at]);

    const elRecommendations = () => {
        return (
            <div className={cn('album__recommendations')}>
                <div className={cn('album__recommendations-items')}>
                    <div className={cn('album__recommendations-item')}>
                        <span className={cn('album__recommendations-text')}>
                            Основное фото - это фото, на котором изображены только Вы.
                        </span>
                    </div>
                    <div className={cn('album__recommendations-item')}>
                        <span className={cn('album__recommendations-text')}>
                            Выберите его из списка загруженных фото и нажмите соответствующую кнопку.
                        </span>
                    </div>
                    <div className={cn('album__recommendations-item')}>
                        <span className={cn('album__recommendations-text')}>
                            После этого у вас появится возможность подтвердить свою персону.
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const elLink = useMemo(() => {
        if (props.isPersonal) {
            return (
                <div className={cn('album__header')}>
                    {props.name}
                    <ReactTooltip
                        id="album-photo-recommendation"
                        place="right"
                        effect="solid"
                        clickable={true}
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('album__recommendations-tooltip')}
                    >
                        {elRecommendations()}
                    </ReactTooltip>
                    <span
                        className={cn('album__question')}
                        data-type="light"
                        data-tip={true}
                        data-for="album-photo-recommendation"
                    >
                        ?
                    </span>
                </div>
            );
        }

        const link = (!props.isInvited) ? `/albums/${props.id}` : `/invited-albums/${props.id}`;

        return (
            <div className={cn('album__header')}>
                <ReactTooltip
                    id={`album-photo-name-${props.id}`}
                    effect="solid"
                    className={cn('album__recommendations-tooltip')}
                >
                    {album.description}
                </ReactTooltip>
                <div
                    {...(album.description && { 'data-tip': album.description })}
                    data-for={`album-photo-name-${props.id}`}
                >
                    <Link to={`${link}${props.parent ? `?parent=${props.parent}` : ''}`} target={`${props.isBlankLink ? '_blank' : ''}`}>{props.name}</Link>
                </div>
                <div className={cn('album__header-btn')}>
                    <FontAwesomeIcon icon={faEdit} onClick={() => setShowModal(true)} />
                </div>
            </div>
        );
    }, [props.name, props.id, props.isInvited, props.photos, album]);

    // Modal
    const onChangeYear = (value: IValue): void => {
        if (value) {
            setYear(Number(value?.value));
        } else {
            setYear(0);
        }
    };

    const onSubmit = useCallback((payload) => {
        const data: BulkUpdateAlbumPhotosData = {
            ...(payload.event?.value && { event: payload.event.value }),
            ...(payload.price && { price: payload.price }),
            ...(payload.remove && { price: null })
        };

        if (props.parent) {
            api.albums.bulkUpdateAlbumAlbumsPhotos(props.parent, props.id, data)
                .then(() => {
                    setShowModal(false);
                    show('Ваши изменения успешно применены.');
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                        show('Не удалось применить Ваши изменения.', 'warning');
                    }
                });
        } else {
            api.albums.bulkUpdateAlbumPhotos(props.id, data)
                .then(() => {
                    setShowModal(false);
                    show('Ваши изменения успешно применены.');
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                        show('Не удалось применить Ваши изменения.', 'warning');
                    }
                });
        }

    }, []);

    const elModal = useMemo(() => {
        if (showModal) {
            const isDisabledEvents = !Boolean(year);

            return (
                <Modal onClickClose={() => setShowModal(false)} >
                    <Form registry={registry.form} onSubmit={onSubmit} className={cn('album__form')}>
                        <h2>{props.name}</h2>
                        <InputYears
                            registry={registry.field}
                            name="year"
                            clearable={true}
                            children={t('components.sidebar-action.forms.items.year')}
                            direction="column"
                            onChange={onChangeYear}
                        />
                        <InputEvents
                            registry={registry.field}
                            name="event"
                            required={true}
                            clearable={true}
                            excludeEmpty={false}
                            year={year}
                            children={t('components.sidebar-action.forms.items.event')}
                            direction="column"
                            disabled={isDisabledEvents}
                        />
                        <InputForm
                            registry={registry.field}
                            name="price"
                            children={t('components.sidebar-action.forms.items.cost')}
                            onlyNumbers={true}
                            direction="column"
                        />
                        <p className={cn('album__form-commission')}>
                            {t('components.sidebar-action.messages.commission-info')}
                        </p>
                        <Checkbox
                            registry={registry.field}
                            name="remove"
                            children={'Снять с продажи'}
                        />
                        <div className={cn('album__form-btn')}>
                            <Button type="submit">
                                Применить
                            </Button>
                        </div>
                    </Form>
                </Modal>
            );
        }
    }, [showModal, year]);

    return (
        <div className={cn('album')}>
            {elModal}
            {elAttachments}
            <div className={cn('album__content')}>
                {elLink}
                {elDate}
            </div>
        </div>
    );
};

export default Album;
