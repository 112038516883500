import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import {
    AccessRights,
    AlbumFilterValuesItem,
    AlbumItem,
    AlbumListItem,
    AlbumPerson,
    AlbumPhoto,
    AlbumRequestsListItem,
    AlbumSimpleListItem,
    AlbumsAPIType,
    AlbumAccessesListItem,
    BulkUpdateAlbumPhotos,
    BulkUpdateAlbumPhotosData,
    CreateAlbum,
    CreateAlbumItem,
    GetAlbumAlbumsListData,
    GetAlbumFilterValuesData,
    GetAlbumListData,
    GetAlbumPersonData,
    GetAlbumPhotoData,
    GetAlbumRequestsList,
    GetAlbumSimpleListData,
    GetAlbumsAccessesListData,
    PartialUpdateAlbumData,
    CreateAlbumRequestsTokens
} from './types';
import queryString from 'query-string';

const getAlbumList = (
    page: Page,
    data?: GetAlbumListData
): Promise<AxiosResponse<PaginationResponse<AlbumListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumListItem>>(
        '/api/v2/albums/',
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            }
        }
    );
};

const createAlbum = (
    data: CreateAlbum
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.post<CreateAlbumItem>('/api/v2/albums/', data);
};

const getAlbumAccessesList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumsAccessesListData
): Promise<AxiosResponse<PaginationResponse<AlbumAccessesListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumAccessesListItem>>(
        `/api/v2/albums/${album_pk}/accesses/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 10 },
                ...data
            }
        }
    );
};

const destroyAlbumsAccess = (
    album_pk: number,
    id: number
): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(
        `/api/v2/albums/${album_pk}/accesses/${id}/`
    );
};

const updateAlbumsAccesses = (
    album_pk: number,
    id: number,
    rights: Array<AccessRights>
): Promise<AxiosResponse<Array<AccessRights>>> => {
    return httpClient.put<Array<AccessRights>>(
        `/api/v2/albums/${album_pk}/accesses/${id}/rights/`,
        {
            rights
        }
    );
};

const getAlbumAlbumsList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumAlbumsListData
): Promise<AxiosResponse<PaginationResponse<AlbumListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumListItem>>(
        `/api/v2/albums/${album_pk}/albums/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 10 },
                ...data
            }
        }
    );
};

const createAlbumAlbumsItem = (
    album_pk: number,
    data: CreateAlbum
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/albums/${album_pk}/albums/`, data);
};

const getAlbumAlbumsRetrieve = (
    album_pk: number,
    id: number
): Promise<AxiosResponse<AlbumItem>> => {
    return httpClient.get<AlbumItem>(
        `/api/v2/albums/${album_pk}/albums/${id}/`
    );
};

const updateAlbumAlbums = (
    album_pk: number,
    id: number,
    data: CreateAlbum
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.put<CreateAlbumItem>(
        `/api/v2/albums/${album_pk}/albums/${id}/`,
        data
    );
};

const partialUpdateAlbumAlbums = (
    album_pk: number,
    id: number,
    data?: PartialUpdateAlbumData
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.patch<CreateAlbumItem>(
        `/api/v2/albums/${album_pk}/albums/${id}/`,
        data
    );
};

const destroyAlbumAlbums = (
    album_pk: number,
    id: number
): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/albums/${album_pk}/albums/${id}/`);
};

const bulkUpdateAlbumAlbumsPhotos = (
    album_pk: number,
    id: number,
    data: BulkUpdateAlbumPhotosData
): Promise<AxiosResponse<BulkUpdateAlbumPhotos>> => {
    return httpClient.put<BulkUpdateAlbumPhotos>(
        `/api/v2/albums/${album_pk}/albums/${id}/bulk-photo-update/`,
        data
    );
};

const getAlbumPersonList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumPersonData
): Promise<AxiosResponse<PaginationResponse<AlbumPerson>>> => {
    return httpClient.get<PaginationResponse<AlbumPerson>>(
        `/api/v2/albums/${album_pk}/persons/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            },
            paramsSerializer(params) {
                return queryString.stringify(params, { arrayFormat: 'none' });
            }
        }
    );
};

const getAlbumPhotoList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumPhotoData
): Promise<AxiosResponse<PaginationResponse<AlbumPhoto>>> => {
    return httpClient.get<PaginationResponse<AlbumPhoto>>(
        `/api/v2/albums/${album_pk}/photos/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            },
            paramsSerializer(params) {
                return queryString.stringify(params, { arrayFormat: 'none' });
            }
        }
    );
};

const getAlbumRequestsList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumRequestsList
): Promise<AxiosResponse<PaginationResponse<AlbumRequestsListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumRequestsListItem>>(
        `/api/v2/albums/${album_pk}/requests/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            }
        }
    );
};

const acceptAlbumRequest = (
    album_pk: number,
    id: number,
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.put<void>(
        `/api/v2/albums/${album_pk}/requests/${id}/accept/`,
        {
            token
        }
    );
};

const rejectAlbumRequest = (
    album_pk: number,
    id: number,
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.put<void>(
        `/api/v2/albums/${album_pk}/requests/${id}/reject/`,
        {
            token
        }
    );
};

const createAlbumRequestsInvites = (
    album_pk: number,
    email: string
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(
        `/api/v2/albums/${album_pk}/requests/invites/`,
        {
            email
        }
    );
};

const checkCreateAlbumRequestsInvites = (
    album_pk: number,
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(
        `/api/v2/albums/${album_pk}/requests/invites/check/`,
        {
            token
        }
    );
};

const confirmCreateAlbumRequestsInvites = (
    album_pk: number,
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(
        `/api/v2/albums/${album_pk}/requests/invites/confirm/`,
        {
            token
        }
    );
};

const createAlbumRequestsTokens = (
    album_pk: number
): Promise<AxiosResponse<CreateAlbumRequestsTokens>> => {
    return httpClient.post<CreateAlbumRequestsTokens>(`/api/v2/albums/${album_pk}/requests/tokens/`);
};

const getAlbumRetrieve = (id: number): Promise<AxiosResponse<AlbumItem>> => {
    return httpClient.get<AlbumItem>(`/api/v2/albums/${id}/`);
};

const updateAlbum = (
    id: number,
    data: CreateAlbum
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.put<CreateAlbumItem>(`/api/v2/albums/${id}/`, data);
};

const partialUpdateAlbum = (
    id: number,
    data?: PartialUpdateAlbumData
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.patch<CreateAlbumItem>(`/api/v2/albums/${id}/`, data);
};

const destroyAlbum = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/albums/${id}/`);
};

const bulkUpdateAlbumPhotos = (
    id: number,
    data: BulkUpdateAlbumPhotosData
): Promise<AxiosResponse<BulkUpdateAlbumPhotos>> => {
    return httpClient.put<BulkUpdateAlbumPhotos>(
        `/api/v2/albums/${id}/bulk-photo-update/`,
        data
    );
};

const getAlbumFilterValues = (
    data?: GetAlbumFilterValuesData
): Promise<AxiosResponse<Array<AlbumSimpleListItem>>> => {
    return httpClient.get<Array<AlbumSimpleListItem>>(
        '/api/v2/albums/filter-values/',
        {
            params: {
                ...data
            }
        }
    );
};

const checkCreateAlbumsInvites = (
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/albums/invites/check/', {
        token
    });
};

const confirmCreateAlbumsInvites = (
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/albums/invites/confirm/', {
        token
    });
};

const getAlbumSimpleList = (
    page: Page,
    data?: GetAlbumSimpleListData
): Promise<AxiosResponse<PaginationResponse<AlbumFilterValuesItem>>> => {
    return httpClient.get<PaginationResponse<AlbumFilterValuesItem>>(
        '/api/v2/albums/simple/',
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            }
        }
    );
};

export default {
    getAlbumList,
    createAlbum,
    getAlbumAccessesList,
    destroyAlbumsAccess,
    updateAlbumsAccesses,
    getAlbumAlbumsList,
    createAlbumAlbumsItem,
    getAlbumAlbumsRetrieve,
    updateAlbumAlbums,
    partialUpdateAlbumAlbums,
    destroyAlbumAlbums,
    bulkUpdateAlbumAlbumsPhotos,
    getAlbumPersonList,
    getAlbumPhotoList,
    getAlbumRequestsList,
    acceptAlbumRequest,
    rejectAlbumRequest,
    createAlbumRequestsInvites,
    checkCreateAlbumRequestsInvites,
    confirmCreateAlbumRequestsInvites,
    createAlbumRequestsTokens,
    getAlbumRetrieve,
    updateAlbum,
    partialUpdateAlbum,
    destroyAlbum,
    bulkUpdateAlbumPhotos,
    getAlbumFilterValues,
    checkCreateAlbumsInvites,
    confirmCreateAlbumsInvites,
    getAlbumSimpleList
} as AlbumsAPIType;
